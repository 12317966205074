// @ts-nocheck
/* eslint-disable @typescript-eslint/no-redeclare */

import { makeApi, Zodios } from '@zodios/core'
import { z } from 'zod'
import { ZodiosHooks } from '@zodios/react'
import { pluginToken } from '@zodios/plugins'
import { MsalSingleton } from './msalInstance'
import { IConfiguration } from '../store/actions/app/configuration'

type WozObjectDto = {
  wozObjectnummer: string
  peildatum?: string | undefined
  wozWaarde?: number | undefined
  wozOzbAanslag?: number | undefined
  wozEigendomGebruik?: (string | null) | undefined
  vastgoedobjectsoortID?: (string | null) | undefined
  vastgoedobjectsoort?: (string | null) | undefined
  locatiebeschrijving?: (string | null) | undefined
  verblijfsobject?: (string | null) | undefined
  vgeId?: (number | null) | undefined
  wozDeelobjecten?: (Array<WozDeelobjectDto> | null) | undefined
  wozHistorie?: (Array<WozObjectDto> | null) | undefined
}
type WozDeelobjectDto = Partial<{
  bagPand: string | null
  wozDeelobjectNummer: string | null
  wozObjectNummer: string | null
  wozDeelobjectOnderdeelCode: string | null
  wozDeelobjectOnderdeelOmschrijving: string | null
  wozDeelobjectGebruiksoppervlakte: string | null
}>

const Postcode = z.string().regex(/^[1-9][0-9]{3}[a-zA-Z]{2}$/)
const AdresListDto = z.object({
  id: z.string().nullable(),
  postcode: z.string().nullable(),
  huisnummer: z.string().nullable(),
  huisletter: z.string().nullable(),
  huisnummertoevoeging: z.string().nullable(),
  straat: z.string().nullable(),
  woonplaats: z.string().nullable(),
})
const id = z.string()
const AdresDetailsDto = z.object({
  postcode: z.string().nullable(),
  huisnummer: z.string().nullable(),
  huisletter: z.string().nullable(),
  huisnummertoevoeging: z.string().nullable(),
  straat: z.string().nullable(),
  woonplaats: z.string().nullable(),
  energielabelLetter: z.string().nullable(),
  cbsBuurtCode: z.string().nullable(),
})
const Filter = z.string().optional()
const PageIndex = z.number().int().gte(1).lte(200000).optional()
const BegrippenLijstDto = z.object({
  begrippenLijstId: z.number().int(),
  begrip: z.string().nullable(),
  beschrijving: z.string().nullable(),
  bron: z.string().nullable(),
})
const BegrippenLijstDtoPaginatedList = z.object({
  items: z.array(BegrippenLijstDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateBegrippenLijstCommand = z.object({
  begrippenLijstId: z.number().int(),
  begrip: z.string().nullable(),
  beschrijving: z.string().nullable(),
  bron: z.string().nullable(),
})
const deleteBegrippenLijst_Body = z.array(z.number().int())
const UpdateBegrippenLijstCommand = z.object({
  begrippenLijstId: z.number().int(),
  begrip: z.string().nullable(),
  beschrijving: z.string().nullable(),
  bron: z.string().nullable(),
})
const id__2 = z.number().int()
const BouwlaagDto = z.object({ id: z.number().int(), naam: z.string().nullable(), order: z.number().int(), naamExtern: z.string().nullable() })
const BouwlaagDtoPaginatedList = z.object({
  items: z.array(BouwlaagDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ChangeBouwlaagOrderCommand = z.object({ id: z.number().int(), moveUp: z.boolean() })
const DropdownOption = z.object({ key: z.number().int(), text: z.string().min(1) })
const CoenCadCodeDto = z.object({
  id: z.number().int(),
  key: z.string().nullable(),
  code: z.string().nullable(),
  description: z.string().nullable(),
  groep: z.string().nullable(),
  elementElementDetailId: z.number().int().nullable(),
  elementElementDetailOmschrijving: z.string().nullable(),
})
const CoenCadCodeDtoPaginatedList = z.object({
  items: z.array(CoenCadCodeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CoencadMeetinstructieListDto = z.object({
  meetinstructieId: z.number().int(),
  tekeningBouwlaagId: z.number().int(),
  verwerkingId: z.number().int(),
  bronbestand: z.string().nullable(),
  berekeningswijze: z.string().nullable(),
  importdatum: z.string().nullable(),
  gebruiker: z.string().nullable(),
  bouwlaag: z.string().nullable(),
  gebruiksoppervlakteWonen: z.number().nullable(),
  gebruiksoppervlakteOverigeInpandigeRuimte: z.number().nullable(),
  externeBergruimte: z.number().nullable(),
  nietOverdekteGebouwgebondenBuitenruimte: z.number().nullable(),
  vrijeDoorgangDeurenWoning: z.number().nullable(),
  overdekteGebouwgebondenBuitenruimte: z.number().nullable(),
})
const CoencadZorggeschiktheidListDto = z.object({
  zorggeschiktheidId: z.number().int(),
  tekeningBouwlaagId: z.number().int(),
  volgnummer: z.number().int(),
  bronbestand: z.string().nullable(),
  berekeningswijze: z.string().nullable(),
  importdatum: z.string().nullable(),
  gebruiker: z.string().nullable(),
  entreeZonderTraptrede: z.boolean().nullable(),
  hoogteTraptrede: z.number().nullable(),
  primaireVoorzieningenGelijkvloers: z.boolean().nullable(),
  breedteToegangspad: z.number().nullable(),
  vrijeDoorgangDeurenWoning: z.number().nullable(),
  niveauverschilInWoning: z.number().nullable(),
  hellingshoekWoningBijVerschil20Mm: z.number().int().nullable(),
  kleinsteBreedteGang: z.number().nullable(),
  badkamerDezelfdeVerdiepingAlsSlaapkamer: z.boolean().nullable(),
  toiletDezelfdeVerdiepingAlsSlaapkamer: z.boolean().nullable(),
  kleinsteOppervlakteBadkamerEnOfToilet: z.number().nullable(),
  kleinsteBreedteBadkamerEnOfToilet: z.number().nullable(),
  draaicirkelBadkamer: z.number().nullable(),
  breedte2PersoonsSlaapkamer: z.number().nullable(),
  lengte2PersoonsSlaapkamer: z.number().nullable(),
  oppervlakte2PersoonsSlaapkamer: z.number().nullable(),
  kleinsteVrijeRuimteVoorAanrecht: z.number().nullable(),
  gebouwZonderTraptrede: z.boolean().nullable(),
  kleinsteBreedteToegangspad: z.number().nullable(),
  niveauverschilEntree: z.number().nullable(),
  hellingshoekToegangspadBijVerschil20: z.number().int().nullable(),
  dagmaat: z.number().nullable(),
  draaicirkel: z.number().nullable(),
  galerijbreedte: z.number().nullable(),
  kleinsteAfmetingLiftkooi: z.number().nullable(),
})
const ContactCategorieListDto = z.object({ id: z.number().int(), naam: z.string().nullable() })
const ContactCategorieListDtoPaginatedList = z.object({
  items: z.array(ContactCategorieListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ContactCategorieAddOrUpdateDto = z.object({ naam: z.string().min(3) })
const ContactCategorieDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const Unit = z.object({})
const ContactgegevenListDto = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  categorieNaam: z.string().nullable(),
  categorieId: z.string().nullable(),
})
const ContactgegevenListDtoPaginatedList = z.object({
  items: z.array(ContactgegevenListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ContactgegevenAddOrUpdateDto = z.object({
  contactCategorieId: z.number().int(),
  naam: z.string().min(3),
  emailadres: z.string().min(1).email(),
  telefoonnummer: z.string().min(1),
})
const ContactgegevenDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const DefectLocatieDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  veraVersie: z.number().nullable(),
})
const DefectLocatieDtoPaginatedList = z.object({
  items: z.array(DefectLocatieDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateDefectLocatieCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  veraVersie: z.number().nullable(),
})
const UpdateDefectLocatieCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  veraVersie: z.number().nullable(),
})
const id__3 = z.number().int().optional()
const Headers = z.record(z.array(z.string())).optional()
const Result = z.object({ succeeded: z.boolean(), errors: z.array(z.string()).nullable() })
const query = z.object({}).optional()
const DefectlocatieElementDto = z.object({ defectlocatieId: z.number().int(), elementId: z.number().int() })
const DefectlocatieElementDtoPaginatedList = z.object({
  items: z.array(DefectlocatieElementDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DefectLocationWoningverbeteringDto = z.object({ defectlocatieId: z.number().int(), woningverbeteringId: z.number().int() })
const DefectLocationListItemDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
})
const DefectoorzaakDto = z.object({
  defectoorzaakId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  naam: z.string().nullable(),
})
const DefectoorzaakDtoPaginatedList = z.object({
  items: z.array(DefectoorzaakDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateDefectoorzaakCommand = z.object({
  defectoorzaakId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  naam: z.string().nullable(),
})
const UpdateDefectoorzaakCommand = z.object({
  defectoorzaakId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  naam: z.string().nullable(),
})
const DefectoorzaakKostensoortListDto = z.object({
  defectoorzaakId: z.number().int(),
  defectoorzaak: z.string().nullable(),
  kostensoortId: z.number().int().nullable(),
  kostensoort: z.string().nullable(),
})
const DefectoorzaakKostensoortListDtoPaginatedList = z.object({
  items: z.array(DefectoorzaakKostensoortListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DefectoorzaakKostensoortAddOrUpdateDto = z.object({ defectoorzaakId: z.number().int(), kostensoortId: z.number().int().nullish() })
const DefectSoortListDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
})
const DefectSoortListDtoPaginatedList = z.object({
  items: z.array(DefectSoortListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DefectSoortAddOrUpdateDto = z.object({
  code: z.string().min(3),
  naam: z.string().min(3),
  omschrijving: z.string().nullish(),
  veraVersie: z.number().nullish(),
  ingangsdatum: z.string().datetime({ offset: true }).nullish(),
  einddatum: z.string().datetime({ offset: true }).nullish(),
})
const DefectSoortDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const DefectSoortDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
})
const DienstenComponentDto = z.object({
  dienstencomponentId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  kostensoortId: z.number().int().nullable(),
})
const DienstenComponentDtoPaginatedList = z.object({
  items: z.array(DienstenComponentDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateDienstenComponentCommand = z.object({
  dienstencomponentId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  kostensoortId: z.number().int().nullable(),
})
const UpdateDienstenComponentCommand = z.object({
  dienstencomponentId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  kostensoortId: z.number().int().nullable(),
})
const DimensieListDto = z.object({ id: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable(), vgtDimensie: z.string().nullable() })
const DimensieListDtoPaginatedList = z.object({
  items: z.array(DimensieListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DimensieAddCommand = z.object({ code: z.string().nullable(), omschrijving: z.string().nullable(), vgtDimensieId: z.number().int() })
const DimensieDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const DimensieCreateOrUpdateDto = z.object({ code: z.string().nullable(), omschrijving: z.string().nullable(), vgtDimensieId: z.number().int() })
const DimensieUpdateCommand = z.object({
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  vgtDimensieId: z.number().int(),
  id: z.number().int(),
})
const DimensiewaardeListDto = z.object({ id: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const DimensiewaardeListDtoPaginatedList = z.object({
  items: z.array(DimensiewaardeListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DimensiewaardeAddCommand = z.object({ code: z.string().nullable(), omschrijving: z.string().nullable(), dimensieId: z.number().int() })
const DimensiewaardeDeleteCommand = z.object({ dimensieId: z.number().int(), itemsToDelete: z.array(z.number().int()).nullable() })
const DimensiewaardeUpdateOrAddDto = z.object({ code: z.string().nullable(), omschrijving: z.string().nullable() })
const DimensiewaardeUpdateCommand = z.object({
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  dimensieId: z.number().int(),
  id: z.number().int(),
})
const DocumentClassificationListDto = z.object({
  documentSoortKenmerkId: z.number().int(),
  documentSoortId: z.number().int(),
  documentKenmerkId: z.number().int(),
  soort: z.string().nullable(),
  kenmerk: z.string().nullable(),
  isVge: z.boolean(),
  isGebouw: z.boolean(),
  isInstallatie: z.boolean(),
  isGebouwCluster: z.boolean(),
  isOnderhoudscontract: z.boolean(),
  isVhe: z.boolean(),
  isVgeRuimte: z.boolean(),
  isGebouwdeel: z.boolean(),
})
const DocumentClassificationListDtoPaginatedList = z.object({
  items: z.array(DocumentClassificationListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DocumentClassificationAddOrUpdateDto = z.object({
  documentSoortKenmerkId: z.number().int().optional(),
  documentSoortId: z.number().int(),
  documentKenmerkId: z.number().int(),
  soort: z.string().nullish(),
  kenmerk: z.string().nullish(),
  isVge: z.boolean(),
  isGebouw: z.boolean(),
  isInstallatie: z.boolean(),
  isGebouwCluster: z.boolean(),
  isOnderhoudscontract: z.boolean(),
  isVhe: z.boolean(),
  isVgeRuimte: z.boolean(),
  isGebouwdeel: z.boolean(),
})
const DocumentClassificationDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const DocumentListDto = z.object({
  documentId: z.number().int(),
  documentSoortKenmerkDoelId: z.number().int(),
  vgeId: z.number().int().nullable(),
  vheId: z.number().int().nullable(),
  gebouwId: z.number().int().nullable(),
  gebouwdeelId: z.number().int().nullable(),
  gebouwenClusterId: z.number().int().nullable(),
  onderhoudscontractId: z.number().int().nullable(),
  vgeRuimteId: z.number().int().nullable(),
  installatieId: z.number().int().nullable(),
  soort: z.string().nullable(),
  kenmerk: z.string().nullable(),
  bestandsnaam: z.string().nullable(),
  extensie: z.string().nullable(),
  gemaaktOp: z.string().nullable(),
  gemaaktDoor: z.string().nullable(),
  isHoofdDocument: z.boolean(),
})
const DocumentListDtoPaginatedList = z.object({
  items: z.array(DocumentListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const postApiDocuments_Body = z
  .object({
    DocumentSoortKenmerkDoelId: z.number().int(),
    DoelType: z.number().int(),
    DoelId: z.number().int(),
    Bestandsnaam: z.string().optional(),
    Extensie: z.string().optional(),
    GemaaktOp: z.string().datetime({ offset: true }).optional(),
    GemaaktDoor: z.string().optional(),
    file: z.instanceof(File).optional(),
  })
  .passthrough()
const DocumentAddOrUpdateDto = z.object({
  documentSoortKenmerkDoelId: z.number().int(),
  doelType: z.number().int(),
  doelId: z.number().int(),
  bestandsnaam: z.string().nullish(),
  extensie: z.string().nullish(),
  gemaaktOp: z.string().datetime({ offset: true }).optional(),
  gemaaktDoor: z.string().nullish(),
})
const DocumentDeleteRequestDto = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const HoofdDocumentDto = z.object({ hoofdDocumentUrl: z.string().nullable() })
const UpdateHoofdDocumentCommand = z.object({ documentId: z.number().int(), doelType: z.number().int(), doelId: z.number().int() })
const DocumentSoortListDto = z.object({ documentSoortId: z.number().int(), omschrijving: z.string().nullable() })
const DocumentSoortListDtoPaginatedList = z.object({
  items: z.array(DocumentSoortListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DocumentKenmerkListDto = z.object({ documentKenmerkId: z.number().int(), omschrijving: z.string().nullable() })
const DocumentKenmerkListDtoPaginatedList = z.object({
  items: z.array(DocumentKenmerkListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DocumentSoortKenmerkDoelListDto = z.object({
  documentSoortKenmerkDoelId: z.number().int(),
  documentSoortKenmerkId: z.number().int(),
  documentSoortId: z.number().int(),
  documentKenmerkId: z.number().int(),
  soort: z.string().nullable(),
  kenmerk: z.string().nullable(),
})
const DocumentSoortKenmerkDoelListDtoPaginatedList = z.object({
  items: z.array(DocumentSoortKenmerkDoelListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const EenheidDetailSoortDto = z.object({
  id: z.number().int(),
  soortId: z.number().int(),
  soortCode: z.string().nullable(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  splitsNaarRuimteSoort: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  eengezinsMeergezinsOptie: z.string().nullable(),
})
const EenheidDetailSoortDtoPaginatedList = z.object({
  items: z.array(EenheidDetailSoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateEenheidDetailSoortCommand = z.object({
  id: z.number().int(),
  soortId: z.number().int(),
  soortCode: z.string().nullable(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  splitsNaarRuimteSoort: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  eengezinsMeergezinsOptie: z.string().nullable(),
})
const UpdateEenheidDetailSoortCommand = z.object({
  id: z.number().int(),
  soortId: z.number().int(),
  soortCode: z.string().nullable(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  splitsNaarRuimteSoort: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  eengezinsMeergezinsOptie: z.string().nullable(),
})
const EenheidDetailSoortRuimteSoortMatrixDto = z.object({ eenheidDetailSoortId: z.number().int(), ruimteSoortId: z.number().int() })
const EenheidDetailSoortListItemDto = z.object({ id: z.number().int(), naam: z.string().nullable() })
const BinnenDeWoning = z.boolean().optional()
const EenheiddetailsoortDefectlocatieDto = z.object({ eenheiddetailsoortId: z.number().int(), defectlocatieId: z.number().int(), binnenDeWoning: z.boolean() })
const EenheiddetailsoortDefectlocatieDtoPaginatedList = z.object({
  items: z.array(EenheiddetailsoortDefectlocatieDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const EenheidDetailSoortCollectiefObjectTypeDto = z.object({
  id: z.number().int(),
  eenheidDetailSoortId: z.number().int(),
  collectiefObjectTypeId: z.number().int(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const EenheidDetailSoortCollectiefObjectTypeDtoPaginatedList = z.object({
  items: z.array(EenheidDetailSoortCollectiefObjectTypeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const EenheidSoortDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  veraVersie: z.number().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const EenheidSoortDtoPaginatedList = z.object({
  items: z.array(EenheidSoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementDto = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  categorieId: z.number().int().nullable(),
  categorieNaam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const ElementDtoPaginatedList = z.object({
  items: z.array(ElementDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateElementCommand = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  categorieId: z.number().int().nullable(),
  categorieNaam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const UpdateElementCommand = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  categorieId: z.number().int().nullable(),
  categorieNaam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const ElementElementDetailDto = z.object({
  id: z.number().int(),
  elementId: z.number().int(),
  elementDetailId: z.number().int().nullable(),
  element: z.string().nullable(),
  elementDetail: z.string().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  materiaalSoortId: z.number().int().nullable(),
  materiaalSoortNaam: z.string().nullable(),
  nlSfBElementId: z.number().int().nullable(),
  nlSfBCode: z.string().nullable(),
  nlSfBOmschrijving: z.string().nullable(),
})
const ElementElementDetailDtoPaginatedList = z.object({
  items: z.array(ElementElementDetailDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementDefectSoortDto = z.object({
  id: z.number().int(),
  element: z.string().nullable(),
  elementDetail: z.string().nullable(),
  materiaalSoort: z.string().nullable(),
  defectSoortIds: z.array(z.number().int()).nullable(),
})
const ElementDefectSoortDtoPaginatedList = z.object({
  items: z.array(ElementDefectSoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementDefectSoortTakenRow = z.object({
  id: z.number().int(),
  element: z.string().nullable(),
  elementDetail: z.string().nullable(),
  materiaalSoort: z.string().nullable(),
  defectSoort: z.string().nullable(),
  taken: z.array(z.number().int()).nullable(),
})
const ElementDefectSoortTakenRowPaginatedList = z.object({
  items: z.array(ElementDefectSoortTakenRow).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementCategorieDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const ElementCategorieDtoPaginatedList = z.object({
  items: z.array(ElementCategorieDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementCategorieElementDto = z.object({
  id: z.number().int(),
  categorie: z.string().nullable(),
  element: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const ElementCategorieElementDtoPaginatedList = z.object({
  items: z.array(ElementCategorieElementDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateElementCategorieCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const UpdateElementCategorieCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const ElementdetailsListDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const ElementdetailsListDtoPaginatedList = z.object({
  items: z.array(ElementdetailsListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateElementdetailsCommand = z.object({ code: z.string().min(1), fonticoonId: z.number().int() })
const ElementdetailsDto = z.object({ code: z.string().min(1), fonticoonId: z.number().int() })
const GerelateerdInspectietakenListDto = z.object({ inspectietaakId: z.number().int(), taak: z.string().nullable() })
const GerelateerdReparatieboomTaken = z.object({ taakId: z.number().int(), naam: z.string().nullable() })
const ElementElementDetailListDto = z.object({
  id: z.number().int(),
  elementId: z.number().int(),
  elementDetailId: z.number().int().nullable(),
  element: z.string().nullable(),
  elementDetail: z.string().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  materiaalSoortId: z.number().int().nullable(),
  materiaalSoortNaam: z.string().nullable(),
  nlSfBElementId: z.number().int().nullable(),
  nlSfBCode: z.string().nullable(),
  nlSfBOmschrijving: z.string().nullable(),
  gerelateerdInspectietakenListDto: z.array(GerelateerdInspectietakenListDto).nullable(),
  gerelateerdReparatieboomTaken: z.array(GerelateerdReparatieboomTaken).nullable(),
})
const ElementElementDetailListDtoPaginatedList = z.object({
  items: z.array(ElementElementDetailListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ElementElementDetailAddOrUpdateDto = z.object({
  elementId: z.number().int(),
  elementNaam: z.string().nullish(),
  elementDetailId: z.number().int().nullish(),
  elementDetailNaam: z.string().nullish(),
  elementCategoryId: z.number().int().optional(),
  elementCategoryNaam: z.string().nullish(),
  materiaalSoortId: z.number().int().nullish(),
  materiaalSoortNaam: z.string().nullish(),
  bouwkundigElementId: z.number().int().nullish(),
})
const ElementElementDetailListItemDto = z.object({ elementElementDetailId: z.number().int(), omschrijving: z.string().nullable() })
const ExportInfo = z.object({ id: z.number().int().optional(), name: z.string().nullable(), endpoint: z.string().nullable() })
const FonticoonListDto = z.object({ fonticoonId: z.number().int(), naam: z.string().nullable(), omschrijving: z.string().nullable() })
const FonticoonListDtoPaginatedList = z.object({
  items: z.array(FonticoonListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const FonticoonAddOrUpdateDto = z.object({ naam: z.string().min(3), omschrijving: z.string().min(3) })
const FonticoonDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VgeListDto = z.object({
  id: z.number().int(),
  srt: z.string().nullable(),
  str: z.string().nullable(),
  wpl: z.string().nullable(),
  pc: z.string().nullable(),
  nr: z.number().int().nullable(),
  ltr: z.string().nullable(),
  nrT: z.string().nullable(),
  aa: z.string().nullable(),
  brt: z.string().nullable(),
  wk: z.string().nullable(),
  type: z.string().nullable(),
  srtNm: z.string().nullable(),
  trnNm: z.string().nullable(),
  vhes: z.array(z.string()).nullable(),
  hist: z.boolean(),
  histDt: z.string().datetime({ offset: true }).nullable(),
})
const VgeListDtoPaginatedList = z.object({
  items: z.array(VgeListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GerelateerdHuurcontractbepaling = z.object({ id: z.number().int(), type: z.string().nullable(), code: z.string().nullable() })
const VgeCoordinateDto = z.object({ id: z.number().int(), nr: z.number().int().nullable(), lat: z.number().nullable(), long: z.number().nullable() })
const GebouwenClusterDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullable(),
  advertentietekst: z.string().nullable(),
  hoeveelhedenCount: z.number().int(),
  vgeCoordinates: z.array(VgeCoordinateDto).nullable(),
})
const GebouwenClusterDtoPaginatedList = z.object({
  items: z.array(GebouwenClusterDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  type: z.number().int().nullable(),
  registratieniveau: z.string().nullable(),
  omschrijving: z.string().nullable(),
  vastgoedportefeuilleId: z.number().int().nullable(),
  vastgoedportefeuilleNaam: z.string().nullable(),
  vastgoeddeelportefeuilleId: z.number().int().nullable(),
  vastgoeddeelportefeuilleNaam: z.string().nullable(),
  werkgebiedId: z.number().int().nullable(),
  werkgebiedNaam: z.string().nullable(),
  advertentietekst: z.string().nullable(),
  serviceadresVge: z.number().int().nullable(),
  gebouwenClusterId: z.number().int().nullable(),
  serviceEmailadres: z.string().nullable(),
  serviceTelefoonnummer: z.string().nullable(),
  bestemmingId: z.number().int().nullable(),
  bestemmingNaam: z.string().nullable(),
})
const GebouwDtoPaginatedList = z.object({
  items: z.array(GebouwDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VgeDto = z.object({
  id: z.number().int(),
  srt: z.string().nullable(),
  str: z.string().nullable(),
  wpl: z.string().nullable(),
  pc: z.string().nullable(),
  nr: z.number().int().nullable(),
  ltr: z.string().nullable(),
  nrT: z.string().nullable(),
  aa: z.string().nullable(),
  brt: z.string().nullable(),
  brtC: z.string().nullable(),
  wk: z.string().nullable(),
  gemeente: z.string().nullable(),
  baGid: z.string().nullable(),
  lat: z.number().nullable(),
  long: z.number().nullable(),
  kad: z.string().nullable(),
  kadV: z.string().nullable(),
  grt: z.number().int().nullable(),
  etg: z.number().int().nullable(),
  vasAnr: z.string().nullable(),
  geb: z.number().int().nullable(),
  gebN: z.string().nullable(),
  gebCN: z.string().nullable(),
  gdl: z.number().int().nullable(),
  gdlCd: z.string().nullable(),
  gdlN: z.string().nullable(),
  eId: z.number().int().nullable(),
  en: z.string().nullable(),
  vhes: z.array(z.string()).nullable(),
  vhb: z.string().nullable(),
  vhbId: z.number().int().nullable(),
  bst: z.string().nullable(),
  bstId: z.number().int().nullable(),
  kmp: z.string().nullable(),
  kmpId: z.number().int().nullable(),
  kpgrnt: z.boolean().nullable(),
  rel: z.string().nullable(),
  bId: z.number().int().nullable(),
  bn: z.string().nullable(),
  opm: z.string().nullable(),
  advt: z.string().nullable(),
  trnClId: z.number().int().nullable(),
  trnNm: z.string().nullable(),
  rijksMnmnt: z.boolean(),
  oprId: z.string().nullable(),
  pId: z.string().nullable(),
  gmntljkMnmnt: z.boolean(),
  bschrmdStdsOfdrpgzcht: z.boolean(),
  prvnclMnmnt: z.boolean(),
  gebouwId: z.number().int().nullable(),
  vveId: z.number().int().nullable(),
  vveNaam: z.string().nullable(),
  bouwnummer: z.string().nullable(),
  bouwjaar: z.number().int().nullable(),
  renovatiejaar: z.number().int().nullable(),
  renovatieInvestering: z.number().nullable(),
  woonvoorzGehandicapten: z.number().nullable(),
  gelegenBinnenVgeId: z.number().int().nullable(),
  aantalNaamplaatjes: z.number().int().nullable(),
  loc: z.string().nullable(),
  locatieaanduiding: z.string().nullable(),
  isCol: z.boolean(),
  ref: z.string().nullable(),
  zelfstandig: z.string().nullable(),
  toegankelijkheid: z.number().int(),
  toegankelijkheidNaam: z.string().nullable(),
  gebouwenClusterId: z.number().int().nullable(),
  gebouwenClustersCode: z.string().nullable(),
  gebouwenClustersName: z.string().nullable(),
  repVerz: z.boolean(),
  repVerzBeoordelen: z.boolean(),
  hist: z.boolean(),
  histDt: z.string().datetime({ offset: true }).nullable(),
  dtbgstrt: z.string().datetime({ offset: true }).nullable(),
  dtingbrkn: z.string().datetime({ offset: true }).nullable(),
  histReden: z.string().nullable(),
  verkpAan: z.string().nullable(),
  histRedenId: z.number().int().nullable(),
  verkpAanId: z.number().int().nullable(),
  taxWrde: z.number().int().nullable(),
  type: z.string().nullable(),
  srtNm: z.string().nullable(),
  referentieExtern: z.string().nullable(),
  warmteLeveringId: z.number().int(),
  warmteLeveringNaam: z.string().nullable(),
  zolderZonderVasteTrapId: z.number().int(),
  zolderZonderVasteTrapNaam: z.string().nullable(),
  redennvId: z.number().int().nullable(),
  redennv: z.string().nullable(),
  labelletter: z.string().nullable(),
  taakTypeOptieId: z.number().int(),
  taakTypeOptieNaam: z.string().nullable(),
  isBadEnAparteDouche: z.boolean(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullable(),
})
const VgeDtoPaginatedList = z.object({
  items: z.array(VgeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const FysiekInfo = z.object({ id: z.number().int(), title: z.string().nullable(), url: z.string().nullable() })
const GarantieDto = z.object({ garantieId: z.number().int(), kostensoortId: z.number().int().nullable(), tenant_id: z.number().int() })
const GarantieDtoPaginatedList = z.object({
  items: z.array(GarantieDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateGarantieCommand = z.object({ garantieId: z.number().int(), kostensoortId: z.number().int().nullable(), tenant_id: z.number().int() })
const UpdateGarantieCommand = z.object({ garantieId: z.number().int(), kostensoortId: z.number().int().nullable(), tenant_id: z.number().int() })
const GebouwContactCategorieListDto = z.object({
  contactCategorieId: z.number().int(),
  contactgegevenId: z.number().int().nullable(),
  contactCategorieNaam: z.string().nullable(),
  contactgegevenNaam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
})
const GebouwContactCategorieListDtoPaginatedList = z.object({
  items: z.array(GebouwContactCategorieListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwContactCategorieGetOrModifyDto = z.object({
  contactgegevenId: z.number().int(),
  contactCategorieNaam: z.string().nullable(),
  contactgegevenNaam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
})
const GebouwContactCategorieMutateDto = z.object({ contactgegevenId: z.number().int().nullable() })
const GebouwDeelContactCategorieListDto = z.object({
  contactCategorieId: z.number().int(),
  contactgegevenId: z.number().int().nullable(),
  contactCategorieNaam: z.string().nullable(),
  contactgegevenNaam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
})
const GebouwDeelContactCategorieListDtoPaginatedList = z.object({
  items: z.array(GebouwDeelContactCategorieListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwDeelContactCategorieGetOrModifyDto = z.object({
  contactgegevenId: z.number().int(),
  contactCategorieNaam: z.string().nullable(),
  contactgegevenNaam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
})
const GebouwDeelContactCategorieMutateDto = z.object({ contactgegevenId: z.number().int().nullable() })
const GebouwdeelAddOrUpdateDto = z.object({
  gebouwDeelId: z.number().int(),
  code: z.string().min(1),
  naam: z.string().min(1),
  gebouwId: z.number().int(),
  serviceadresVge: z.number().int().nullish(),
  bestemmingId: z.number().int().optional(),
  bestemmingNaam: z.string().nullish(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullish(),
  advertentietekst: z.string().nullish(),
  hoofdDocumentId: z.number().int().nullish(),
  hoeveelhedenCount: z.number().int().optional(),
  coordinates: z.array(VgeCoordinateDto).nullish(),
  aantalAdresseerbaar: z.number().int().optional(),
  aantalOverigGebouwd: z.number().int().optional(),
  aantalTerreinen: z.number().int().optional(),
})
const GebouwdeelDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const GebouwdeelListDto = z.object({ id: z.number().int(), naam: z.string().nullable(), code: z.string().nullable(), gebouwNaam: z.string().nullable() })
const GebouwdeelListDtoPaginatedList = z.object({
  items: z.array(GebouwdeelListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const AddVgeToGebouwdeelCommand = z.object({ gebouwdeelId: z.number().int(), vgeIds: z.array(z.number().int()).nullable() })
const RemoveVgeFromGebouwdeelCommand = z.object({ gebouwdeelId: z.number().int(), vgeIds: z.array(z.number().int()).nullable() })
const OppervlakteNen2580Dto = z.object({
  bouwlaagId: z.number().int(),
  kompasrichtingOptie: z.number().int().nullable(),
  goWoonfunctie: z.number().nullable(),
  goOverigeFunctie: z.number().nullable(),
  goGemeenschappelijkeRuimten: z.number().nullable(),
  goUtiliteitsfuncties: z.number().nullable(),
  goOverigeAangrenzendeRuimteBergingGarage: z.number().nullable(),
  goGetekendVolgensNen: z.boolean().nullable(),
  bvoGebouw: z.number().nullable(),
  bvoOverdekteGebouwgebondenBuitenruimten: z.number().nullable(),
  bvoNietOverdekteGebouwgebondenBuitenruimten: z.number().nullable(),
  bvoGemeenschappelijkeBinnenruimteWoningen: z.number().nullable(),
  bvoGemeenschappelijkeBinnenruimteBouwlaag: z.number().nullable(),
  bvoGemeenschappelijkeBinnenruimteGebouw: z.number().nullable(),
  bvoGemeenschappelijkeNietOverdekteBuitenruimten: z.number().nullable(),
  bvoOverigeRuimten: z.number().nullable(),
  vvoUnit: z.number().nullable(),
  vvoGemeenschappelijkeRuimten: z.number().nullable(),
  vvoOverigeRuimten: z.number().nullable(),
  bvoTotaal: z.number().nullable(),
  vvoTotaal: z.number().nullable(),
  goTotaal: z.number().nullable(),
  bouwlaag: z.string().nullable(),
})
const OppervlakteNen2580HeaderDto = z.object({
  importDoor: z.string().nullable(),
  importOp: z.string().nullable(),
  importBestand: z.string().nullable(),
  berekeningswijze: z.string().nullable(),
  kompasrichting: z.string().nullable(),
  oppervlaktes: z.array(OppervlakteNen2580Dto).nullable(),
})
const CartotheekIcon = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  fonticoon: z.string().nullable(),
  numberOfItems: z.number().int().nullable(),
})
const InstallatieDto = z.object({
  id: z.number().int().nullable(),
  categorieId: z.number().int(),
  categorieDisplay: z.string().nullable(),
  elementId: z.number().int().nullable(),
  elementDisplay: z.string().nullable(),
  elementdetailId: z.number().int().nullable(),
  elementdetailDisplay: z.string().nullable(),
  materiaalSoortId: z.number().int().nullable(),
  materiaalSoortDisplay: z.string().nullable(),
  nlSfbElementId: z.number().int().nullable(),
  nlSfbCode: z.string().nullable(),
  nlSfbOmschrijving: z.string().nullable(),
  vgeRuimteId: z.number().int().nullable(),
  ruimte: z.string().nullable(),
  ruimteSoortErpId: z.number().int().nullable(),
  defectlocatieId: z.number().int().nullable(),
  locatieaanduiding: z.string().nullable(),
  asbest: z.boolean(),
  referentieExtern: z.string().nullable(),
  referentieKostenplaats: z.string().nullable(),
  opmerking: z.string().nullable(),
  woningVerbeteringType: z.number().int().nullable(),
  zavOnderhoud: z.boolean(),
  collectief: z.boolean(),
  beheerderId: z.number().int().nullable(),
  beheerderDisplay: z.string().nullable(),
  bron: z.string().nullable(),
  productGtin: z.string().nullable(),
  productKlasse: z.string().nullable(),
  productGln: z.string().nullable(),
  productFabrikant: z.string().nullable(),
  productMerk: z.string().nullable(),
  productSerie: z.string().nullable(),
  productType: z.string().nullable(),
  productCode: z.string().nullable(),
  productOpmerking: z.string().nullable(),
  productUse2BA: z.boolean(),
  productKleur: z.string().nullable(),
  productLengte: z.number().nullable(),
  productBreedte: z.number().nullable(),
  productHoogte: z.number().nullable(),
  productAantal: z.number().nullable(),
  wattpiek: z.number().int().nullable(),
  productEenheidId: z.number().int().nullable(),
  productFabricageJaar: z.number().int().nullable(),
  leverancierId: z.number().int().nullable(),
  leverancierHandelsnaam: z.string().nullable(),
  plaatsingDatum: z.string().datetime({ offset: true }).nullable(),
  vervangingsDatum: z.string().datetime({ offset: true }).nullable(),
  garantieGlnUitvoerder: z.string().nullable(),
  garantieUitvoerder: z.string().nullable(),
  garantieIngangsDatum: z.string().datetime({ offset: true }).nullable(),
  garantieEindDatum: z.string().datetime({ offset: true }).nullable(),
  garantieProductNotificatieDatum: z.string().datetime({ offset: true }).nullable(),
  contractId: z.number().int().nullable(),
  werkzaamhedenIngangsDatum: z.string().datetime({ offset: true }).nullable(),
  werkzaamhedenEindDatum: z.string().datetime({ offset: true }).nullable(),
  werkzaamhedenNotificatieDatum: z.string().datetime({ offset: true }).nullable(),
  keuringPartijId: z.number().int().nullable(),
  keuringDatum: z.string().datetime({ offset: true }).nullable(),
  keuringGeldigTot: z.string().datetime({ offset: true }).nullable(),
  keuringNotificatieDatum: z.string().datetime({ offset: true }).nullable(),
  vgeId: z.number().int().nullable(),
  gebouwId: z.number().int().nullable(),
  gebouwdeelId: z.number().int().nullable(),
})
const GebouwDeelVgeListDto = z.object({
  vgeId: z.number().int(),
  srtNm: z.string().nullable(),
  adres: z.string().nullable(),
  wpl: z.string().nullable(),
  locatieaanduiding: z.string().nullable(),
  pc: z.string().nullable(),
  vhes: z.array(z.string()).nullable(),
  bn: z.string().nullable(),
  hist: z.boolean(),
  isCollectief: z.boolean().nullable(),
})
const GebouwAddOrUpdateDto = z.object({
  code: z.string().min(1),
  naam: z.string().min(1),
  gebouwId: z.number().int().optional(),
  type: z.number().int().nullish(),
  registratieniveau: z.string().nullish(),
  omschrijving: z.string().nullish(),
  vastgoedportefeuilleId: z.number().int().nullish(),
  vastgoedportefeuilleNaam: z.string().nullish(),
  vastgoeddeelportefeuilleId: z.number().int().nullish(),
  vastgoeddeelportefeuilleNaam: z.string().nullish(),
  werkgebiedId: z.number().int().nullish(),
  werkgebiedNaam: z.string().nullish(),
  advertentietekst: z.string().nullish(),
  serviceadresVge: z.number().int().nullish(),
  serviceAdresVgeNaam: z.string().nullish(),
  gebouwenClusterId: z.number().int().nullish(),
  gebouwenClusterNaam: z.string().nullish(),
  serviceEmailadres: z.string().nullish(),
  serviceTelefoonnummer: z.string().nullish(),
  bestemmingId: z.number().int().nullish(),
  bestemmingNaam: z.string().nullish(),
  hoeveelhedenCount: z.number().int().optional(),
  coordinates: z.array(VgeCoordinateDto).nullish(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullish(),
  aantalGebouwdelen: z.number().int().optional(),
  aantalAdresseerbaar: z.number().int().optional(),
  aantalOverigGebouwd: z.number().int().optional(),
  aantalTerreinen: z.number().int().optional(),
})
const GebouwDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const GerelateerdGebouwDeelListDto = z.object({ gebouwDeelId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const GebouwenListDto = z.object({
  gebouwId: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  type: z.number().int(),
  omschrijving: z.string().nullable(),
  gebouwDelen: z.array(GerelateerdGebouwDeelListDto).nullable(),
})
const GebouwenListDtoPaginatedList = z.object({
  items: z.array(GebouwenListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwListDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  gebouwClusterNaam: z.string().nullable(),
  aantalAdresseerbaar: z.number().int(),
  aantalOverigGebouwd: z.number().int(),
  aantalTerreinen: z.number().int(),
})
const GebouwListDtoPaginatedList = z.object({
  items: z.array(GebouwListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwDefectLocatieDto = z.object({
  gebouwDefectlocatieId: z.number().int(),
  gebouwId: z.number().int(),
  defectlocatieId: z.number().int(),
  defectlocatie: z.string().nullable(),
  beheerderId: z.number().int(),
  beheerder: z.string().nullable(),
  externeReferentie: z.string().nullable(),
  locatieaanduiding: z.string().nullable(),
})
const GebouwDefectlocatieAddorUpdateDto = z.object({
  gebouwDefectlocatieId: z.number().int(),
  gebouwId: z.number().int(),
  defectlocatieId: z.number().int(),
  beheerderId: z.number().int(),
  externeReferentie: z.string().nullish(),
  locatieaanduiding: z.string().nullish(),
})
const GebouwDefectlocatieDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const AddVgeToGebouwCommand = z.object({ gebouwId: z.number().int(), vgeIds: z.array(z.number().int()).nullable(), linkVgeContracten: z.boolean() })
const RemoveVgeFromGebouwCommand = z.object({ gebouwId: z.number().int(), vgeIds: z.array(z.number().int()).nullable() })
const GebouwHoeveelheidDto = z.object({
  id: z.number().int(),
  gebouwCluster: z.string().nullable(),
  gebouwDeel: z.string().nullable(),
  hoofdElementGroep: z.string().nullable(),
  plaatsaanduiding: z.string().nullable(),
  element: z.string().nullable(),
  hoeveelheid: z.number(),
  eenheidId: z.number().int(),
  vervangingsJaar: z.number().int().nullable(),
  inspectieDatum: z.string().datetime({ offset: true }).nullable(),
  conditie: z.number().int().nullable(),
  toelichting: z.string().nullable(),
})
const GebouwHoeveelheidDisplayDto = z.object({
  id: z.number().int(),
  gebouwCluster: z.string().nullable(),
  gebouwDeel: z.string().nullable(),
  hoofdElementGroep: z.string().nullable(),
  plaatsaanduiding: z.string().nullable(),
  element: z.string().nullable(),
  hoeveelheid: z.number(),
  eenheidId: z.number().int(),
  vervangingsJaar: z.number().int().nullable(),
  inspectieDatum: z.string().datetime({ offset: true }).nullable(),
  conditie: z.number().int().nullable(),
  toelichting: z.string().nullable(),
  hoofdElementGroepOmschrijving: z.string().nullable(),
  elementOmschrijving: z.string().nullable(),
})
const GebouwVgeListDto = z.object({
  vgeId: z.number().int(),
  adres: z.string().nullable(),
  srtNm: z.string().nullable(),
  wpl: z.string().nullable(),
  locatieaanduiding: z.string().nullable(),
  pc: z.string().nullable(),
  gdlCd: z.string().nullable(),
  vhes: z.array(z.string()).nullable(),
  bn: z.string().nullable(),
  isCollectief: z.boolean().nullable(),
  hist: z.boolean(),
})
const GebouwHoeveelheidListDto = z.object({
  id: z.number().int(),
  gebouwCluster: z.string().nullable(),
  gebouwDeel: z.string().nullable(),
  hoofdElementGroep: z.string().nullable(),
  plaatsaanduiding: z.string().nullable(),
  element: z.string().nullable(),
  hoeveelheid: z.number(),
  eenheidId: z.number().int(),
  vervangingsJaar: z.number().int().nullable(),
  inspectieDatum: z.string().datetime({ offset: true }).nullable(),
  conditie: z.number().int().nullable(),
  toelichting: z.string().nullable(),
  hoofdElementGroepOmschrijving: z.string().nullable(),
  elementOmschrijving: z.string().nullable(),
})
const GebouwHoeveelheidListDtoPaginatedList = z.object({
  items: z.array(GebouwHoeveelheidListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const GebouwGebouwdelenListDto = z.object({
  gebouwdeelId: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  gebouwNaam: z.string().nullable(),
})
const GebouwGebouwdelenListDtoPaginatedList = z.object({
  items: z.array(GebouwGebouwdelenListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateGebouwenClusterCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullable(),
  advertentietekst: z.string().nullable(),
  hoeveelhedenCount: z.number().int(),
  vgeCoordinates: z.array(VgeCoordinateDto).nullable(),
})
const GerelateerdGebouwListDto = z.object({
  gebouwId: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  gebouwDelen: z.array(GerelateerdGebouwDeelListDto).nullable(),
})
const GebouwenClusterListDto = z.object({
  gebouwClusterId: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  gerelateerdGebouwListDto: z.array(GerelateerdGebouwListDto).nullable(),
})
const GebouwenClusterListDtoPaginatedList = z.object({
  items: z.array(GebouwenClusterListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const UpdateGebouwenClusterCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullable(),
  advertentietekst: z.string().nullable(),
  hoeveelhedenCount: z.number().int(),
  vgeCoordinates: z.array(VgeCoordinateDto).nullable(),
})
const GebouwOnderhoudsbedrijfDto = z.object({
  gebouwId: z.number().int().nullable(),
  onderhoudsbedrijfTyperingId: z.number().int(),
  typeringNaam: z.string().nullable(),
  rechtspersoonId: z.number().int().nullable(),
  handelsNaam: z.string().nullable(),
  emailadres: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  contactpersoon: z.string().nullable(),
})
const GebouwOnderhoudsbedrijfDtoPaginatedList = z.object({
  items: z.array(GebouwOnderhoudsbedrijfDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateGebouwOnderhoudsbedrijfCommand = z.object({
  gebouwId: z.number().int(),
  onderhoudsbedrijfTyperingId: z.number().int(),
  rechtspersoonId: z.number().int().nullable(),
})
const UpdateGebouwOnderhoudsbedrijfCommand = z.object({
  gebouwId: z.number().int(),
  onderhoudsbedrijfTyperingId: z.number().int(),
  rechtspersoonId: z.number().int().nullable(),
})
const HuurcontractbepalingListDto = z.object({ huurcontractbepalingId: z.number().int(), naam: z.string().nullable(), titel: z.string().nullable() })
const HuurcontractbepalingListDtoPaginatedList = z.object({
  items: z.array(HuurcontractbepalingListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const HuurcontractbepalingAddOrUpdateDto = z.object({ naam: z.string().min(3), titel: z.string().min(10), content: z.string().min(10) })
const HuurcontractbepalingDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const HuurcontractbepalingGebouwclusterListDto = z.object({ gebouwClusterId: z.number().int(), gebouwClusterNaam: z.string().nullable() })
const HuurcontractbepalingGebouwclusterListDtoPaginatedList = z.object({
  items: z.array(HuurcontractbepalingGebouwclusterListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const HuurcontractbepalingGebouwListDto = z.object({ gebouwId: z.number().int(), gebouwNaam: z.string().nullable() })
const HuurcontractbepalingGebouwListDtoPaginatedList = z.object({
  items: z.array(HuurcontractbepalingGebouwListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const HuurcontractbepalingGebouwdeelListDto = z.object({ gebouwdeelId: z.number().int(), gebouwdeelNaam: z.string().nullable() })
const HuurcontractbepalingGebouwdeelListDtoPaginatedList = z.object({
  items: z.array(HuurcontractbepalingGebouwdeelListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const NietGekoppeldGebouwclusterListDto = z.object({
  gebouwClusterId: z.number().int(),
  gebouwclusterNaam: z.string().nullable(),
  gebouwclusterCode: z.string().nullable(),
})
const NietGekoppeldGebouwclusterListDtoPaginatedList = z.object({
  items: z.array(NietGekoppeldGebouwclusterListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const NietGekoppeldGebouwListDto = z.object({ gebouwId: z.number().int(), gebouwNaam: z.string().nullable(), gebouwCode: z.string().nullable() })
const NietGekoppeldGebouwListDtoPaginatedList = z.object({
  items: z.array(NietGekoppeldGebouwListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const NietGekoppeldGebouwdeelListDto = z.object({
  gebouwdeelId: z.number().int(),
  gebouwdeelNaam: z.string().nullable(),
  gebouwdeelCode: z.string().nullable(),
})
const NietGekoppeldGebouwdeelListDtoPaginatedList = z.object({
  items: z.array(NietGekoppeldGebouwdeelListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const KoppelingGebouwclusterCommand = z.object({ huurcontractbepalingId: z.number().int(), gebouwClusterIds: z.array(z.number().int()).nullable() })
const KoppelingGebouwCommand = z.object({ huurcontractbepalingId: z.number().int(), gebouwIds: z.array(z.number().int()).nullable() })
const KoppelingGebouwdeelCommand = z.object({ huurcontractbepalingId: z.number().int(), gebouwdeelIds: z.array(z.number().int()).nullable() })
const KoppelingVgeCommand = z.object({ huurcontractbepalingId: z.number().int(), vgeIds: z.array(z.number().int()).nullable() })
const OntkoppelingGebouwclusterCommand = z.object({ huurcontractbepalingId: z.number().int(), itemsToDelete: z.array(z.number().int()).nullable() })
const OntkoppelingGebouwCommand = z.object({ huurcontractbepalingId: z.number().int(), itemsToDelete: z.array(z.number().int()).nullable() })
const OntkoppelingGebouwdeelCommand = z.object({ huurcontractbepalingId: z.number().int(), itemsToDelete: z.array(z.number().int()).nullable() })
const OntkoppelingVgeCommand = z.object({ huurcontractbepalingId: z.number().int(), itemsToDelete: z.array(z.number().int()).nullable() })
const ImportInfo = z.object({ id: z.number().int().optional(), name: z.string().nullable(), uploadEndpoint: z.string().nullable() })
const InspectiecategorieActiefDto = z.object({
  inspectieCategorieActiefId: z.number().int(),
  inspectieCategorie: z.number().int(),
  inspectieCategorieNaam: z.string().nullable(),
  vooropname: z.boolean(),
  eindopname: z.boolean(),
  tweedeEindopname: z.boolean(),
  aanvangsopname: z.boolean(),
  onderhoudsinspectie: z.boolean(),
  opleverinspectie: z.boolean(),
  vastgoedinspectie: z.boolean(),
})
const InspectiecategorieActiefDtoPaginatedList = z.object({
  items: z.array(InspectiecategorieActiefDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const InspectiecategorieActiefUpdateCommand = z.object({
  inspectieCategorieActiefId: z.number().int(),
  vooropname: z.boolean(),
  eindopname: z.boolean(),
  tweedeEindopname: z.boolean(),
  aanvangsopname: z.boolean(),
  onderhoudsinspectie: z.boolean(),
  opleverinspectie: z.boolean(),
  vastgoedinspectie: z.boolean(),
})
const InspectieOpnamedetailCartotheekMatrixDto = z.object({ elementElementDetailId: z.number().int(), inspectieSectieTypeId: z.number().int() })
const ElementElementDetailMatrixLeftDto = z.object({ id: z.number().int(), naam: z.string().nullable() })
const InspectieSectieTypeMatrixTopDto = z.object({ id: z.number().int(), naam: z.string().nullable() })
const InspectieOpnameTypeDto = z.object({ id: z.number().int(), naam: z.string().nullable() })
const InspectieSjabloonListDto = z.object({ inspectieSjabloonId: z.number().int(), naam: z.string().nullable() })
const InspectieSjabloonListDtoPaginatedList = z.object({
  items: z.array(InspectieSjabloonListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const InspectieSjabloonAddOrUpdateDto = z.object({ naam: z.string().min(3) })
const InspectieSjabloonDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const InspectieSjabloonTenantInspectietaakListDto = z.object({ inspectieSjabloonTenantInspectietaakId: z.number().int(), taak: z.string().nullable() })
const InspectieSjabloonTenantInspectietaakListDtoPaginatedList = z.object({
  items: z.array(InspectieSjabloonTenantInspectietaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const ActiefInspectietaakListDto = z.object({ inspectietaakId: z.number().int(), taak: z.string().nullable() })
const ActiefInspectietaakListDtoPaginatedList = z.object({
  items: z.array(ActiefInspectietaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const KoppelingInspectietaakCommand = z.object({ onderhoudssjablonId: z.number().int(), inspectietaakIds: z.array(z.number().int()).nullable() })
const OntkoppelingInspectietaakCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const InspectietaakListDto = z.object({
  inspectietaakId: z.number().int(),
  taak: z.string().nullable(),
  elementcategorie: z.string().nullable(),
  cartotheekItem: z.string().nullable(),
  werkzaamheidId: z.number().int(),
  werkzaamheid: z.string().nullable(),
  elementElementId: z.number().int(),
  toelichting: z.string().nullable(),
  type: z.string().nullable(),
  merk: z.string().nullable(),
  hoogte: z.number(),
  lengte: z.number(),
  breedte: z.number(),
  meetkundigeEenheidHlbId: z.number().int(),
  meetkundigeEenheidHlb: z.string().nullable(),
  bestekcode: z.string().nullable(),
  meetkundigeEenheidId: z.number().int(),
  meetkundigeEenheid: z.string().nullable(),
})
const InspectietaakListDtoPaginatedList = z.object({
  items: z.array(InspectietaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const InspectietaakAddOrUpdateDto = z.object({
  elementElementId: z.number().int(),
  werkzaamheidId: z.number().int(),
  meetkundigeEenheidHlbId: z.number().int(),
  meetkundigeEenheidId: z.number().int(),
  toelichting: z.string().nullish(),
  type: z.string().nullish(),
  merk: z.string().nullish(),
  hoogte: z.number().optional(),
  lengte: z.number().optional(),
  breedte: z.number().optional(),
  bestekcode: z.string().nullish(),
  taak: z.string().min(1),
  verkorteInspectietaak: z.string().min(1),
})
const InspectietaakDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const DeleteInstallatiesCommand = z.object({ installatieIds: z.array(z.number().int()).nullable() })
const KadastraalObjectDto = z.object({
  kadastraleAanduiding: z.string().nullable(),
  gerechtigdeNummer: z.string().nullable(),
  gerechtigdeNaam: z.string().nullable(),
  gerechtigdeAdres: z.string().nullable(),
  kadastraleAanduidingVlak: z.string().nullable(),
})
const KadastraalObjectDtoPaginatedList = z.object({
  items: z.array(KadastraalObjectDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const KadastraalSubjectDto = z.object({ id: z.string().nullable(), naam: z.string().nullable(), adres: z.string().nullable() })
const KadastraalSubjectDtoPaginatedList = z.object({
  items: z.array(KadastraalSubjectDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const KostensoortDto = z.object({ kostensoortId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const KostensoortDtoPaginatedList = z.object({
  items: z.array(KostensoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateKostensoortCommand = z.object({ kostensoortId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const UpdateKostensoortCommand = z.object({ kostensoortId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const SbiActiviteit = z.object({ sbiCode: z.string().nullable(), sbiOmschrijving: z.string().nullable(), indHoofdactiviteit: z.string().nullable() })
const Adres = z.object({
  type: z.string().nullable(),
  indAfgeschermd: z.string().nullable(),
  volledigAdres: z.string().nullable(),
  straatnaam: z.string().nullable(),
  huisnummer: z.number().int().nullable(),
  huisnummerToevoeging: z.string().nullable(),
  huisletter: z.string().nullable(),
  aanduidingBijHuisnummer: z.string().nullable(),
  toevoegingAdres: z.string().nullable(),
  postcode: z.string().nullable(),
  postbusnummer: z.number().int().nullable(),
  plaats: z.string().nullable(),
  straatHuisnummer: z.string().nullable(),
  postcodeWoonplaats: z.string().nullable(),
  regio: z.string().nullable(),
  land: z.string().nullable(),
})
const BinnenlandsAdres = z.object({
  straatnaam: z.string().nullable(),
  huisnummer: z.number().int().nullable(),
  huisletter: z.string().nullable(),
  postbusnummer: z.number().int().nullable(),
  postcode: z.string().nullable(),
  plaats: z.string().nullable(),
  type: z.string().nullable(),
})
const BuitenlandsAdres = z.object({
  straatHuisnummer: z.string().nullable(),
  postcodeWoonplaats: z.string().nullable(),
  land: z.string().nullable(),
  type: z.string().nullable(),
})
const KvkAdres = z.object({ binnenlandsAdres: BinnenlandsAdres, buitenlandsAdres: BuitenlandsAdres })
const KvkDetailsDto = z.object({
  kvkNummer: z.string().nullable(),
  vestigingsNummer: z.string().nullable(),
  rsin: z.string().nullable(),
  naam: z.string().nullable(),
  straat: z.string().nullable(),
  huisnummer: z.string().nullable(),
  postcode: z.string().nullable(),
  huisletter: z.string().nullable(),
  woonplaats: z.string().nullable(),
  sbiActiviteiten: z.array(SbiActiviteit).nullable(),
  adressen: z.array(Adres).nullable(),
  adres: KvkAdres,
  isNonMailing: z.boolean().nullable(),
  formeleRegistratiedatum: z.string().nullable(),
  isHoofdvestiging: z.boolean().nullable(),
  totaalWerkzamePersonen: z.number().int().nullable(),
  websites: z.array(z.string()).nullable(),
})
const KvkDetailsDtoPaginatedList = z.object({
  items: z.array(KvkDetailsDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const MateriaalSoortDto = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const MateriaalSoortDtoPaginatedList = z.object({
  items: z.array(MateriaalSoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const MeetkundigeEenheidDto = z.object({ id: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const MeetkundigeEenheidDtoPaginatedList = z.object({
  items: z.array(MeetkundigeEenheidDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const MetertypeDto = z.object({
  metertypeId: z.number().int(),
  omschrijving: z.string().nullable(),
  eenheidId: z.number().int(),
  aantalDecimalen: z.number().int(),
  fonticoonNaam: z.string().nullable(),
})
const MetertypeDtoPaginatedList = z.object({
  items: z.array(MetertypeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const MetertypeAddOrUpdateDto = z.object({
  omschrijving: z.string().nullable(),
  eenheidId: z.number().int(),
  aantalDecimalen: z.number().int(),
  fonticoonId: z.number().int().nullable(),
})
const MetertypeDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const ElementSuggestion = z.object({ id: z.number().int(), code: z.string().nullable(), description: z.string().nullable(), selected: z.boolean() })
const NlsfbBouwkundigElementDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  tekstCodeNotatie: z.string().nullable(),
  codeNotatie: z.string().nullable(),
  niveau: z.number().int(),
  bovenliggendNiveau: z.string().nullable(),
  omschrijving: z.string().nullable(),
  classificatie: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const NlsfbBouwkundigElementDtoPaginatedList = z.object({
  items: z.array(NlsfbBouwkundigElementDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateNlSfBElementenCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  tekstCodeNotatie: z.string().nullable(),
  codeNotatie: z.string().nullable(),
  niveau: z.number().int(),
  bovenliggendNiveau: z.string().nullable(),
  omschrijving: z.string().nullable(),
  classificatie: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const UpdateNlSfbElementenCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  tekstCodeNotatie: z.string().nullable(),
  codeNotatie: z.string().nullable(),
  niveau: z.number().int(),
  bovenliggendNiveau: z.string().nullable(),
  omschrijving: z.string().nullable(),
  classificatie: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const OnderhoudsbedrijfTyperingDto = z.object({ onderhoudsbedrijfTyperingId: z.number().int(), naam: z.string().nullable() })
const OnderhoudsbedrijfTyperingDtoPaginatedList = z.object({
  items: z.array(OnderhoudsbedrijfTyperingDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateOnderhoudsbedrijfTyperingCommand = z.object({ onderhoudsbedrijfTyperingId: z.number().int(), naam: z.string().nullable() })
const UpdateOnderhoudsbedrijfTyperingCommand = z.object({ onderhoudsbedrijfTyperingId: z.number().int(), naam: z.string().nullable() })
const GetOnderhoudscontractVgesQuery = z.object({
  pageIndex: z.number().int().gte(1).lte(200000),
  pageSize: z.number().int().gte(1).lte(200000),
  sortDirection: z.string().nullable(),
  sortKey: z.string().nullable(),
  gebouwIds: z.array(z.number().int()).nullable(),
  gebouwdeelIds: z.array(z.number().int()).nullable(),
  filter: z.string().nullable(),
})
const GebouwIds = z.array(z.number().int()).optional()
const ContractGebouwDto = z.object({ gebouwId: z.number().int(), naam: z.string().nullable(), code: z.string().nullable() })
const ContractGebouwDeelDto = z.object({ gebouwDeelId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const TenantTaakDto = z.object({
  tenantTaakId: z.number().int().nullable(),
  taakId: z.number().int(),
  taakNaam: z.string().nullable(),
  dienstencomponentIds: z.array(z.number().int()).nullable(),
  dienstencomponenten: z.array(DienstenComponentDto).nullable(),
  dienstencomponentOmschrijving: z.string().nullable(),
  meetkundigeEenheidId: z.number().int().nullable(),
  meetkundigeEenheidCode: z.string().nullable(),
  meetkundigeEenheidOmschrijving: z.string().nullable(),
  onderhoudstaakErpId: z.number().int().nullable(),
  onderhoudstaakErpCode: z.string().nullable(),
  onderhoudstaakErpOmschrijving: z.string().nullable(),
  onderhoudsspecialismeId: z.number().int().nullable(),
  onderhoudsspecialismeCode: z.string().nullable(),
  onderhoudsspecialismeNaam: z.string().nullable(),
  bewerkingstijdMin: z.number().int().nullable(),
  urgentieDgn: z.number().int().nullable(),
  afspraak: z.boolean().nullable(),
  actief: z.boolean().nullable(),
  tonenAanKlant: z.boolean().nullable(),
  verkoopprijs: z.number().nullable(),
  kostprijs: z.number().nullable(),
  betalerOptieId: z.number().int().nullable(),
  betalerNaam: z.string().nullable(),
  heeftInspectieNodig: z.boolean().nullable(),
  teBeoordelen: z.boolean().nullable(),
  metOnderhoudscontract: z.boolean().nullable(),
  portalToelichtingBijOntbrekendContract: z.string().nullable(),
  zelfOplossen: z.boolean().nullable(),
  portalToelichtingZelfOplossen: z.string().nullable(),
  isSpoed: z.boolean().nullable(),
  spoedToelichting: z.string().nullable(),
})
const OnderhoudscontractDto = z.object({
  id: z.number().int(),
  contractNummer: z.string().nullable(),
  contractOmschrijving: z.string().nullable(),
  leverancierId: z.number().int(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }),
  uitersteOpzegDatum: z.string().datetime({ offset: true }),
  notificatieDatum: z.string().datetime({ offset: true }).nullable(),
  contractBeheerder: z.string().nullable(),
  voorVolledigBezit: z.boolean(),
  intakeDoorCorporatie: z.boolean(),
  gebouwen: z.array(ContractGebouwDto).nullable(),
  gebouwDelen: z.array(ContractGebouwDeelDto).nullable(),
  vges: z.array(VgeDto).nullable(),
  taken: z.array(TenantTaakDto).nullable(),
})
const OnderhoudscontractDtoPaginatedList = z.object({
  items: z.array(OnderhoudscontractDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const OnderhoudscontractTaakDto = z.object({
  tenantTaakId: z.number().int(),
  element: z.string().nullable(),
  elementdetail: z.string().nullable(),
  materiaalsoort: z.string().nullable(),
  defectsoort: z.string().nullable(),
  omschrijving: z.string().nullable(),
})
const OnderhoudsspecialismeDto = z.object({ onderhoudsspecialismeId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const OnderhoudsspecialismeDtoPaginatedList = z.object({
  items: z.array(OnderhoudsspecialismeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateOnderhoudsspecialismeCommand = z.object({ onderhoudsspecialismeId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const UpdateOnderhoudsspecialismeCommand = z.object({ onderhoudsspecialismeId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const OnderhoudsTaakErpDto = z.object({ onderhoudstaakErpId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const OnderhoudsTaakErpDtoPaginatedList = z.object({
  items: z.array(OnderhoudsTaakErpDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateOnderhoudsTaakErpCommand = z.object({ onderhoudstaakErpId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const UpdateOnderhoudsTaakErpCommand = z.object({ onderhoudstaakErpId: z.number().int(), code: z.string().nullable(), omschrijving: z.string().nullable() })
const GerechtigdeDto = z.object({
  nrGerechtigde: z.number().int(),
  gerechtigde: z.string().nullable(),
  rechtCode: z.string().nullable(),
  volgNummer: z.number().int(),
  aandeel: z.string().nullable(),
  bronDocument: z.string().nullable(),
})
const PerceelDto = z.object({
  vgeId: z.number().int(),
  perceel: z.string().nullable(),
  kadastraleGemeente: z.string().nullable(),
  complexIndex: z.string().nullable(),
  grootte: z.number().int().nullable(),
  nrGerechtigde: z.number().int().nullable(),
  naamGerechtigde: z.string().nullable(),
  bronDocument: z.string().nullable(),
  datumBronDocument: z.string().datetime({ offset: true }).nullable(),
  cultuurCodeBebouwd: z.string().nullable(),
  cultuurCodeOnbebouwd: z.string().nullable(),
  latitude: z.number().nullable(),
  longitude: z.number().nullable(),
  kadastraleAanduidingObject: z.string().nullable(),
  kadastraleAanduidingVlak: z.string().nullable(),
  gerechtigden: z.array(GerechtigdeDto).nullable(),
})
const ProductFeature = z.object({ id: z.string().nullable(), name: z.string().nullable(), value: z.string().nullable() })
const Product = z.object({
  description: z.string().nullable(),
  features: z.array(ProductFeature).nullable(),
  gtin: z.string().nullable(),
  id: z.number().int(),
  manufacture: z.string().nullable(),
  manufacturerGLN: z.string().nullable(),
  model: z.string().nullable(),
  productClassDescription: z.string().nullable(),
  productCode: z.string().nullable(),
  tradeItemCount: z.number().int(),
  version: z.string().nullable(),
})
const ProductPaginatedList = z.object({
  items: z.array(Product).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const PubSubEntityDto = z.object({ name: z.string().nullable(), version: z.string().nullable() })
const SubscriptionDto = z.object({ name: z.string().nullable(), version: z.string().nullable() })
const InitialLoadCommand = z.object({
  entityVersion: z.string().nullable(),
  entityName: z.string().nullable(),
  blobUrl: z.string().nullable(),
  tenantKey: z.string().nullable(),
})
const QueryInfo = z.object({ id: z.number().int(), title: z.string().nullable(), url: z.string().nullable() })
const RechtspersoonListDto = z.object({
  id: z.number().int(),
  rolNaam: z.string().nullish(),
  externeReferentie: z.string().nullish(),
  handelsnaam: z.string().nullish(),
  adres: z.string().nullish(),
  woonplaats: z.string().nullish(),
  telefoonnummer: z.string().nullish(),
})
const RechtspersoonListDtoPaginatedList = z.object({
  items: z.array(RechtspersoonListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const RechtspersoonDto = z.object({
  id: z.number().int(),
  handelsnaam: z.string().nullable(),
  externeReferentie: z.string().nullable(),
  rolId: z.number().int(),
  rolNaam: z.string().nullable(),
  gln: z.string().nullable(),
  kvkNummer: z.string().nullable(),
  vestigingsnummer: z.string().nullable(),
  rsin: z.string().nullable(),
  straat: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  nummeraanduidingId: z.string().nullable(),
  landCode: z.string().nullable(),
  emailadres: z.string().nullable(),
  website: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  contactpersoon: z.string().nullable(),
  geblokkeerd: z.boolean(),
  intakeDoorCorporatie: z.boolean(),
})
const RechtspersoonDtoPaginatedList = z.object({
  items: z.array(RechtspersoonDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const RechtspersoonAddOrUpdateDto = z.object({
  handelsnaam: z.string().min(1),
  externeReferentie: z.string().nullish(),
  rolId: z.number().int(),
  gln: z.string().nullish(),
  kvkNummer: z.string().nullish(),
  vestigingsnummer: z.string().nullish(),
  rsin: z.string().nullish(),
  straat: z.string().nullish(),
  postcode: z.string().nullish(),
  woonplaats: z.string().nullish(),
  nummeraanduidingId: z.string().nullish(),
  landCode: z.string().nullish(),
  emailadres: z.string().nullish(),
  website: z.string().nullish(),
  telefoonnummer: z.string().nullish(),
  contactpersoon: z.string().nullish(),
  contactBinnenXDagen: z.number().int().nullish(),
  geblokkeerd: z.boolean().nullish(),
  intakeDoorCorporatie: z.boolean().nullish(),
})
const DeleteRechtspersoonCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const RelatieRolDto = z.object({ relatieRolId: z.number().int(), naam: z.string().nullable() })
const RelatieRolDtoPaginatedList = z.object({
  items: z.array(RelatieRolDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CoenCadRuimteSoortDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  wwd: z.boolean(),
  vertrek: z.boolean(),
  overigeRuimte: z.boolean(),
  buitenRuimte: z.boolean(),
  verkeersruimte: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  veraVersie: z.number().nullable(),
  aantal: z.number().int(),
  defectLocatieId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  defectLocatieNaam: z.string().nullable(),
  coenCadCodes: z.array(CoenCadCodeDto).nullable(),
})
const CoenCadRuimteSoortDtoPaginatedList = z.object({
  items: z.array(CoenCadRuimteSoortDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateRuimteSoortCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  wwd: z.boolean(),
  vertrek: z.boolean(),
  overigeRuimte: z.boolean(),
  buitenRuimte: z.boolean(),
  verkeersruimte: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  veraVersie: z.number().nullable(),
  aantal: z.number().int(),
  defectLocatieId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  defectLocatieNaam: z.string().nullable(),
  coenCadCodes: z.array(CoenCadCodeDto).nullable(),
})
const UpdateRuimteSoortCommand = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  wwd: z.boolean(),
  vertrek: z.boolean(),
  overigeRuimte: z.boolean(),
  buitenRuimte: z.boolean(),
  verkeersruimte: z.boolean(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
  veraVersie: z.number().nullable(),
  aantal: z.number().int(),
  defectLocatieId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  defectLocatieNaam: z.string().nullable(),
  coenCadCodes: z.array(CoenCadCodeDto).nullable(),
})
const RuimteSoortListItem = z.object({ id: z.number().int(), naam: z.string().nullable() })
const RuimtesoortErpDto = z.object({
  ruimtesoortErpId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  tenantId: z.number().int().nullable(),
})
const RuimtesoortErpDtoPaginatedList = z.object({
  items: z.array(RuimtesoortErpDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateRuimtesoortErpCommand = z.object({
  ruimtesoortErpId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  tenantId: z.number().int().nullable(),
})
const UpdateRuimtesoortErpCommand = z.object({
  ruimtesoortErpId: z.number().int(),
  code: z.string().nullable(),
  omschrijving: z.string().nullable(),
  tenantId: z.number().int().nullable(),
})
const SleuteltypeDto = z.object({
  sleuteltypeId: z.number().int(),
  inspectietaak: z.string().nullable(),
  omschrijving: z.string().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const SleuteltypeDtoPaginatedList = z.object({
  items: z.array(SleuteltypeDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const SleuteltypeAddOrUpdateDto = z.object({
  inspectietaakId: z.number().int().nullable(),
  omschrijving: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
})
const SleuteltypeDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const Verwerking = z.object({ type: z.string().nullable(), datum: z.string().nullable() })
const StatistiekDto = z.object({
  totalNumberOfVges: z.number().int(),
  totalNumberOfPercelen: z.number().int(),
  totalNumberOfVhes: z.number().int(),
  vgesIngedeeld: z.number().int(),
  vgesMetKadastraalObject: z.number().int(),
  vgesMetVerhuureenheid: z.number().int(),
  verwerkingen: z.array(Verwerking).nullable(),
})
const SysteeminstellingDto = z.object({
  tenantId: z.string().nullable(),
  tenantNaam: z.string().nullable(),
  tenantOmgeving: z.string().nullish(),
  tenantGuid: z.string().nullable(),
  vheNummerFormat: z.string().nullable(),
  vheNummerTotaleLengteNummer: z.number().int().optional(),
  vheNummerHuidigNummer: z.number().int().optional(),
  vheNummerActief: z.boolean().optional(),
  verhuurVheUrl: z.string().nullish(),
  verhuurVgeUrl: z.string().nullish(),
  crmActief: z.boolean().optional(),
  crmEndpoint: z.string().nullish(),
  crmClientId: z.string().nullish(),
  crmOAuthScope: z.string().nullish(),
  crmClientSecretGeldigTot: z.string().nullish(),
  fieldServiceActief: z.boolean().optional(),
  fieldServiceEndpoint: z.string().nullish(),
  fieldServiceClientId: z.string().nullish(),
  fieldServiceOAuthScope: z.string().nullish(),
  fieldServiceClientSecretGeldigTot: z.string().nullish(),
  irisFieldServiceActief: z.boolean().optional(),
  irisFieldServiceEndpoint: z.string().nullish(),
  irisFieldServiceClientId: z.string().nullish(),
  irisFieldServiceOAuthScope: z.string().nullish(),
  irisFieldServiceClientSecretGeldigTot: z.string().nullish(),
  businessCentralActief: z.boolean().optional(),
  businessCentralEndpoint: z.string().nullish(),
  businessCentralClientId: z.string().nullish(),
  businessCentralOAuthScope: z.string().nullish(),
  documentConfigurationActief: z.boolean().optional(),
  documentConfigurationAccountName: z.string().nullish(),
  businessCentralClientSecretGeldigTot: z.string().nullish(),
  vheNummerVoorloopKarakter: z.string().nullish(),
  heeftZakelijkeVerhuur: z.boolean().optional(),
  isEmpireKlant: z.boolean().optional(),
})
const VheNummeringUpdateDto = z.object({
  isActief: z.boolean(),
  prefix: z.string().max(9).nullable(),
  startVanaf: z.number().int().gte(1).lte(800000),
  totaleLengteCode: z.number().int().gte(0).lte(15),
  voorloopKarakter: z.string().max(1).nullable(),
})
const VerhuurUpdateDto = z.object({ vgeUrlToRentalApplication: z.string().url().nullable(), vheUrlToRentalApplication: z.string().url().nullable() })
const CrmConfigurationUpdateDto = z.object({ baseUrl: z.string().url().nullable(), oauthScope: z.string().nullable(), clientId: z.string().nullable() })
const CrmConfigurationSecretUpdateDto = z.object({ clientSecret: z.string().nullable(), clientSecretExpiresOn: z.string() })
const FieldServiceConfigurationUpdateDto = z.object({
  baseUrl: z.string().url().nullable(),
  oauthScope: z.string().nullable(),
  clientId: z.string().nullable(),
})
const FieldServiceConfigurationSecretUpdateDto = z.object({ clientSecret: z.string().nullable(), clientSecretExpiresOn: z.string() })
const IrisFieldServiceConfigurationUpdateDto = z.object({
  baseUrl: z.string().url().nullable(),
  oauthScope: z.string().nullable(),
  clientId: z.string().nullable(),
})
const IrisFieldServiceConfigurationSecretUpdateDto = z.object({ clientSecret: z.string().nullable(), clientSecretExpiresOn: z.string() })
const BusinessCentralConfigurationUpdateDto = z.object({
  baseUrl: z.string().url().nullable(),
  oauthScope: z.string().nullable(),
  clientId: z.string().nullable(),
})
const BusinessCentralConfigurationSecretUpdateDto = z.object({ clientSecret: z.string().nullable(), clientSecretExpiresOn: z.string() })
const DocumentConfigurationUpdateDto = z.object({ accountName: z.string().nullable() })
const DocumentConfigurationSecretUpdateDto = z.object({ accountKey: z.string().nullable() })
const TenantUpdateDto = z.object({
  naam: z.string().max(100).nullable(),
  omgeving: z.string().max(20).nullable(),
  heeftZakelijkeVerhuur: z.boolean(),
  isEmpireKlant: z.boolean(),
})
const TaakDto = z.object({
  id: z.number().int(),
  naam: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const TaakDtoPaginatedList = z.object({
  items: z.array(TaakDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const TaakListDto = z.object({
  taakId: z.number().int(),
  naam: z.string().nullable(),
  verkorteNaam: z.string().nullable(),
  ingangsDatum: z.string().nullable(),
  eindDatum: z.string().nullable(),
})
const TaakListDtoPaginatedList = z.object({
  items: z.array(TaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const TaakAddOrUpdateDto = z.object({ naam: z.string().min(1), verkorteNaam: z.string().min(1) })
const TaakDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const TenantDto = z.object({
  id: z.number().int(),
  name: z.string().nullable(),
  cyclomediaUsername: z.string().nullable(),
  cyclomediaPassword: z.string().nullable(),
  cyclomediaApiKey: z.string().nullable(),
  vheUrlToRentalApplication: z.string().nullable(),
  vgeUrlToRentalApplication: z.string().nullable(),
  isLight: z.boolean(),
  heeftZakelijkeVerhuur: z.boolean(),
  isEmpireKlant: z.boolean(),
  omgeving: z.string().nullable(),
})
const TenantInspectietaakListDto = z.object({
  tenantInspectietaakId: z.number().int(),
  inspectietaakId: z.number().int(),
  taak: z.string().nullable(),
  bestekcode: z.string().nullable(),
  actief: z.boolean(),
  verkoopprijs: z.number(),
  inkoopprijs: z.number(),
  onderhoudsspecialisme: z.string().nullable(),
  onderhoudstaakErp: z.string().nullable(),
  bewerkingstijdMin: z.number().int(),
  kostensoort: z.string().nullable(),
})
const TenantInspectietaakListDtoPaginatedList = z.object({
  items: z.array(TenantInspectietaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const TenantInspectietaakAddOrUpdateDto = z.object({
  tenantInspectietaakId: z.number().int().optional(),
  inspectietaakId: z.number().int(),
  actief: z.boolean().optional(),
  verkoopprijs: z.number().optional(),
  inkoopprijs: z.number().optional(),
  onderhoudsspecialismeId: z.number().int(),
  onderhoudstaakErpId: z.number().int().nullish(),
  bewerkingstijdMin: z.number().int(),
  opmerkingHuurder: z.string().max(70).nullish(),
  kostensoortId: z.number().int(),
})
const TenantInspectietaakDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const TenantKostensoortBetalerDto = z.object({ betalerOptie: z.number().int(), betalerNaam: z.string().nullable(), kostensoortId: z.number().int() })
const CreateTenantKostensoortBetalerCommand = z.object({ betalerOptie: z.number().int(), betalerNaam: z.string().nullable(), kostensoortId: z.number().int() })
const UpdateTenantKostensoortBetalerCommand = z.object({ betalerOptie: z.number().int(), betalerNaam: z.string().nullable(), kostensoortId: z.number().int() })
const TenantRuimteSoortListDto = z.object({
  tenantRuimteSoortId: z.number().int(),
  ruimtesoortErpId: z.number().int(),
  defectLocatieId: z.number().int(),
  ruimtesoortErpOmschrijving: z.string().nullable(),
  defectLocatieNaam: z.string().nullable(),
})
const TenantRuimteSoortListDtoPaginatedList = z.object({
  items: z.array(TenantRuimteSoortListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const TenantRuimteSoortAddOrUpdateDto = z.object({ ruimtesoortErpId: z.number().int(), defectLocatieId: z.number().int().nullish() })
const TenantTaakListDto = z.object({
  tenantTaakId: z.number().int(),
  taakId: z.number().int(),
  naam: z.string().nullable(),
  actief: z.boolean().nullish(),
  onderhoudslocatieOptieNaam: z.string().nullish(),
  tonenAanKlant: z.boolean().nullish(),
  afspraak: z.boolean().nullish(),
  inspectieVereist: z.boolean().nullish(),
  aantalOnderhoudscontract: z.number().int().optional(),
  zelfOplossen: z.boolean().nullish(),
  betalerNaam: z.string().nullable(),
  onderhoudstaakErp: z.string().nullish(),
})
const TenantTaakListDtoPaginatedList = z.object({
  items: z.array(TenantTaakListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateTenantTaakCommand = z.object({
  tenantTaakId: z.number().int().nullable(),
  taakId: z.number().int(),
  taakNaam: z.string().nullable(),
  isGecombineerd: z.boolean(),
  dienstencomponentIds: z.array(z.number().int()).nullable(),
  meetkundigeEenheidId: z.number().int().nullable(),
  onderhoudstaakErpId: z.number().int().nullable(),
  onderhoudsspecialismeId: z.number().int().nullable(),
  bewerkingstijdMin: z.number().int().nullable(),
  urgentieDgn: z.number().int().nullable(),
  afspraak: z.boolean().nullable(),
  actief: z.boolean().nullable(),
  tonenAanKlant: z.boolean().nullable(),
  verkoopprijs: z.number().nullable(),
  kostprijs: z.number().nullable(),
  betalerOptieId: z.number().int().nullable(),
  inspectieVereist: z.boolean().nullable(),
  teBeoordelen: z.boolean().nullable(),
  taakTypeOptieId: z.number().int(),
  portalToelichtingBijOntbrekendContract: z.string().nullable(),
  portalToelichtingZelfOplossen: z.string().nullable(),
  isSpoed: z.boolean().nullable(),
  spoedToelichting: z.string().nullable(),
  dienstencomponentIdsBuiten: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdBuiten: z.number().int().nullable(),
  onderhoudstaakErpIdBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdBuiten: z.number().int().nullable(),
  bewerkingstijdMinBuiten: z.number().int().nullable(),
  urgentieDgnBuiten: z.number().int().nullable(),
  afspraakBuiten: z.boolean().nullable(),
  tonenAanKlantBuiten: z.boolean().nullable(),
  verkoopprijsBuiten: z.number().nullable(),
  kostprijsBuiten: z.number().nullable(),
  betalerOptieIdBuiten: z.number().int().nullable(),
  inspectieVereistBuiten: z.boolean().nullable(),
  teBeoordelenBuiten: z.boolean().nullable(),
  taakTypeOptieIdBuiten: z.number().int(),
  portalToelichtingBijOntbrekendContractBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenBuiten: z.string().nullable(),
  isSpoedBuiten: z.boolean().nullable(),
  spoedToelichtingBuiten: z.string().nullable(),
  actiefZakelijk: z.boolean().nullable(),
  isGecombineerdZakelijk: z.boolean().nullable(),
  dienstencomponentIdsZakelijk: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdZakelijk: z.number().int().nullable(),
  onderhoudstaakErpIdZakelijk: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijk: z.number().int().nullable(),
  bewerkingstijdMinZakelijk: z.number().int().nullable(),
  urgentieDgnZakelijk: z.number().int().nullable(),
  afspraakZakelijk: z.boolean().nullable(),
  tonenAanKlantZakelijk: z.boolean().nullable(),
  verkoopprijsZakelijk: z.number().nullable(),
  kostprijsZakelijk: z.number().nullable(),
  betalerOptieIdZakelijk: z.number().int().nullable(),
  inspectieVereistZakelijk: z.boolean().nullable(),
  teBeoordelenZakelijk: z.boolean().nullable(),
  taakTypeOptieIdZakelijk: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijk: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijk: z.string().nullable(),
  isSpoedZakelijk: z.boolean().nullable(),
  spoedToelichtingZakelijk: z.string().nullable(),
  meetkundigeEenheidIdZakelijkBuiten: z.number().int().nullable(),
  dienstencomponentIdsZakelijkBuiten: z.array(z.number().int()).nullable(),
  onderhoudstaakErpIdZakelijkBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijkBuiten: z.number().int().nullable(),
  bewerkingstijdMinZakelijkBuiten: z.number().int().nullable(),
  urgentieDgnZakelijkBuiten: z.number().int().nullable(),
  afspraakZakelijkBuiten: z.boolean().nullable(),
  tonenAanKlantZakelijkBuiten: z.boolean().nullable(),
  verkoopprijsZakelijkBuiten: z.number().nullable(),
  kostprijsZakelijkBuiten: z.number().nullable(),
  betalerOptieIdZakelijkBuiten: z.number().int().nullable(),
  inspectieVereistZakelijkBuiten: z.boolean().nullable(),
  teBeoordelenZakelijkBuiten: z.boolean().nullable(),
  taakTypeOptieIdZakelijkBuiten: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijkBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijkBuiten: z.string().nullable(),
  isSpoedZakelijkBuiten: z.boolean().nullable(),
  spoedToelichtingZakelijkBuiten: z.string().nullable(),
})
const TenantTaakAddOrUpdateDto = z.object({
  tenantTaakId: z.number().int().nullable(),
  taakId: z.number().int(),
  taakNaam: z.string().nullable(),
  isGecombineerd: z.boolean(),
  dienstencomponentIds: z.array(z.number().int()).nullable(),
  meetkundigeEenheidId: z.number().int().nullable(),
  onderhoudstaakErpId: z.number().int().nullable(),
  onderhoudsspecialismeId: z.number().int().nullable(),
  bewerkingstijdMin: z.number().int().nullable(),
  urgentieDgn: z.number().int().nullable(),
  afspraak: z.boolean().nullable(),
  actief: z.boolean().nullable(),
  tonenAanKlant: z.boolean().nullable(),
  verkoopprijs: z.number().nullable(),
  kostprijs: z.number().nullable(),
  betalerOptieId: z.number().int().nullable(),
  inspectieVereist: z.boolean().nullable(),
  teBeoordelen: z.boolean().nullable(),
  taakTypeOptieId: z.number().int(),
  portalToelichtingBijOntbrekendContract: z.string().nullable(),
  portalToelichtingZelfOplossen: z.string().nullable(),
  isSpoed: z.boolean().nullable(),
  spoedToelichting: z.string().nullable(),
  dienstencomponentIdsBuiten: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdBuiten: z.number().int().nullable(),
  onderhoudstaakErpIdBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdBuiten: z.number().int().nullable(),
  bewerkingstijdMinBuiten: z.number().int().nullable(),
  urgentieDgnBuiten: z.number().int().nullable(),
  afspraakBuiten: z.boolean().nullable(),
  tonenAanKlantBuiten: z.boolean().nullable(),
  verkoopprijsBuiten: z.number().nullable(),
  kostprijsBuiten: z.number().nullable(),
  betalerOptieIdBuiten: z.number().int().nullable(),
  inspectieVereistBuiten: z.boolean().nullable(),
  teBeoordelenBuiten: z.boolean().nullable(),
  taakTypeOptieIdBuiten: z.number().int(),
  portalToelichtingBijOntbrekendContractBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenBuiten: z.string().nullable(),
  isSpoedBuiten: z.boolean().nullable(),
  spoedToelichtingBuiten: z.string().nullable(),
  actiefZakelijk: z.boolean().nullable(),
  isGecombineerdZakelijk: z.boolean().nullable(),
  dienstencomponentIdsZakelijk: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdZakelijk: z.number().int().nullable(),
  onderhoudstaakErpIdZakelijk: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijk: z.number().int().nullable(),
  bewerkingstijdMinZakelijk: z.number().int().nullable(),
  urgentieDgnZakelijk: z.number().int().nullable(),
  afspraakZakelijk: z.boolean().nullable(),
  tonenAanKlantZakelijk: z.boolean().nullable(),
  verkoopprijsZakelijk: z.number().nullable(),
  kostprijsZakelijk: z.number().nullable(),
  betalerOptieIdZakelijk: z.number().int().nullable(),
  inspectieVereistZakelijk: z.boolean().nullable(),
  teBeoordelenZakelijk: z.boolean().nullable(),
  taakTypeOptieIdZakelijk: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijk: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijk: z.string().nullable(),
  isSpoedZakelijk: z.boolean().nullable(),
  spoedToelichtingZakelijk: z.string().nullable(),
  meetkundigeEenheidIdZakelijkBuiten: z.number().int().nullable(),
  dienstencomponentIdsZakelijkBuiten: z.array(z.number().int()).nullable(),
  onderhoudstaakErpIdZakelijkBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijkBuiten: z.number().int().nullable(),
  bewerkingstijdMinZakelijkBuiten: z.number().int().nullable(),
  urgentieDgnZakelijkBuiten: z.number().int().nullable(),
  afspraakZakelijkBuiten: z.boolean().nullable(),
  tonenAanKlantZakelijkBuiten: z.boolean().nullable(),
  verkoopprijsZakelijkBuiten: z.number().nullable(),
  kostprijsZakelijkBuiten: z.number().nullable(),
  betalerOptieIdZakelijkBuiten: z.number().int().nullable(),
  inspectieVereistZakelijkBuiten: z.boolean().nullable(),
  teBeoordelenZakelijkBuiten: z.boolean().nullable(),
  taakTypeOptieIdZakelijkBuiten: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijkBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijkBuiten: z.string().nullable(),
  isSpoedZakelijkBuiten: z.boolean().nullable(),
  spoedToelichtingZakelijkBuiten: z.string().nullable(),
})
const UpdateTenantTaakCommand = z.object({
  tenantTaakId: z.number().int().nullable(),
  taakId: z.number().int(),
  taakNaam: z.string().nullable(),
  isGecombineerd: z.boolean(),
  dienstencomponentIds: z.array(z.number().int()).nullable(),
  meetkundigeEenheidId: z.number().int().nullable(),
  onderhoudstaakErpId: z.number().int().nullable(),
  onderhoudsspecialismeId: z.number().int().nullable(),
  bewerkingstijdMin: z.number().int().nullable(),
  urgentieDgn: z.number().int().nullable(),
  afspraak: z.boolean().nullable(),
  actief: z.boolean().nullable(),
  tonenAanKlant: z.boolean().nullable(),
  verkoopprijs: z.number().nullable(),
  kostprijs: z.number().nullable(),
  betalerOptieId: z.number().int().nullable(),
  inspectieVereist: z.boolean().nullable(),
  teBeoordelen: z.boolean().nullable(),
  taakTypeOptieId: z.number().int(),
  portalToelichtingBijOntbrekendContract: z.string().nullable(),
  portalToelichtingZelfOplossen: z.string().nullable(),
  isSpoed: z.boolean().nullable(),
  spoedToelichting: z.string().nullable(),
  dienstencomponentIdsBuiten: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdBuiten: z.number().int().nullable(),
  onderhoudstaakErpIdBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdBuiten: z.number().int().nullable(),
  bewerkingstijdMinBuiten: z.number().int().nullable(),
  urgentieDgnBuiten: z.number().int().nullable(),
  afspraakBuiten: z.boolean().nullable(),
  tonenAanKlantBuiten: z.boolean().nullable(),
  verkoopprijsBuiten: z.number().nullable(),
  kostprijsBuiten: z.number().nullable(),
  betalerOptieIdBuiten: z.number().int().nullable(),
  inspectieVereistBuiten: z.boolean().nullable(),
  teBeoordelenBuiten: z.boolean().nullable(),
  taakTypeOptieIdBuiten: z.number().int(),
  portalToelichtingBijOntbrekendContractBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenBuiten: z.string().nullable(),
  isSpoedBuiten: z.boolean().nullable(),
  spoedToelichtingBuiten: z.string().nullable(),
  actiefZakelijk: z.boolean().nullable(),
  isGecombineerdZakelijk: z.boolean().nullable(),
  dienstencomponentIdsZakelijk: z.array(z.number().int()).nullable(),
  meetkundigeEenheidIdZakelijk: z.number().int().nullable(),
  onderhoudstaakErpIdZakelijk: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijk: z.number().int().nullable(),
  bewerkingstijdMinZakelijk: z.number().int().nullable(),
  urgentieDgnZakelijk: z.number().int().nullable(),
  afspraakZakelijk: z.boolean().nullable(),
  tonenAanKlantZakelijk: z.boolean().nullable(),
  verkoopprijsZakelijk: z.number().nullable(),
  kostprijsZakelijk: z.number().nullable(),
  betalerOptieIdZakelijk: z.number().int().nullable(),
  inspectieVereistZakelijk: z.boolean().nullable(),
  teBeoordelenZakelijk: z.boolean().nullable(),
  taakTypeOptieIdZakelijk: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijk: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijk: z.string().nullable(),
  isSpoedZakelijk: z.boolean().nullable(),
  spoedToelichtingZakelijk: z.string().nullable(),
  meetkundigeEenheidIdZakelijkBuiten: z.number().int().nullable(),
  dienstencomponentIdsZakelijkBuiten: z.array(z.number().int()).nullable(),
  onderhoudstaakErpIdZakelijkBuiten: z.number().int().nullable(),
  onderhoudsspecialismeIdZakelijkBuiten: z.number().int().nullable(),
  bewerkingstijdMinZakelijkBuiten: z.number().int().nullable(),
  urgentieDgnZakelijkBuiten: z.number().int().nullable(),
  afspraakZakelijkBuiten: z.boolean().nullable(),
  tonenAanKlantZakelijkBuiten: z.boolean().nullable(),
  verkoopprijsZakelijkBuiten: z.number().nullable(),
  kostprijsZakelijkBuiten: z.number().nullable(),
  betalerOptieIdZakelijkBuiten: z.number().int().nullable(),
  inspectieVereistZakelijkBuiten: z.boolean().nullable(),
  teBeoordelenZakelijkBuiten: z.boolean().nullable(),
  taakTypeOptieIdZakelijkBuiten: z.number().int().nullable(),
  portalToelichtingBijOntbrekendContractZakelijkBuiten: z.string().nullable(),
  portalToelichtingZelfOplossenZakelijkBuiten: z.string().nullable(),
  isSpoedZakelijkBuiten: z.boolean().nullable(),
  spoedToelichtingZakelijkBuiten: z.string().nullable(),
})
const TenantTaakOnderhoudscontractenListDto = z.object({
  onderhoudscontractId: z.number().int(),
  contractNummer: z.string().nullable(),
  contractOmschrijving: z.string().nullable(),
  contractBeheerder: z.string().nullable(),
  contractPartij: z.string().nullable(),
})
const TenantWoningverbeteringDto = z.object({
  woningverbeteringId: z.number().int(),
  voorziening: z.string().nullable(),
  tenantWoningverbeteringId: z.number().int().nullable(),
  woningverbeteringOmschrijving: z.string().nullable(),
  woningverbeteringToestemmingId: z.number().int().nullable(),
  woningverbeteringToestemmingCode: z.string().nullable(),
  bedragHuurprijsaanpassing: z.number().nullable(),
  toelichting: z.string().nullable(),
  toelichtingIntern: z.string().nullable(),
  actiefVoorKlantdialoog: z.boolean().nullable(),
  actiefVoorOvername: z.boolean().nullable(),
  woningverbeteringType: z.number().int().nullable(),
  geriefsverbeteringMogelijk: z.boolean().nullable(),
  vergoedingMogelijk: z.boolean().nullable(),
})
const TenantWoningverbeteringDtoPaginatedList = z.object({
  items: z.array(TenantWoningverbeteringDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateTenantWoningverbeteringCommand = z.object({
  woningverbeteringId: z.number().int(),
  voorziening: z.string().nullable(),
  tenantWoningverbeteringId: z.number().int().nullable(),
  woningverbeteringOmschrijving: z.string().nullable(),
  woningverbeteringToestemmingId: z.number().int().nullable(),
  woningverbeteringToestemmingCode: z.string().nullable(),
  bedragHuurprijsaanpassing: z.number().nullable(),
  toelichting: z.string().nullable(),
  toelichtingIntern: z.string().nullable(),
  actiefVoorKlantdialoog: z.boolean().nullable(),
  actiefVoorOvername: z.boolean().nullable(),
  woningverbeteringType: z.number().int().nullable(),
  geriefsverbeteringMogelijk: z.boolean().nullable(),
  vergoedingMogelijk: z.boolean().nullable(),
})
const UpdateTenantWoningverbeteringCommand = z.object({
  woningverbeteringId: z.number().int(),
  voorziening: z.string().nullable(),
  tenantWoningverbeteringId: z.number().int().nullable(),
  woningverbeteringOmschrijving: z.string().nullable(),
  woningverbeteringToestemmingId: z.number().int().nullable(),
  woningverbeteringToestemmingCode: z.string().nullable(),
  bedragHuurprijsaanpassing: z.number().nullable(),
  toelichting: z.string().nullable(),
  toelichtingIntern: z.string().nullable(),
  actiefVoorKlantdialoog: z.boolean().nullable(),
  actiefVoorOvername: z.boolean().nullable(),
  woningverbeteringType: z.number().int().nullable(),
  geriefsverbeteringMogelijk: z.boolean().nullable(),
  vergoedingMogelijk: z.boolean().nullable(),
})
const TerreinClassificatieDto = z.object({
  id: z.number().int(),
  terrein: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const TerreinClassificatieDtoPaginatedList = z.object({
  items: z.array(TerreinClassificatieDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateTerreinClassificatieCommand = z.object({
  id: z.number().int(),
  terrein: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const UpdateTerreinClassificatieCommand = z.object({
  id: z.number().int(),
  terrein: z.string().nullable(),
  ingangsDatum: z.string().datetime({ offset: true }),
  eindDatum: z.string().datetime({ offset: true }).nullable(),
})
const VastgoeddeelportefeuilleListDto = z.object({
  vastgoeddeelportefeuilleId: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  vastgoedportefeuilleId: z.number().int(),
  vastgoedportefeuilleNaam: z.string().nullable(),
})
const VastgoeddeelportefeuilleListDtoPaginatedList = z.object({
  items: z.array(VastgoeddeelportefeuilleListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VastgoeddeelportefeuilleAddOrUpdateDto = z.object({
  code: z.string().max(50).nullish(),
  naam: z.string().min(3),
  vastgoedportefeuilleId: z.number().int(),
  vastgoedportefeuilleNaam: z.string().nullish(),
})
const VastgoeddeelportefeuilleDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VastgoedportefeuilleListDto = z.object({ vastgoedportefeuilleId: z.number().int(), code: z.string().nullable(), naam: z.string().nullable() })
const VastgoedportefeuilleListDtoPaginatedList = z.object({
  items: z.array(VastgoedportefeuilleListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VastgoedportefeuilleAddOrUpdateDto = z.object({ code: z.string().max(50).nullish(), naam: z.string().min(3) })
const VastgoedportefeuilleDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VerwarmingListDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  leveringVanWarmte: z.string().nullable(),
  wijzeVanVerwarming: z.string().nullable(),
})
const VerwarmingListDtoPaginatedList = z.object({
  items: z.array(VerwarmingListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VerwarmingAddOrUpdateDto = z.object({ code: z.string().nullable(), leveringVanWarmteId: z.number().int(), wijzeVanVerwarmingId: z.number().int() })
const VerwarmingDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VerwerkingDto = z.object({
  id: z.number().int(),
  bestandsnaam: z.string().nullable(),
  beschrijving: z.string().nullable(),
  gestartDoor: z.string().nullable(),
  gestartOp: z.string().nullable(),
  gereedOp: z.string().nullable(),
  type: z.string().nullable(),
  status: z.string().nullable(),
  foutmelding: z.string().nullable(),
})
const VerwerkingDtoPaginatedList = z.object({
  items: z.array(VerwerkingDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VerwerkingPostCommand = z.object({ verwerkingstypeId: z.number().int() })
const VerwerkingstypeDto = z.object({
  id: z.number().int(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  bestandNoodzakelijk: z.boolean(),
  alleTenants: z.boolean(),
})
const VerwerkingRegelDto = z.object({
  id: z.number().int(),
  details: z.string().nullable(),
  bestandUrl: z.string().nullable(),
  kadastraalobjectId: z.string().nullable(),
  vastgoedeenheidId: z.number().int().nullable(),
  verblijfsobjectId: z.string().nullable(),
  logTypeId: z.number().int(),
  actieId: z.number().int().nullable(),
  objectType: z.string().nullable(),
  verwerkingId: z.number().int(),
  verwerkingNaam: z.string().nullable(),
})
const VerwerkingRegelDtoPaginatedList = z.object({
  items: z.array(VerwerkingRegelDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const HistorischReden = z.object({ id: z.number().int(), naam: z.string().nullable() })
const SearchVgesForLinkageListDto = z.object({
  vgeId: z.number().int(),
  srt: z.string().nullable(),
  str: z.string().nullable(),
  nr: z.number().int().nullable(),
  ltr: z.string().nullable(),
  nrT: z.string().nullable(),
  pc: z.string().nullable(),
  wpl: z.string().nullable(),
  trnNm: z.string().nullable(),
  aa: z.string().nullable(),
})
const SearchVgesForLinkageListDtoPaginatedList = z.object({
  items: z.array(SearchVgesForLinkageListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const UpdateVgeCommand = z.object({
  id: z.number().int(),
  srt: z.string().nullable(),
  str: z.string().nullable(),
  wpl: z.string().nullable(),
  pc: z.string().nullable(),
  nr: z.number().int().nullable(),
  ltr: z.string().nullable(),
  nrT: z.string().nullable(),
  aa: z.string().nullable(),
  brt: z.string().nullable(),
  brtC: z.string().nullable(),
  wk: z.string().nullable(),
  gemeente: z.string().nullable(),
  baGid: z.string().nullable(),
  lat: z.number().nullable(),
  long: z.number().nullable(),
  kad: z.string().nullable(),
  kadV: z.string().nullable(),
  grt: z.number().int().nullable(),
  etg: z.number().int().nullable(),
  vasAnr: z.string().nullable(),
  geb: z.number().int().nullable(),
  gebN: z.string().nullable(),
  gebCN: z.string().nullable(),
  gdl: z.number().int().nullable(),
  gdlCd: z.string().nullable(),
  gdlN: z.string().nullable(),
  eId: z.number().int().nullable(),
  en: z.string().nullable(),
  vhes: z.array(z.string()).nullable(),
  vhb: z.string().nullable(),
  vhbId: z.number().int().nullable(),
  bst: z.string().nullable(),
  bstId: z.number().int().nullable(),
  kmp: z.string().nullable(),
  kmpId: z.number().int().nullable(),
  kpgrnt: z.boolean().nullable(),
  rel: z.string().nullable(),
  bId: z.number().int().nullable(),
  bn: z.string().nullable(),
  opm: z.string().nullable(),
  advt: z.string().nullable(),
  trnClId: z.number().int().nullable(),
  trnNm: z.string().nullable(),
  rijksMnmnt: z.boolean(),
  oprId: z.string().nullable(),
  pId: z.string().nullable(),
  gmntljkMnmnt: z.boolean(),
  bschrmdStdsOfdrpgzcht: z.boolean(),
  prvnclMnmnt: z.boolean(),
  gebouwId: z.number().int().nullable(),
  vveId: z.number().int().nullable(),
  vveNaam: z.string().nullable(),
  bouwnummer: z.string().nullable(),
  bouwjaar: z.number().int().nullable(),
  renovatiejaar: z.number().int().nullable(),
  renovatieInvestering: z.number().nullable(),
  woonvoorzGehandicapten: z.number().nullable(),
  gelegenBinnenVgeId: z.number().int().nullable(),
  aantalNaamplaatjes: z.number().int().nullable(),
  loc: z.string().nullable(),
  locatieaanduiding: z.string().nullable(),
  isCol: z.boolean(),
  ref: z.string().nullable(),
  zelfstandig: z.string().nullable(),
  toegankelijkheid: z.number().int(),
  toegankelijkheidNaam: z.string().nullable(),
  gebouwenClusterId: z.number().int().nullable(),
  gebouwenClustersCode: z.string().nullable(),
  gebouwenClustersName: z.string().nullable(),
  repVerz: z.boolean(),
  repVerzBeoordelen: z.boolean(),
  hist: z.boolean(),
  histDt: z.string().datetime({ offset: true }).nullable(),
  dtbgstrt: z.string().datetime({ offset: true }).nullable(),
  dtingbrkn: z.string().datetime({ offset: true }).nullable(),
  histReden: z.string().nullable(),
  verkpAan: z.string().nullable(),
  histRedenId: z.number().int().nullable(),
  verkpAanId: z.number().int().nullable(),
  taxWrde: z.number().int().nullable(),
  type: z.string().nullable(),
  srtNm: z.string().nullable(),
  referentieExtern: z.string().nullable(),
  warmteLeveringId: z.number().int(),
  warmteLeveringNaam: z.string().nullable(),
  zolderZonderVasteTrapId: z.number().int(),
  zolderZonderVasteTrapNaam: z.string().nullable(),
  redennvId: z.number().int().nullable(),
  redennv: z.string().nullable(),
  labelletter: z.string().nullable(),
  taakTypeOptieId: z.number().int(),
  taakTypeOptieNaam: z.string().nullable(),
  isBadEnAparteDouche: z.boolean(),
  huurcontractbepalingen: z.array(GerelateerdHuurcontractbepaling).nullable(),
})
const VheListDto = z.object({
  id: z.number().int(),
  code: z.string().min(1),
  detailsoort: z.string().nullish(),
  adres: z.string().nullish(),
  adresaanduiding: z.string().nullish(),
  postcode: z.string().nullish(),
  woonplaats: z.string().nullish(),
  activatieDatum: z.string().nullish(),
  inExploitatieDatum: z.string().nullish(),
  uitExploitatieDatum: z.string().nullish(),
})
const VgeSelectionListDto = z.object({
  id: z.number().int(),
  adres: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  adresaanduiding: z.string().nullable(),
  eenheiddetailsoortId: z.number().int(),
  eenheiddetailsoortNaam: z.string().nullable(),
})
const Vges = z.array(VgeSelectionListDto).optional()
const SuggestedAddressDto = z.object({
  adres: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  adresaanduiding: z.string().nullable(),
})
const VgeVoorzieningenListDto = z.object({
  voorzieningId: z.number().int(),
  type: z.string().nullable(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
})
const VgeRuimteListDto = z.object({
  id: z.number().int(),
  categorie: z.string().nullable(),
  soort: z.string().nullable(),
  nummer: z.string().nullable(),
  bouwlaag: z.string().nullable(),
  oppervlakte: z.number(),
  verwarmd: z.string().nullable(),
  gekoeld: z.string().nullable(),
  vheCount: z.number().int(),
  documentCount: z.number().int(),
})
const VgeRuimteAddOrUpdateDto = z.object({
  soortId: z.number().int(),
  nummer: z.string().max(255).nullish(),
  bouwlaagId: z.number().int().nullish(),
  oppervlakte: z.number().nullish(),
  verwarmingOptieId: z.number().int().nullish(),
  koelingOptieId: z.number().int().nullish(),
  vheIds: z.array(z.number().int()).nullish(),
})
const WoningwaarderingDto = z.object({
  warmteLevering: z.string().nullable(),
  aantalVerwarmdeVertrekkenIndividueel: z.number().int().nullable(),
  aantalVerwarmdeVertrekkenCollectief: z.number().int().nullable(),
  aantalVerwarmdeOverigeRuimtenIndividueel: z.number().int().nullable(),
  aantalVerwarmdeOverigeRuimtenCollectief: z.number().int().nullable(),
  epBerekeningswijze: z.string().nullable(),
  epEnergieLabel: z.string().nullable(),
  epEnergieprestatie: z.string().nullable(),
  epOpnameDatum: z.string().nullable(),
  epGeldigTot: z.string().nullable(),
  eensgezinsMeergezins: z.string().nullable(),
  bouwjaar: z.string().nullable(),
  woonvoorzieningGehandicapten: z.string().nullable(),
  extraPuntenGemeenschappelijkeBuitenruimte: z.number().nullable(),
  priveBuitenruimte: z.number().int().nullable(),
  carport: z.string().nullable(),
  garage: z.string().nullable(),
  taxatiewaarde: z.number().int().nullable(),
  wozPeildatum: z.string().nullable(),
  wozWaarde: z.string().nullable(),
  datumBouwGestart: z.string().nullable(),
  datumIngebruikname: z.string().nullable(),
  renovatieJaar: z.string().nullable(),
  renovatieInvesteringsbedrag: z.string().nullable(),
  zorgwoning: z.string().nullable(),
  rijksmonument: z.string().nullable(),
  beschermdeStadsgezicht: z.string().nullable(),
  aantalVVI: z.number().int().nullable(),
  aantalVVC: z.number().int().nullable(),
  aantalVOI: z.number().int().nullable(),
  aantalVOC: z.number().int().nullable(),
  videofoon: z.string().nullable(),
  provinciaal: z.string().nullable(),
  gemeentelijk: z.string().nullable(),
  zolderZonderVasteTrapNaam: z.string().nullable(),
})
const VgeWoningwaarderingUpdateDto = z.object({
  priveBuitenruimte: z.number().int(),
  aantalVvi: z.number().int().nullable(),
  aantalVvc: z.number().int().nullable(),
  aantalVoi: z.number().int().nullable(),
  aantalVoc: z.number().int().nullable(),
  extraPuntenGemeenschappelijkeBuitenruimte: z.number().nullable(),
})
const PandCertificaatDto = z.object({
  adresseerbaarobjectId: z.string().nullable(),
  mutatiedatum: z.string().datetime({ offset: true }).nullable(),
  registratiedatum: z.string().datetime({ offset: true }).nullable(),
  opnamedatum: z.string().datetime({ offset: true }).nullable(),
  metingGeldigTot: z.string().datetime({ offset: true }).nullable(),
  stuurcode: z.string().nullable(),
  certificaathouder: z.string().nullable(),
  mutatievolgnummer: z.string().nullable(),
  gebouwklasseNaam: z.string().nullable(),
  isOpBasisVanReferentieGebouw: z.boolean().nullable(),
  status: z.string().nullable(),
  opnametype: z.string().nullable(),
  berekeningstype: z.string().nullable(),
  energieprestatieindex: z.number().nullable(),
  labelletter: z.string().nullable(),
  energielabelIsPrive: z.string().nullable(),
  energieprestatieForfaitair: z.number().nullable(),
  postcode: z.string().nullable(),
  huisnummer: z.string().nullable(),
  huisletter: z.string().nullable(),
  huisnummertoevoeging: z.string().nullable(),
  detailaanduiding: z.string().nullable(),
  bagverblijfsobjectid: z.string().nullable(),
  bagligplaatsobjectid: z.string().nullable(),
  bagstandplaatsobjectid: z.string().nullable(),
  bagpandid: z.string().nullable(),
  gebouwtype: z.string().nullable(),
  gebouwsubtype: z.string().nullable(),
  sbiCode: z.string().nullable(),
  gebruiksoppervlakte: z.number().nullable(),
  energiebehoefte: z.number().nullable(),
  primaireFossieleEnergie: z.number().nullable(),
  primaireFossieleEnergieEmgForfaitair: z.number().nullable(),
  aandeelHernieuwbareEnergie: z.number().nullable(),
  aandeelHernieuwbareEnergieEmgForfaitair: z.number().nullable(),
  eisEnergiebehoefte: z.number().nullable(),
  eisPrimaireFossieleEnergie: z.number().nullable(),
  eisAandeelHernieuwbareEnergie: z.number().nullable(),
  eisTemperatuuroverschrijding: z.number().nullable(),
  afschrift: z.boolean().nullable(),
  projectnaam: z.string().nullable(),
  projectobject: z.string().nullable(),
  temperatuuroverschrijding: z.number().nullable(),
  warmtebehoefte: z.number().nullable(),
  energieIndexMetEmgForfaitair: z.number().nullable(),
  bouwjaar: z.number().int().nullable(),
  compactheid: z.number().nullable(),
  berekendeCO2Emissie: z.number().nullable(),
  berekendeEnergieverbruik: z.number().nullable(),
})
const HistorischInfoDto = z.object({
  historisch: z.boolean(),
  verhuurtypeOptie: z.string().nullable(),
  reparatieverzoekToegestaan: z.boolean(),
  verhuureenhedenNietUitExploitatie: z.array(VheListDto).nullable(),
})
const VgeSetHistorischDto = z.object({
  vanaf: z.string().datetime({ offset: true }),
  redenId: z.number().int(),
  vgeIds: z.array(z.number().int()),
  verkoopAanId: z.number().int().optional(),
})
const VgeMeterstandDto = z.object({
  vgeMeterstandId: z.number().int(),
  vgeId: z.number().int(),
  metertypeId: z.number().int(),
  metertypeNaam: z.string().nullable(),
  metertypeEenheid: z.string().nullable(),
  metertypeAantalDecimalen: z.number().int(),
  inspectieOpnameType: z.number().int(),
  aantal: z.number(),
  registratieDatum: z.string().datetime({ offset: true }),
  registratieGebruikerNaam: z.string().nullable(),
  registratieGebruikerEmail: z.string().nullable(),
})
const VgeMeterstandDtoPaginatedList = z.object({
  items: z.array(VgeMeterstandDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VgeMeterstandAddOrUpdateDto = z.object({
  vgeId: z.number().int(),
  metertypeId: z.number().int(),
  inspectieOpnameType: z.number().int(),
  aantal: z.number(),
})
const VgeMeterstandHistorieDto = z.object({
  vgeMeterstandId: z.number().int(),
  vgeId: z.number().int(),
  metertypeNaam: z.string().nullable(),
  metertypeEenheid: z.string().nullable(),
  inspectieOpnameTypeNaam: z.string().nullable(),
  aantal: z.number(),
  registratieDatum: z.string().datetime({ offset: true }),
  registratieGebruikerNaam: z.string().nullable(),
  registratieGebruikerEmail: z.string().nullable(),
})
const VgeOppervlakteNen2580ListDto = z.object({ bouwlaagId: z.number().int(), bouwlaagNaam: z.string().nullable() })
const VgeOppervlakteNen2580ListDtoPaginatedList = z.object({
  items: z.array(VgeOppervlakteNen2580ListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VgeOppervlakteNen2580AddOrUpdateDto = z.object({
  vgeId: z.number().int(),
  bouwlaagId: z.number().int(),
  kompasrichtingOptie: z.number().int().nullish(),
  goWoonfunctie: z.number().nullish(),
  goOverigeFunctie: z.number().nullish(),
  goGemeenschappelijkeRuimten: z.number().nullish(),
  goUtiliteitsfuncties: z.number().nullish(),
  goOverigeAangrenzendeRuimteBergingGarage: z.number().nullish(),
  goGetekendVolgensNen: z.boolean().nullish(),
  bvoGebouw: z.number().nullish(),
  bvoOverdekteGebouwgebondenBuitenruimten: z.number().nullish(),
  bvoNietOverdekteGebouwgebondenBuitenruimten: z.number().nullish(),
  bvoGemeenschappelijkeBinnenruimteWoningen: z.number().nullish(),
  bvoGemeenschappelijkeBinnenruimteBouwlaag: z.number().nullish(),
  bvoGemeenschappelijkeBinnenruimteGebouw: z.number().nullish(),
  bvoGemeenschappelijkeNietOverdekteBuitenruimten: z.number().nullish(),
  bvoOverigeRuimten: z.number().nullish(),
  vvoUnit: z.number().nullish(),
  vvoGemeenschappelijkeRuimten: z.number().nullish(),
  vvoOverigeRuimten: z.number().nullish(),
})
const VgeOppervlakteNen2580DeleteCommand = z.object({ vgeId: z.number().int(), bouwlaagIdsToDeletes: z.array(z.number().int()).nullable() })
const VgeRuimteDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VgeSleutelDto = z.object({
  vgeSleutelId: z.number().int(),
  vgeId: z.number().int(),
  sleuteltypeId: z.number().int(),
  sleuteltypeNaam: z.string().nullable(),
  aantal: z.number().int(),
  certificaat: z.string().nullable(),
})
const VgeSleutelDtoPaginatedList = z.object({
  items: z.array(VgeSleutelDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VgeSleutelAddOrUpdateDto = z.object({
  vgeSleutelId: z.number().int(),
  vgeId: z.number().int(),
  sleuteltypeId: z.number().int(),
  aantal: z.number().int(),
  certificaat: z.string().nullable(),
})
const VheListDtoPaginatedList = z.object({
  items: z.array(VheListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VheAddDto = z.object({
  code: z.string().min(1).max(15),
  adres: z.string().max(80).nullish(),
  postcode: z.string().max(6).nullish(),
  woonplaats: z.string().max(80).nullish(),
  adresaanduiding: z.string().max(50).nullish(),
  eenheiddetailsoortId: z.number().int(),
  waardestelselOptieId: z.number().int(),
  cbsBuurtCode: z.string().nullish(),
  zorgwoning: z.boolean().nullish(),
  magAdresWordenGewijzigd: z.boolean().nullish(),
  isAdresOverschreven: z.boolean().nullish(),
  vgeIds: z.array(z.number().int()).nullish(),
})
const VheDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const VheUpdateDto = z.object({
  code: z.string().min(1).max(15),
  adres: z.string().max(80).nullish(),
  postcode: z.string().max(6).nullish(),
  postcodeDisplay: z.string().nullish(),
  woonplaats: z.string().max(80).nullish(),
  adresaanduiding: z.string().max(50).nullish(),
  inexploitatie: z.string().nullish(),
  uitexploitatie: z.string().nullish(),
  activatiedatum: z.string().nullish(),
  eenheiddetailsoortId: z.number().int(),
  waardestelselOptieId: z.number().int(),
  cbsBuurtCode: z.string().nullish(),
  zorgwoning: z.boolean().optional(),
  magAdresWordenGewijzigd: z.boolean().optional(),
  isAdresOverschreven: z.boolean().optional(),
  verhuurderNaam: z.string().nullish(),
})
const VheVgeListDto = z.object({
  id: z.number().int(),
  adres: z.string().nullable(),
  detailsoort: z.string().nullable(),
  adresaanduiding: z.string().nullable(),
  straat: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  isCollectief: z.boolean().nullable(),
})
const VheVgeListDtoPaginatedList = z.object({
  items: z.array(VheVgeListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VoorzieningenListDto = z.object({
  voorzieningId: z.number().int(),
  type: z.string().nullable(),
  code: z.string().nullable(),
  naam: z.string().nullable(),
  omschrijving: z.string().nullable(),
  elementDetail: z.string().nullable(),
  fonticoonNaam: z.string().nullable(),
})
const VoorzieningenListDtoPaginatedList = z.object({
  items: z.array(VoorzieningenListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const VoorzieningenAddOrUpdateDto = z.object({
  typeId: z.number().int(),
  code: z.string().min(3).max(10),
  naam: z.string().min(3).max(100),
  omschrijving: z.string().max(500).nullish(),
  elementElementDetailId: z.number().int().optional(),
  selectedCoenCadCodes: z.array(z.number().int()).nullish(),
  fonticoonId: z.number().int().nullish(),
})
const VoorzieningenDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const CoenCadcoderingListDto = z.object({ id: z.number().int(), isSelected: z.boolean(), code: z.string().nullable(), naam: z.string().nullable() })
const AddVveCommand = z.object({
  id: z.number().int(),
  handelsnaam: z.string().nullable(),
  externeReferentie: z.string().nullable(),
  rolId: z.number().int(),
  rolNaam: z.string().nullable(),
  gln: z.string().nullable(),
  kvkNummer: z.string().nullable(),
  vestigingsnummer: z.string().nullable(),
  rsin: z.string().nullable(),
  straat: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  nummeraanduidingId: z.string().nullable(),
  landCode: z.string().nullable(),
  emailadres: z.string().nullable(),
  website: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  contactpersoon: z.string().nullable(),
  geblokkeerd: z.boolean(),
  naamOpAkte: z.string().nullable(),
  typeSplitsing: z.string().nullable(),
  bovenliggendeVveNaam: z.string().nullable(),
  aantalAppartementsrechten: z.number().int(),
  oprichtingsdatum: z.string().datetime({ offset: true }).nullable(),
  gerechtigdeNummer: z.string().nullable(),
  administratiefBeheerder: z.string().nullable(),
  administratiefBeheerderContactpersoon: z.string().nullable(),
  codeInExterneVveRegistratie: z.string().nullable(),
  technischBeheerder: z.string().nullable(),
  technischBeheerderContactpersoon: z.string().nullable(),
  vertegenwoordigerCorporatie: z.string().nullable(),
  beheerTelefoon: z.string().nullable(),
  beheerEmail: z.string().nullable(),
  intakeDoorCorporatie: z.boolean(),
})
const VveDto = z.object({
  id: z.number().int(),
  handelsnaam: z.string().nullable(),
  externeReferentie: z.string().nullable(),
  rolId: z.number().int(),
  rolNaam: z.string().nullable(),
  gln: z.string().nullable(),
  kvkNummer: z.string().nullable(),
  vestigingsnummer: z.string().nullable(),
  rsin: z.string().nullable(),
  straat: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  nummeraanduidingId: z.string().nullable(),
  landCode: z.string().nullable(),
  emailadres: z.string().nullable(),
  website: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  contactpersoon: z.string().nullable(),
  geblokkeerd: z.boolean(),
  naamOpAkte: z.string().nullable(),
  typeSplitsing: z.string().nullable(),
  bovenliggendeVveNaam: z.string().nullable(),
  aantalAppartementsrechten: z.number().int(),
  oprichtingsdatum: z.string().datetime({ offset: true }).nullable(),
  gerechtigdeNummer: z.string().nullable(),
  administratiefBeheerder: z.string().nullable(),
  administratiefBeheerderContactpersoon: z.string().nullable(),
  codeInExterneVveRegistratie: z.string().nullable(),
  technischBeheerder: z.string().nullable(),
  technischBeheerderContactpersoon: z.string().nullable(),
  vertegenwoordigerCorporatie: z.string().nullable(),
  beheerTelefoon: z.string().nullable(),
  beheerEmail: z.string().nullable(),
  intakeDoorCorporatie: z.boolean(),
})
const VveExpandedDto = z.object({
  id: z.number().int(),
  handelsnaam: z.string().nullable(),
  externeReferentie: z.string().nullable(),
  rolId: z.number().int(),
  rolNaam: z.string().nullable(),
  gln: z.string().nullable(),
  kvkNummer: z.string().nullable(),
  vestigingsnummer: z.string().nullable(),
  rsin: z.string().nullable(),
  straat: z.string().nullable(),
  postcode: z.string().nullable(),
  woonplaats: z.string().nullable(),
  nummeraanduidingId: z.string().nullable(),
  landCode: z.string().nullable(),
  emailadres: z.string().nullable(),
  website: z.string().nullable(),
  telefoonnummer: z.string().nullable(),
  contactpersoon: z.string().nullable(),
  geblokkeerd: z.boolean(),
  naamOpAkte: z.string().nullable(),
  typeSplitsing: z.string().nullable(),
  bovenliggendeVveNaam: z.string().nullable(),
  aantalAppartementsrechten: z.number().int(),
  oprichtingsdatum: z.string().datetime({ offset: true }).nullable(),
  gerechtigdeNummer: z.string().nullable(),
  administratiefBeheerder: z.string().nullable(),
  administratiefBeheerderContactpersoon: z.string().nullable(),
  codeInExterneVveRegistratie: z.string().nullable(),
  technischBeheerder: z.string().nullable(),
  technischBeheerderContactpersoon: z.string().nullable(),
  vertegenwoordigerCorporatie: z.string().nullable(),
  beheerTelefoon: z.string().nullable(),
  beheerEmail: z.string().nullable(),
  intakeDoorCorporatie: z.boolean(),
  bovenliggendeVve: VveDto,
  onderliggendeVves: z.array(VveDto).nullable(),
  appartmentsrechten: z.array(KadastraalObjectDto).nullable(),
  bronnenVoorSplitsing: z.array(KadastraalObjectDto).nullable(),
})
const DeleteVvesCommand = z.object({ vvesToDelete: z.array(z.number().int()).nullable() })
const VveDtoPaginatedList = z.object({
  items: z.array(VveDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const postVveIdappartementsindexen_Body = z.array(z.string())
const postVveIdkadastraalsubject_Body = z.number()
const WerkgebiedListDto = z.object({ werkgebiedId: z.number().int(), naam: z.string().nullable() })
const WerkgebiedListDtoPaginatedList = z.object({
  items: z.array(WerkgebiedListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const WerkgebiedAddOrUpdateDto = z.object({ naam: z.string().min(3) })
const WerkgebiedDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const WerkzaamhedenListDto = z.object({ werkzaamheidId: z.number().int(), omschrijving: z.string().nullable(), fonticoonNaam: z.string().nullable() })
const WerkzaamhedenListDtoPaginatedList = z.object({
  items: z.array(WerkzaamhedenListDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const WerkzaamhedenAddOrUpdateDto = z.object({ omschrijving: z.string().min(3), fonticoonId: z.number().int().nullish() })
const WerkzaamhedenDeleteCommand = z.object({ itemsToDelete: z.array(z.number().int()).nullable() })
const WoningverbeteringDto = z.object({
  woningverbeteringId: z.number().int(),
  omschrijving: z.string().nullable(),
  voorziening: z.string().nullable(),
  elementElementDetailId: z.number().int().nullable(),
  cartotheekItem: z.string().nullable(),
  inspectietaakId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  inspectietaak: z.string().nullable(),
})
const WoningverbeteringDtoPaginatedList = z.object({
  items: z.array(WoningverbeteringDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateWoningverbeteringCommand = z.object({
  woningverbeteringId: z.number().int(),
  omschrijving: z.string().nullable(),
  voorziening: z.string().nullable(),
  elementElementDetailId: z.number().int().nullable(),
  cartotheekItem: z.string().nullable(),
  inspectietaakId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  inspectietaak: z.string().nullable(),
})
const UpdateWoningverbeteringCommand = z.object({
  woningverbeteringId: z.number().int(),
  omschrijving: z.string().nullable(),
  voorziening: z.string().nullable(),
  elementElementDetailId: z.number().int().nullable(),
  cartotheekItem: z.string().nullable(),
  inspectietaakId: z.number().int().nullable(),
  fonticoonId: z.number().int().nullable(),
  fonticoonNaam: z.string().nullable(),
  inspectietaak: z.string().nullable(),
})
const WoningverbeteringListItemDto = z.object({
  woningverbeteringId: z.number().int(),
  omschrijving: z.string().nullable(),
  fonticoonId: z.number().int().nullable(),
})
const WoningVerbeteringType = z.object({ woningVerbeteringTypeId: z.number().int(), naam: z.string().nullable() })
const WoningverbeteringToestemmingDto = z.object({ woningverbeteringToestemmingId: z.number().int(), code: z.string().nullable() })
const WoningverbeteringToestemmingDtoPaginatedList = z.object({
  items: z.array(WoningverbeteringToestemmingDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const CreateWoningverbeteringToestemmingCommand = z.object({ woningverbeteringToestemmingId: z.number().int(), code: z.string().nullable() })
const UpdateWoningverbeteringToestemmingCommand = z.object({ woningverbeteringToestemmingId: z.number().int(), code: z.string().nullable() })
const WozDeelobjectDto = z.object({
  bagPand: z.string().nullable(),
  wozDeelobjectNummer: z.string().nullable(),
  wozObjectNummer: z.string().nullable(),
  wozDeelobjectOnderdeelCode: z.string().nullable(),
  wozDeelobjectOnderdeelOmschrijving: z.string().nullable(),
  wozDeelobjectGebruiksoppervlakte: z.string().nullable(),
})
const WozObjectDto: z.ZodType<WozObjectDto> = z.lazy(() =>
  z.object({
    wozObjectnummer: z.string().min(1),
    peildatum: z.string().datetime({ offset: true }).optional(),
    wozWaarde: z.number().optional(),
    wozOzbAanslag: z.number().optional(),
    wozEigendomGebruik: z.string().nullish(),
    vastgoedobjectsoortID: z.string().nullish(),
    vastgoedobjectsoort: z.string().nullish(),
    locatiebeschrijving: z.string().nullish(),
    verblijfsobject: z.string().nullish(),
    vgeId: z.number().int().nullish(),
    wozDeelobjecten: z.array(WozDeelobjectDto).nullish(),
    wozHistorie: z.array(WozObjectDto).nullish(),
  })
)
const WozObjectDtoPaginatedList = z.object({
  items: z.array(WozObjectDto).nullable(),
  pageIndex: z.number().int(),
  totalPages: z.number().int(),
  totalCount: z.number().int(),
  hasPreviousPage: z.boolean(),
  hasNextPage: z.boolean(),
})
const DefectLocatieElementCategorieExportQuery = z.object({})
const DefectLocatieWoningverbeteringExportQuery = z.object({})
const EenheidDetailSoortRuimteSoortExportQuery = z.object({})
const ElementDefectSoortExportQuery = z.object({})
const ElementDefectSoortTaakExportQuery = z.object({})
const ElementExportQuery = z.object({})
const GetExportQuery = z.object({})
const InspectieOpnameTypeAllListQuery = z.object({})
const InspectieOpnamedetailCartotheekExportQuery = z.object({})
const StatistiekQuery = z.object({})
const TenantKostensoortBetalerQuery = z.object({})

const endpoints = makeApi([
  {
    method: 'get',
    path: '/:vgeId/pandcertificaat',
    alias: 'getVgeIdpandcertificaat',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: PandCertificaatDto,
  },
  {
    method: 'get',
    path: '/Adressen',
    alias: 'getAdressen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Postcode',
        type: 'Query',
        schema: Postcode,
      },
    ],
    response: z.array(AdresListDto),
  },
  {
    method: 'get',
    path: '/Adressen/:id',
    alias: 'getAdressenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: AdresDetailsDto,
  },
  {
    method: 'get',
    path: '/api/Bouwlagen',
    alias: 'getApiBouwlagen',
    requestFormat: 'json',
    response: BouwlaagDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Bouwlagen',
    alias: 'postApiBouwlagen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BouwlaagDto,
      },
    ],
    response: BouwlaagDto,
  },
  {
    method: 'put',
    path: '/api/Bouwlagen',
    alias: 'putApiBouwlagen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BouwlaagDto,
      },
    ],
    response: BouwlaagDto,
  },
  {
    method: 'delete',
    path: '/api/Bouwlagen',
    alias: 'deleteApiBouwlagen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/Bouwlagen/opties',
    alias: 'getApiBouwlagenopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'post',
    path: '/api/Bouwlagen/reorder',
    alias: 'postApiBouwlagenreorder',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ChangeBouwlaagOrderCommand,
      },
    ],
    response: BouwlaagDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/CoenCad',
    alias: 'getApiCoenCad',
    requestFormat: 'json',
    response: CoenCadCodeDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/CoenCad',
    alias: 'postApiCoenCad',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CoenCadCodeDto,
      },
    ],
    response: CoenCadCodeDto,
  },
  {
    method: 'put',
    path: '/api/CoenCad',
    alias: 'putApiCoenCad',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CoenCadCodeDto,
      },
    ],
    response: CoenCadCodeDto,
  },
  {
    method: 'delete',
    path: '/api/CoenCad',
    alias: 'deleteApiCoenCad',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/CoencadMeetinstructie/:vgeId',
    alias: 'getApiCoencadMeetinstructieVgeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CoencadMeetinstructieListDto),
  },
  {
    method: 'get',
    path: '/api/CoencadZorggeschiktheid/:vgeId',
    alias: 'getApiCoencadZorggeschiktheidVgeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CoencadZorggeschiktheidListDto),
  },
  {
    method: 'get',
    path: '/api/DefectSoort',
    alias: 'getApiDefectSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DefectSoortListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/DefectSoort',
    alias: 'postApiDefectSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DefectSoortAddOrUpdateDto,
      },
    ],
    response: DefectSoortAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/DefectSoort',
    alias: 'deleteApiDefectSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DefectSoortDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'put',
    path: '/api/DefectSoort/:id',
    alias: 'putApiDefectSoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DefectSoortDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: DefectSoortDto,
  },
  {
    method: 'get',
    path: '/api/Documents',
    alias: 'getApiDocuments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'DoelType',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'DoelId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DocumentListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Documents',
    alias: 'postApiDocuments',
    requestFormat: 'form-data',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postApiDocuments_Body,
      },
    ],
    response: DocumentAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Documents',
    alias: 'deleteApiDocuments',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentDeleteRequestDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Documents/:id/download',
    alias: 'getApiDocumentsIddownload',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/Documents/classifications',
    alias: 'getApiDocumentsclassifications',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DocumentClassificationListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Documents/classifications',
    alias: 'postApiDocumentsclassifications',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentClassificationAddOrUpdateDto,
      },
    ],
    response: DocumentClassificationAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Documents/classifications',
    alias: 'deleteApiDocumentsclassifications',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentClassificationDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Documents/classifications/:id',
    alias: 'getApiDocumentsclassificationsId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DocumentClassificationAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Documents/classifications/:id',
    alias: 'putApiDocumentsclassificationsId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentClassificationAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DocumentClassificationAddOrUpdateDto,
  },
  {
    method: 'post',
    path: '/api/Documents/hoofdDocument',
    alias: 'postApiDocumentshoofdDocument',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateHoofdDocumentCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Documents/hoofdDocumentForDoelType/:doelType/doelId/:doelId',
    alias: 'getApiDocumentshoofdDocumentForDoelTypeDoelTypedoelIdDoelId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'doelType',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'doelId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: HoofdDocumentDto,
  },
  {
    method: 'get',
    path: '/api/Documents/kenmerken',
    alias: 'getApiDocumentskenmerken',
    requestFormat: 'json',
    response: DocumentKenmerkListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Documents/soorten',
    alias: 'getApiDocumentssoorten',
    requestFormat: 'json',
    response: DocumentSoortListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Documents/soortKenmerkenPerDoel',
    alias: 'getApiDocumentssoortKenmerkenPerDoel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'DoelType',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DocumentSoortKenmerkDoelListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Element',
    alias: 'postApiElement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateElementCommand,
      },
    ],
    response: ElementDto,
  },
  {
    method: 'delete',
    path: '/api/Element',
    alias: 'deleteApiElement',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/Element/:id',
    alias: 'putApiElementId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateElementCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ElementDto,
  },
  {
    method: 'get',
    path: '/api/Element/exportDefectSoorten',
    alias: 'getApiElementexportDefectSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/Element/exportTaken',
    alias: 'getApiElementexportTaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/Element/importDefectSoorten',
    alias: 'postApiElementimportDefectSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/Element/importTaken',
    alias: 'postApiElementimportTaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/Element/page',
    alias: 'getApiElementpage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Element/pageDefectSoorten',
    alias: 'getApiElementpageDefectSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementDefectSoortDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Element/pageDetails',
    alias: 'getApiElementpageDetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementElementDetailDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Element/pageTaken',
    alias: 'getApiElementpageTaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementDefectSoortTakenRowPaginatedList,
  },
  {
    method: 'post',
    path: '/api/ElementCategorie',
    alias: 'postApiElementCategorie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateElementCategorieCommand,
      },
    ],
    response: ElementCategorieDto,
  },
  {
    method: 'delete',
    path: '/api/ElementCategorie',
    alias: 'deleteApiElementCategorie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/ElementCategorie/:id',
    alias: 'putApiElementCategorieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateElementCategorieCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ElementCategorieDto,
  },
  {
    method: 'get',
    path: '/api/ElementCategorie/opties',
    alias: 'getApiElementCategorieopties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/api/ElementCategorie/page',
    alias: 'getApiElementCategoriepage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementCategorieDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/ElementCategorie/pageElements',
    alias: 'getApiElementCategoriepageElements',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementCategorieElementDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Fonticoon',
    alias: 'getApiFonticoon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: FonticoonListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Fonticoon',
    alias: 'postApiFonticoon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: FonticoonAddOrUpdateDto,
      },
    ],
    response: FonticoonAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Fonticoon',
    alias: 'deleteApiFonticoon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: FonticoonDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Fonticoon/:id',
    alias: 'getApiFonticoonId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: FonticoonAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Fonticoon/:id',
    alias: 'putApiFonticoonId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: FonticoonAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: FonticoonAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/api/Fonticoon/opties',
    alias: 'getApiFonticoonopties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling',
    alias: 'getApiHuurcontractbepaling',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: HuurcontractbepalingListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Huurcontractbepaling',
    alias: 'postApiHuurcontractbepaling',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: HuurcontractbepalingAddOrUpdateDto,
      },
    ],
    response: HuurcontractbepalingAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Huurcontractbepaling',
    alias: 'deleteApiHuurcontractbepaling',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: HuurcontractbepalingDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/:id',
    alias: 'getApiHuurcontractbepalingId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: HuurcontractbepalingAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Huurcontractbepaling/:id',
    alias: 'putApiHuurcontractbepalingId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: HuurcontractbepalingAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: HuurcontractbepalingAddOrUpdateDto,
  },
  {
    method: 'post',
    path: '/api/Huurcontractbepaling/koppelingGebouw',
    alias: 'postApiHuurcontractbepalingkoppelingGebouw',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KoppelingGebouwCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'post',
    path: '/api/Huurcontractbepaling/koppelingGebouwcluster',
    alias: 'postApiHuurcontractbepalingkoppelingGebouwcluster',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KoppelingGebouwclusterCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'post',
    path: '/api/Huurcontractbepaling/koppelingGebouwdelen',
    alias: 'postApiHuurcontractbepalingkoppelingGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KoppelingGebouwdeelCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'post',
    path: '/api/Huurcontractbepaling/koppelingVges',
    alias: 'postApiHuurcontractbepalingkoppelingVges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KoppelingVgeCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/listGebouwclusters',
    alias: 'getApiHuurcontractbepalinglistGebouwclusters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: HuurcontractbepalingGebouwclusterListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/listGebouwdelen',
    alias: 'getApiHuurcontractbepalinglistGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: HuurcontractbepalingGebouwdeelListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/listGebouwen',
    alias: 'getApiHuurcontractbepalinglistGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: HuurcontractbepalingGebouwListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/listVges',
    alias: 'getApiHuurcontractbepalinglistVges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/nietGekoppeldGebouwclusters',
    alias: 'getApiHuurcontractbepalingnietGekoppeldGebouwclusters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: NietGekoppeldGebouwclusterListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/nietGekoppeldGebouwdelen',
    alias: 'getApiHuurcontractbepalingnietGekoppeldGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: NietGekoppeldGebouwdeelListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/nietGekoppeldGebouwen',
    alias: 'getApiHuurcontractbepalingnietGekoppeldGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: NietGekoppeldGebouwListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Huurcontractbepaling/nietGekoppeldVges',
    alias: 'getApiHuurcontractbepalingnietGekoppeldVges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'HuurcontractbepalingId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'delete',
    path: '/api/Huurcontractbepaling/ontkoppelingGebouw',
    alias: 'deleteApiHuurcontractbepalingontkoppelingGebouw',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OntkoppelingGebouwCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/api/Huurcontractbepaling/ontkoppelingGebouwcluster',
    alias: 'deleteApiHuurcontractbepalingontkoppelingGebouwcluster',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OntkoppelingGebouwclusterCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/api/Huurcontractbepaling/ontkoppelingGebouwdelen',
    alias: 'deleteApiHuurcontractbepalingontkoppelingGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OntkoppelingGebouwdeelCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/api/Huurcontractbepaling/ontkoppelingVges',
    alias: 'deleteApiHuurcontractbepalingontkoppelingVges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OntkoppelingVgeCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/InspectieSjabloon',
    alias: 'getApiInspectieSjabloon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: InspectieSjabloonListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/InspectieSjabloon',
    alias: 'postApiInspectieSjabloon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectieSjabloonAddOrUpdateDto,
      },
    ],
    response: InspectieSjabloonAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/InspectieSjabloon',
    alias: 'deleteApiInspectieSjabloon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectieSjabloonDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/InspectieSjabloon/:id',
    alias: 'getApiInspectieSjabloonId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: InspectieSjabloonAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/InspectieSjabloon/:id',
    alias: 'putApiInspectieSjabloonId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectieSjabloonAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: InspectieSjabloonAddOrUpdateDto,
  },
  {
    method: 'post',
    path: '/api/InspectieSjabloon/koppelingInspectietaak',
    alias: 'postApiInspectieSjabloonkoppelingInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KoppelingInspectietaakCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/InspectieSjabloon/listOfInspectietaken',
    alias: 'getApiInspectieSjabloonlistOfInspectietaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'InspectieSjabloonId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: InspectieSjabloonTenantInspectietaakListDtoPaginatedList,
  },
  {
    method: 'delete',
    path: '/api/InspectieSjabloon/ontkoppelingInspectietaak',
    alias: 'deleteApiInspectieSjabloonontkoppelingInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OntkoppelingInspectietaakCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/InspectieSjabloon/searchInspectietaken',
    alias: 'getApiInspectieSjabloonsearchInspectietaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'InspectieSjabloonId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ActiefInspectietaakListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Inspectietaak',
    alias: 'getApiInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: InspectietaakListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Inspectietaak',
    alias: 'postApiInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectietaakAddOrUpdateDto,
      },
    ],
    response: InspectietaakAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Inspectietaak',
    alias: 'deleteApiInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectietaakDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Inspectietaak/:id',
    alias: 'getApiInspectietaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: InspectietaakAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Inspectietaak/:id',
    alias: 'putApiInspectietaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectietaakAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: InspectietaakAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/api/Inspectietaak/opties',
    alias: 'getApiInspectietaakopties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'post',
    path: '/api/Installatie',
    alias: 'postApiInstallatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InstallatieDto,
      },
    ],
    response: InstallatieDto,
  },
  {
    method: 'delete',
    path: '/api/Installatie',
    alias: 'deleteApiInstallatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteInstallatiesCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/Installatie/:id',
    alias: 'putApiInstallatieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InstallatieDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: InstallatieDto,
  },
  {
    method: 'get',
    path: '/api/Installatie/gebouw',
    alias: 'getApiInstallatiegebouw',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'get',
    path: '/api/Installatie/gebouwDeel',
    alias: 'getApiInstallatiegebouwDeel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwDeelId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'get',
    path: '/api/Installatie/vge',
    alias: 'getApiInstallatievge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'get',
    path: '/api/Kvk/details',
    alias: 'getApiKvkdetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VestigingsNummer',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: KvkDetailsDto,
  },
  {
    method: 'get',
    path: '/api/Kvk/search',
    alias: 'getApiKvksearch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: KvkDetailsDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Lemdo/exportTaken',
    alias: 'getApiLemdoexportTaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/MeetkundigeEenheden/as-options',
    alias: 'getApiMeetkundigeEenhedenasOptions',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/api/MeetkundigeEenheden/fetchAll',
    alias: 'getApiMeetkundigeEenhedenfetchAll',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: MeetkundigeEenheidDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract',
    alias: 'getApiOnderhoudscontract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: OnderhoudscontractDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Onderhoudscontract',
    alias: 'postApiOnderhoudscontract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OnderhoudscontractDto,
      },
    ],
    response: OnderhoudscontractDto,
  },
  {
    method: 'put',
    path: '/api/Onderhoudscontract',
    alias: 'putApiOnderhoudscontract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OnderhoudscontractDto,
      },
    ],
    response: OnderhoudscontractDto,
  },
  {
    method: 'delete',
    path: '/api/Onderhoudscontract',
    alias: 'deleteApiOnderhoudscontract',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/:contractId/taken',
    alias: 'getApiOnderhoudscontractContractIdtaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'contractId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(OnderhoudscontractTaakDto),
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/gebouw',
    alias: 'getApiOnderhoudscontractgebouw',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: OnderhoudscontractDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/gebouwDeel',
    alias: 'getApiOnderhoudscontractgebouwDeel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwDeelId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: OnderhoudscontractDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/getById',
    alias: 'getApiOnderhoudscontractgetById',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: OnderhoudscontractDto,
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/opties',
    alias: 'getApiOnderhoudscontractopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/api/Onderhoudscontract/vge',
    alias: 'getApiOnderhoudscontractvge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: OnderhoudscontractDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Onderhoudscontract/vges',
    alias: 'postApiOnderhoudscontractvges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GetOnderhoudscontractVgesQuery,
      },
      {
        name: 'GebouwIds',
        type: 'Query',
        schema: GebouwIds,
      },
      {
        name: 'GebouwdeelIds',
        type: 'Query',
        schema: GebouwIds,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Perceel',
    alias: 'getApiPerceel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: PerceelDto,
  },
  {
    method: 'get',
    path: '/api/Product2BA',
    alias: 'getApiProduct2BA',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ProductPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Taak',
    alias: 'getApiTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TaakListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Taak',
    alias: 'postApiTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TaakAddOrUpdateDto,
      },
    ],
    response: TaakAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Taak',
    alias: 'deleteApiTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TaakDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Taak/:id',
    alias: 'getApiTaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TaakAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Taak/:id',
    alias: 'putApiTaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TaakAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TaakAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/api/Taak/page',
    alias: 'getApiTaakpage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TaakDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/api/Tenant',
    alias: 'getApiTenant',
    requestFormat: 'json',
    response: TenantDto,
  },
  {
    method: 'get',
    path: '/api/TenantInspectietaak',
    alias: 'getApiTenantInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TenantInspectietaakListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/TenantInspectietaak',
    alias: 'postApiTenantInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantInspectietaakAddOrUpdateDto,
      },
    ],
    response: TenantInspectietaakAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/TenantInspectietaak',
    alias: 'deleteApiTenantInspectietaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantInspectietaakDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/TenantInspectietaak/:id',
    alias: 'getApiTenantInspectietaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantInspectietaakAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/TenantInspectietaak/:id',
    alias: 'putApiTenantInspectietaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantInspectietaakAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantInspectietaakAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/api/Werkzaamheden',
    alias: 'getApiWerkzaamheden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: WerkzaamhedenListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/api/Werkzaamheden',
    alias: 'postApiWerkzaamheden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkzaamhedenAddOrUpdateDto,
      },
    ],
    response: WerkzaamhedenAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/api/Werkzaamheden',
    alias: 'deleteApiWerkzaamheden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkzaamhedenDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/api/Werkzaamheden/:id',
    alias: 'getApiWerkzaamhedenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WerkzaamhedenAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/api/Werkzaamheden/:id',
    alias: 'putApiWerkzaamhedenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkzaamhedenAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WerkzaamhedenAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/BegrippenLijst',
    alias: 'getBegrippenLijst',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: BegrippenLijstDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/BegrippenLijst',
    alias: 'postBegrippenLijst',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateBegrippenLijstCommand,
      },
    ],
    response: BegrippenLijstDto,
  },
  {
    method: 'delete',
    path: '/BegrippenLijst',
    alias: 'deleteBegrippenLijst',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/BegrippenLijst/:id',
    alias: 'putBegrippenLijstId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateBegrippenLijstCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: BegrippenLijstDto,
  },
  {
    method: 'get',
    path: '/bestemming-opties',
    alias: 'getBestemmingOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/buitenruimtes-opties',
    alias: 'getBuitenruimtesOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/ContactCategorieen',
    alias: 'getContactCategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ContactCategorieListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/ContactCategorieen',
    alias: 'postContactCategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactCategorieAddOrUpdateDto,
      },
    ],
    response: ContactCategorieAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/ContactCategorieen',
    alias: 'deleteContactCategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactCategorieDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/ContactCategorieen/:id',
    alias: 'getContactCategorieenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ContactCategorieAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/ContactCategorieen/:id',
    alias: 'putContactCategorieenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactCategorieAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ContactCategorieAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Contactgegevens',
    alias: 'getContactgegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ContactgegevenListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Contactgegevens',
    alias: 'postContactgegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactgegevenAddOrUpdateDto,
      },
    ],
    response: ContactgegevenAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Contactgegevens',
    alias: 'deleteContactgegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactgegevenDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Contactgegevens/:id',
    alias: 'getContactgegevensId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ContactgegevenAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Contactgegevens/:id',
    alias: 'putContactgegevensId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ContactgegevenAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ContactgegevenAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/DefectLocatie',
    alias: 'getDefectLocatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DefectLocatieDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/DefectLocatie',
    alias: 'postDefectLocatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateDefectLocatieCommand,
      },
    ],
    response: DefectLocatieDto,
  },
  {
    method: 'delete',
    path: '/DefectLocatie',
    alias: 'deleteDefectLocatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/DefectLocatie/:id',
    alias: 'putDefectLocatieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateDefectLocatieCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DefectLocatieDto,
  },
  {
    method: 'get',
    path: '/DefectLocatie/dropdownoptions',
    alias: 'getDefectLocatiedropdownoptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/DefectLocatie/icon',
    alias: 'getDefectLocatieicon',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/DefectLocatie/uploadImage',
    alias: 'postDefectLocatieuploadImage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'get',
    path: '/DefectLocatieElementCategorie/exportLocaties',
    alias: 'getDefectLocatieElementCategorieexportLocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/DefectLocatieElementCategorie/importLocaties',
    alias: 'postDefectLocatieElementCategorieimportLocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/DefectLocatieElementCategorie/pageLocations',
    alias: 'getDefectLocatieElementCategoriepageLocations',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DefectlocatieElementDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/DefectLocatieWoningverbetering/defectlocations',
    alias: 'getDefectLocatieWoningverbeteringdefectlocations',
    requestFormat: 'json',
    response: z.array(DefectLocationListItemDto),
  },
  {
    method: 'get',
    path: '/DefectLocatieWoningverbetering/export',
    alias: 'getDefectLocatieWoningverbeteringexport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/DefectLocatieWoningverbetering/import',
    alias: 'postDefectLocatieWoningverbeteringimport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/DefectLocatieWoningverbetering/matrix',
    alias: 'getDefectLocatieWoningverbeteringmatrix',
    requestFormat: 'json',
    response: z.array(DefectLocationWoningverbeteringDto),
  },
  {
    method: 'get',
    path: '/Defectoorzaak',
    alias: 'getDefectoorzaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DefectoorzaakDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Defectoorzaak',
    alias: 'postDefectoorzaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateDefectoorzaakCommand,
      },
    ],
    response: DefectoorzaakDto,
  },
  {
    method: 'delete',
    path: '/Defectoorzaak',
    alias: 'deleteDefectoorzaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/Defectoorzaak/:id',
    alias: 'putDefectoorzaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateDefectoorzaakCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DefectoorzaakDto,
  },
  {
    method: 'get',
    path: '/DefectoorzaakKostensoort',
    alias: 'getDefectoorzaakKostensoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DefectoorzaakKostensoortListDtoPaginatedList,
  },
  {
    method: 'put',
    path: '/DefectoorzaakKostensoort/:id',
    alias: 'putDefectoorzaakKostensoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DefectoorzaakKostensoortAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DefectoorzaakKostensoortAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/DienstenComponent',
    alias: 'getDienstenComponent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DienstenComponentDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/DienstenComponent',
    alias: 'postDienstenComponent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateDienstenComponentCommand,
      },
    ],
    response: DienstenComponentDto,
  },
  {
    method: 'delete',
    path: '/DienstenComponent',
    alias: 'deleteDienstenComponent',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/DienstenComponent/:id',
    alias: 'putDienstenComponentId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateDienstenComponentCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DienstenComponentDto,
  },
  {
    method: 'get',
    path: '/DienstenComponent/as-opties',
    alias: 'getDienstenComponentasOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Dimensies',
    alias: 'getDimensies',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: DimensieListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Dimensies',
    alias: 'postDimensies',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensieAddCommand,
      },
    ],
    response: z.number().int(),
  },
  {
    method: 'delete',
    path: '/Dimensies',
    alias: 'deleteDimensies',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensieDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Dimensies/:dimensieId/waarden',
    alias: 'getDimensiesDimensieIdwaarden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'DimensieId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'dimensieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DimensiewaardeListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Dimensies/:dimensieId/waarden',
    alias: 'postDimensiesDimensieIdwaarden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensiewaardeAddCommand,
      },
      {
        name: 'dimensieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.number().int(),
  },
  {
    method: 'delete',
    path: '/Dimensies/:dimensieId/waarden',
    alias: 'deleteDimensiesDimensieIdwaarden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensiewaardeDeleteCommand,
      },
      {
        name: 'dimensieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Dimensies/:dimensieId/waarden/:id',
    alias: 'getDimensiesDimensieIdwaardenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'dimensieId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DimensiewaardeUpdateOrAddDto,
  },
  {
    method: 'put',
    path: '/Dimensies/:dimensieId/waarden/:id',
    alias: 'putDimensiesDimensieIdwaardenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensiewaardeUpdateCommand,
      },
      {
        name: 'dimensieId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Dimensies/:id',
    alias: 'getDimensiesId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: DimensieCreateOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Dimensies/:id',
    alias: 'putDimensiesId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DimensieUpdateCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Dimensies/VgtEntiteittypes',
    alias: 'getDimensiesVgtEntiteittypes',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort',
    alias: 'getEenheidDetailSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: EenheidDetailSoortDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/EenheidDetailSoort',
    alias: 'postEenheidDetailSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateEenheidDetailSoortCommand,
      },
    ],
    response: EenheidDetailSoortDto,
  },
  {
    method: 'delete',
    path: '/EenheidDetailSoort',
    alias: 'deleteEenheidDetailSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/EenheidDetailSoort/:id',
    alias: 'putEenheidDetailSoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateEenheidDetailSoortCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: EenheidDetailSoortDto,
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/defectlocaties',
    alias: 'getEenheidDetailSoortdefectlocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'BinnenDeWoning',
        type: 'Query',
        schema: BinnenDeWoning,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: EenheiddetailsoortDefectlocatieDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/dropdownoptions',
    alias: 'getEenheidDetailSoortdropdownoptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/exportDefectlocaties',
    alias: 'getEenheidDetailSoortexportDefectlocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'BinnenDeWoning',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/exportRuimteSoorten',
    alias: 'getEenheidDetailSoortexportRuimteSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/EenheidDetailSoort/importDefectlocatiesBinnen',
    alias: 'postEenheidDetailSoortimportDefectlocatiesBinnen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/EenheidDetailSoort/importDefectlocatiesBuiten',
    alias: 'postEenheidDetailSoortimportDefectlocatiesBuiten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/EenheidDetailSoort/importRuimteSoorten',
    alias: 'postEenheidDetailSoortimportRuimteSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/listitems',
    alias: 'getEenheidDetailSoortlistitems',
    requestFormat: 'json',
    response: z.array(EenheidDetailSoortListItemDto),
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/pageCollectiefObjectTypes',
    alias: 'getEenheidDetailSoortpageCollectiefObjectTypes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: EenheidDetailSoortCollectiefObjectTypeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/EenheidDetailSoort/pageRuimteSoorten',
    alias: 'getEenheidDetailSoortpageRuimteSoorten',
    requestFormat: 'json',
    response: z.array(EenheidDetailSoortRuimteSoortMatrixDto),
  },
  {
    method: 'get',
    path: '/EenheidSoort',
    alias: 'getEenheidSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: EenheidSoortDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/ElementDetail',
    alias: 'getElementDetail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementdetailsListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/ElementDetail',
    alias: 'postElementDetail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateElementdetailsCommand,
      },
    ],
    response: ElementdetailsDto,
  },
  {
    method: 'delete',
    path: '/ElementDetail',
    alias: 'deleteElementDetail',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/ElementDetail/:id',
    alias: 'getElementDetailId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ElementdetailsDto,
  },
  {
    method: 'put',
    path: '/ElementDetail/:id',
    alias: 'putElementDetailId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ElementdetailsDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ElementdetailsDto,
  },
  {
    method: 'get',
    path: '/ElementDetail/opties',
    alias: 'getElementDetailopties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'post',
    path: '/ElementElementDetails',
    alias: 'postElementElementDetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ElementElementDetailDto,
      },
    ],
    response: ElementElementDetailDto,
  },
  {
    method: 'delete',
    path: '/ElementElementDetails',
    alias: 'deleteElementElementDetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/ElementElementDetails/:id',
    alias: 'putElementElementDetailsId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ElementElementDetailDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: ElementElementDetailDto,
  },
  {
    method: 'get',
    path: '/ElementElementDetails/:id',
    alias: 'getElementElementDetailsId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: ElementElementDetailAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/ElementElementDetails/as-options',
    alias: 'getElementElementDetailsasOptions',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/ElementElementDetails/listitems',
    alias: 'getElementElementDetailslistitems',
    requestFormat: 'json',
    response: z.array(ElementElementDetailListItemDto),
  },
  {
    method: 'get',
    path: '/ElementElementDetails/page',
    alias: 'getElementElementDetailspage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: ElementElementDetailListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/exports',
    alias: 'getExports',
    requestFormat: 'json',
    response: z.array(ExportInfo),
  },
  {
    method: 'get',
    path: '/exports/advertentietekst',
    alias: 'getExportsadvertentietekst',
    requestFormat: 'json',
    response: Result,
  },
  {
    method: 'get',
    path: '/exports/cartotheektemplate',
    alias: 'getExportscartotheektemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/contactgegevensTemplate',
    alias: 'getExportscontactgegevensTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/dpeEigenWoning',
    alias: 'getExportsdpeEigenWoning',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/dpeGemeenschaplijkeRuimte',
    alias: 'getExportsdpeGemeenschaplijkeRuimte',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/energieprestatiegegevens',
    alias: 'getExportsenergieprestatiegegevens',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/gebouwenTemplate',
    alias: 'getExportsgebouwenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/indelingFysiekBezit',
    alias: 'getExportsindelingFysiekBezit',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtenTakenTemplate',
    alias: 'getExportsinrichtenTakenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtingOnderhoudsbedrijvenpg',
    alias: 'getExportsinrichtingOnderhoudsbedrijvenpg',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtingOnderhoudscontractenTemplate',
    alias: 'getExportsinrichtingOnderhoudscontractenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtingRuimtenEnVertrekken',
    alias: 'getExportsinrichtingRuimtenEnVertrekken',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtingVastgoedeenheden',
    alias: 'getExportsinrichtingVastgoedeenheden',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inrichtingwozgegevens',
    alias: 'getExportsinrichtingwozgegevens',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/inspectietakenTemplate',
    alias: 'getExportsinspectietakenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/onderhoudscontractenTemplate',
    alias: 'getExportsonderhoudscontractenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/relatieVgeEnVhe',
    alias: 'getExportsrelatieVgeEnVhe',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/vgebulkmutatie',
    alias: 'getExportsvgebulkmutatie',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/vgeEnVheWaarderingGegevensTemplate',
    alias: 'getExportsvgeEnVheWaarderingGegevensTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/vvebijvastgoedeenheden',
    alias: 'getExportsvvebijvastgoedeenheden',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/exports/woningverbeteringenTemplate',
    alias: 'getExportswoningverbeteringenTemplate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Fysiek',
    alias: 'getFysiek',
    requestFormat: 'json',
    response: z.array(FysiekInfo),
  },
  {
    method: 'get',
    path: '/Fysiek/Gebouwen',
    alias: 'getFysiekGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Fysiek/GebouwenCluster',
    alias: 'getFysiekGebouwenCluster',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwenClusterDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Fysiek/Koopgarant',
    alias: 'getFysiekKoopgarant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Fysiek/Monumenten',
    alias: 'getFysiekMonumenten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Fysiek/PercelenZonderAdresseerbaarObject',
    alias: 'getFysiekPercelenZonderAdresseerbaarObject',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Fysiek/Vastgoedeenheid',
    alias: 'getFysiekVastgoedeenheid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Garantie',
    alias: 'getGarantie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GarantieDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Garantie',
    alias: 'postGarantie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateGarantieCommand,
      },
    ],
    response: GarantieDto,
  },
  {
    method: 'delete',
    path: '/Garantie',
    alias: 'deleteGarantie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/Garantie/:id',
    alias: 'putGarantieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateGarantieCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GarantieDto,
  },
  {
    method: 'get',
    path: '/GebouwContactCategorie/:gebouwId/contactCategorien',
    alias: 'getGebouwContactCategorieGebouwIdcontactCategorien',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'GebouwId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwContactCategorieListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/GebouwContactCategorie/:gebouwId/contactCategorien/:contactCategorieId',
    alias: 'getGebouwContactCategorieGebouwIdcontactCategorienContactCategorieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'contactCategorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwContactCategorieGetOrModifyDto,
  },
  {
    method: 'post',
    path: '/GebouwContactCategorie/:gebouwId/contactCategorien/:contactCategorieId',
    alias: 'postGebouwContactCategorieGebouwIdcontactCategorienContactCategorieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwContactCategorieMutateDto,
      },
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'contactCategorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwContactCategorieGetOrModifyDto,
  },
  {
    method: 'get',
    path: '/GebouwDeelContactCategorie/:gebouwDeelId/contactCategorien',
    alias: 'getGebouwDeelContactCategorieGebouwDeelIdcontactCategorien',
    requestFormat: 'json',
    parameters: [
      {
        name: 'GebouwDeelId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwDeelContactCategorieListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/GebouwDeelContactCategorie/:gebouwDeelId/contactCategorien/:contactCategorieId',
    alias: 'getGebouwDeelContactCategorieGebouwDeelIdcontactCategorienContactCategorieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwDeelId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'contactCategorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwDeelContactCategorieGetOrModifyDto,
  },
  {
    method: 'post',
    path: '/GebouwDeelContactCategorie/:gebouwDeelId/contactCategorien/:contactCategorieId',
    alias: 'postGebouwDeelContactCategorieGebouwDeelIdcontactCategorienContactCategorieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwDeelContactCategorieMutateDto,
      },
      {
        name: 'gebouwDeelId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'contactCategorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwDeelContactCategorieGetOrModifyDto,
  },
  {
    method: 'post',
    path: '/Gebouwdelen',
    alias: 'postGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwdeelAddOrUpdateDto,
      },
    ],
    response: GebouwdeelAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Gebouwdelen',
    alias: 'deleteGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwdeelDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Gebouwdelen',
    alias: 'getGebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwdeelListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:gebouwdeelId/cartotheek/categorie/:categorieId/elementen',
    alias: 'getGebouwdelenGebouwdeelIdcartotheekcategorieCategorieIdelementen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwdeelId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'categorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:gebouwDeelId/serviceadresOptions',
    alias: 'getGebouwdelenGebouwDeelIdserviceadresOptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwDeelId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:gebouwDeelId/vges',
    alias: 'getGebouwdelenGebouwDeelIdvges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwDeelId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'adresStatus',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(GebouwDeelVgeListDto),
  },
  {
    method: 'put',
    path: '/Gebouwdelen/:id',
    alias: 'putGebouwdelenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwdeelAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwdeelAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:id',
    alias: 'getGebouwdelenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwdeelAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Gebouwdelen/:id/bestemming/:bestemmingid',
    alias: 'putGebouwdelenIdbestemmingBestemmingid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'bestemmingid',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:id/cartotheek/categorieen',
    alias: 'getGebouwdelenIdcartotheekcategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:id/OppervlaktesNEN2580',
    alias: 'getGebouwdelenIdOppervlaktesNEN2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OppervlakteNen2580HeaderDto,
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:vgeId/cartotheek/element/:elementId/details',
    alias: 'getGebouwdelenVgeIdcartotheekelementElementIddetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwdelen/:vgeId/installaties',
    alias: 'getGebouwdelenVgeIdinstallaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementdetailId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'post',
    path: '/Gebouwdelen/Vge',
    alias: 'postGebouwdelenVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddVgeToGebouwdeelCommand,
      },
    ],
    response: z.array(VgeDto),
  },
  {
    method: 'delete',
    path: '/Gebouwdelen/Vge',
    alias: 'deleteGebouwdelenVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveVgeFromGebouwdeelCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Gebouwen',
    alias: 'getGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Gebouwen',
    alias: 'postGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwAddOrUpdateDto,
      },
    ],
    response: GebouwAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Gebouwen',
    alias: 'deleteGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Gebouwen/:entityId/hoeveelheden',
    alias: 'getGebouwenEntityIdhoeveelheden',
    requestFormat: 'json',
    parameters: [
      {
        name: 'entityId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'entityType',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: GebouwHoeveelheidListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/cartotheek/categorie/:categorieId/elementen',
    alias: 'getGebouwenGebouwIdcartotheekcategorieCategorieIdelementen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'categorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/cartotheek/element/:elementId/details',
    alias: 'getGebouwenGebouwIdcartotheekelementElementIddetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/gebouwdelen',
    alias: 'getGebouwenGebouwIdgebouwdelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'GebouwId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwGebouwdelenListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/installaties',
    alias: 'getGebouwenGebouwIdinstallaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementdetailId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/serviceadresOptions',
    alias: 'getGebouwenGebouwIdserviceadresOptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Gebouwen/:gebouwId/vges',
    alias: 'getGebouwenGebouwIdvges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'gebouwId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'adresStatus',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(GebouwVgeListDto),
  },
  {
    method: 'get',
    path: '/Gebouwen/:id',
    alias: 'getGebouwenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Gebouwen/:id',
    alias: 'putGebouwenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Gebouwen/:id/bestemming/:bestemmingid',
    alias: 'putGebouwenIdbestemmingBestemmingid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'bestemmingid',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Gebouwen/:id/cartotheek/categorieen',
    alias: 'getGebouwenIdcartotheekcategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Gebouwen/:id/defectlocaties',
    alias: 'getGebouwenIddefectlocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(GebouwDefectLocatieDto),
  },
  {
    method: 'post',
    path: '/Gebouwen/:id/defectlocaties',
    alias: 'postGebouwenIddefectlocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwDefectlocatieAddorUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: GebouwDefectlocatieAddorUpdateDto,
  },
  {
    method: 'delete',
    path: '/Gebouwen/:id/defectlocaties',
    alias: 'deleteGebouwenIddefectlocaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwDefectlocatieDeleteCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Gebouwen/:id/OppervlaktesNEN2580',
    alias: 'getGebouwenIdOppervlaktesNEN2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OppervlakteNen2580HeaderDto,
  },
  {
    method: 'get',
    path: '/Gebouwen/byClusterId/:clusterId',
    alias: 'getGebouwenbyClusterIdClusterId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'clusterId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Gebouwen/details',
    alias: 'getGebouwendetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwenListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Gebouwen/gebouwenOptions',
    alias: 'getGebouwengebouwenOptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'post',
    path: '/Gebouwen/updateHoeveelheid',
    alias: 'postGebouwenupdateHoeveelheid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: GebouwHoeveelheidDto,
      },
    ],
    response: GebouwHoeveelheidDisplayDto,
  },
  {
    method: 'post',
    path: '/Gebouwen/Vge',
    alias: 'postGebouwenVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddVgeToGebouwCommand,
      },
    ],
    response: z.array(VgeDto),
  },
  {
    method: 'delete',
    path: '/Gebouwen/Vge',
    alias: 'deleteGebouwenVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RemoveVgeFromGebouwCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/GebouwenClusters',
    alias: 'getGebouwenClusters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwenClusterDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/GebouwenClusters',
    alias: 'postGebouwenClusters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateGebouwenClusterCommand,
      },
    ],
    response: GebouwenClusterDto,
  },
  {
    method: 'delete',
    path: '/GebouwenClusters',
    alias: 'deleteGebouwenClusters',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/GebouwenClusters/:id',
    alias: 'getGebouwenClustersId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Id',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: GebouwenClusterDto,
  },
  {
    method: 'put',
    path: '/GebouwenClusters/:id',
    alias: 'putGebouwenClustersId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateGebouwenClusterCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: GebouwenClusterDto,
  },
  {
    method: 'get',
    path: '/GebouwenClusters/details',
    alias: 'getGebouwenClustersdetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwenClusterListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/GebouwOnderhoudsbedrijf',
    alias: 'getGebouwOnderhoudsbedrijf',
    requestFormat: 'json',
    parameters: [
      {
        name: 'GebouwId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: GebouwOnderhoudsbedrijfDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/GebouwOnderhoudsbedrijf',
    alias: 'postGebouwOnderhoudsbedrijf',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateGebouwOnderhoudsbedrijfCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/GebouwOnderhoudsbedrijf',
    alias: 'deleteGebouwOnderhoudsbedrijf',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/GebouwOnderhoudsbedrijf/:id',
    alias: 'putGebouwOnderhoudsbedrijfId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateGebouwOnderhoudsbedrijfCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/historisch',
    alias: 'getHistorisch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeIds',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(HistorischInfoDto),
  },
  {
    method: 'post',
    path: '/historisch',
    alias: 'postHistorisch',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeSetHistorischDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/historisch-opties',
    alias: 'getHistorischOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Imports',
    alias: 'getImports',
    requestFormat: 'json',
    response: z.array(ImportInfo),
  },
  {
    method: 'post',
    path: '/Imports/advertentietekst',
    alias: 'postImportsadvertentietekst',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/cartotheek',
    alias: 'postImportscartotheek',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/coencadNen2580',
    alias: 'postImportscoencadNen2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/coencadRuimte',
    alias: 'postImportscoencadRuimte',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/contactgegevens',
    alias: 'postImportscontactgegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/eigenarenKadasterFile',
    alias: 'postImportseigenarenKadasterFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/elementen',
    alias: 'postImportselementen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/ImportConfiguratieGebouwen',
    alias: 'postImportsImportConfiguratieGebouwen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/inrichtenTakenFile',
    alias: 'postImportsinrichtenTakenFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/inspectietaken',
    alias: 'postImportsinspectietaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/KenmerkenVgeBulkmutatie',
    alias: 'postImportsKenmerkenVgeBulkmutatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/meetinstructies',
    alias: 'postImportsmeetinstructies',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/onderhoudscontractenOnderhoudstaken',
    alias: 'postImportsonderhoudscontractenOnderhoudstaken',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/onderhoudscontractenStamgegevens',
    alias: 'postImportsonderhoudscontractenStamgegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/onderhoudscontractGebouw',
    alias: 'postImportsonderhoudscontractGebouw',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/onderhoudsTakenErpFile',
    alias: 'postImportsonderhoudsTakenErpFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/ruimtesoortenErpFile',
    alias: 'postImportsruimtesoortenErpFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/syncvhes',
    alias: 'postImportssyncvhes',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/verhuureenhedenUpload',
    alias: 'postImportsverhuureenhedenUpload',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/vgeEnVheWaarderingGegevens',
    alias: 'postImportsvgeEnVheWaarderingGegevens',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/vgeNen2580',
    alias: 'postImportsvgeNen2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/vgeRuimtes',
    alias: 'postImportsvgeRuimtes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/vveBijVge',
    alias: 'postImportsvveBijVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/woningverbeteringenFile',
    alias: 'postImportswoningverbeteringenFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/woningwaardering',
    alias: 'postImportswoningwaardering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/Imports/wozFile',
    alias: 'postImportswozFile',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'post',
    path: '/Imports/zorggeschiktheid',
    alias: 'postImportszorggeschiktheid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: Result,
  },
  {
    method: 'get',
    path: '/InspectiecategorieActief',
    alias: 'getInspectiecategorieActief',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: InspectiecategorieActiefDtoPaginatedList,
  },
  {
    method: 'put',
    path: '/InspectiecategorieActief',
    alias: 'putInspectiecategorieActief',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InspectiecategorieActiefUpdateCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/InspectieOpnamedetailCartotheek/export',
    alias: 'getInspectieOpnamedetailCartotheekexport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/InspectieOpnamedetailCartotheek/import',
    alias: 'postInspectieOpnamedetailCartotheekimport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ContentType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ContentDisposition',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Headers',
        type: 'Query',
        schema: Headers,
      },
      {
        name: 'Length',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Name',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'FileName',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/InspectieOpnamedetailCartotheek/matrix',
    alias: 'getInspectieOpnamedetailCartotheekmatrix',
    requestFormat: 'json',
    response: z.array(InspectieOpnamedetailCartotheekMatrixDto),
  },
  {
    method: 'get',
    path: '/InspectieOpnamedetailCartotheek/matrix-left',
    alias: 'getInspectieOpnamedetailCartotheekmatrixLeft',
    requestFormat: 'json',
    response: z.array(ElementElementDetailMatrixLeftDto),
  },
  {
    method: 'get',
    path: '/InspectieOpnamedetailCartotheek/matrix-top',
    alias: 'getInspectieOpnamedetailCartotheekmatrixTop',
    requestFormat: 'json',
    response: z.array(InspectieSectieTypeMatrixTopDto),
  },
  {
    method: 'get',
    path: '/InspectieOpnameTypes',
    alias: 'getInspectieOpnameTypes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.array(InspectieOpnameTypeDto),
  },
  {
    method: 'get',
    path: '/KadastraalObject',
    alias: 'getKadastraalObject',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: KadastraalObjectDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/KadastraalSubject',
    alias: 'getKadastraalSubject',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: KadastraalSubjectDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/kompasrichting-opties',
    alias: 'getKompasrichtingOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Kostensoort',
    alias: 'getKostensoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: KostensoortDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Kostensoort',
    alias: 'postKostensoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateKostensoortCommand,
      },
    ],
    response: KostensoortDto,
  },
  {
    method: 'delete',
    path: '/Kostensoort',
    alias: 'deleteKostensoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/Kostensoort/:id',
    alias: 'putKostensoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateKostensoortCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: KostensoortDto,
  },
  {
    method: 'post',
    path: '/MateriaalSoorten',
    alias: 'postMateriaalSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MateriaalSoortDto,
      },
    ],
    response: MateriaalSoortDto,
  },
  {
    method: 'delete',
    path: '/MateriaalSoorten',
    alias: 'deleteMateriaalSoorten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/MateriaalSoorten/:id',
    alias: 'putMateriaalSoortenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MateriaalSoortDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: MateriaalSoortDto,
  },
  {
    method: 'get',
    path: '/MateriaalSoorten/opties',
    alias: 'getMateriaalSoortenopties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/MateriaalSoorten/page',
    alias: 'getMateriaalSoortenpage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: MateriaalSoortDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Metertype',
    alias: 'getMetertype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: MetertypeDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Metertype',
    alias: 'postMetertype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MetertypeAddOrUpdateDto,
      },
    ],
    response: MetertypeAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Metertype',
    alias: 'deleteMetertype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MetertypeDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Metertype/:id',
    alias: 'getMetertypeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: MetertypeAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Metertype/:id',
    alias: 'putMetertypeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: MetertypeAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: MetertypeAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/NlSfB',
    alias: 'getNlSfB',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: NlsfbBouwkundigElementDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/NlSfB',
    alias: 'postNlSfB',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateNlSfBElementenCommand,
      },
    ],
    response: NlsfbBouwkundigElementDto,
  },
  {
    method: 'delete',
    path: '/NlSfB',
    alias: 'deleteNlSfB',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/NlSfB/:id',
    alias: 'putNlSfBId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateNlSfbElementenCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: NlsfbBouwkundigElementDto,
  },
  {
    method: 'get',
    path: '/NlSfB/export',
    alias: 'getNlSfBexport',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/NlSfB/findElements',
    alias: 'getNlSfBfindElements',
    requestFormat: 'json',
    parameters: [
      {
        name: 'baseElementId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'text',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(ElementSuggestion),
  },
  {
    method: 'get',
    path: '/OnderhoudsbedrijfTypering',
    alias: 'getOnderhoudsbedrijfTypering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: OnderhoudsbedrijfTyperingDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/OnderhoudsbedrijfTypering',
    alias: 'postOnderhoudsbedrijfTypering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateOnderhoudsbedrijfTyperingCommand,
      },
    ],
    response: OnderhoudsbedrijfTyperingDto,
  },
  {
    method: 'delete',
    path: '/OnderhoudsbedrijfTypering',
    alias: 'deleteOnderhoudsbedrijfTypering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/OnderhoudsbedrijfTypering/:id',
    alias: 'putOnderhoudsbedrijfTyperingId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateOnderhoudsbedrijfTyperingCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OnderhoudsbedrijfTyperingDto,
  },
  {
    method: 'get',
    path: '/Onderhoudsspecialisme',
    alias: 'getOnderhoudsspecialisme',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: OnderhoudsspecialismeDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Onderhoudsspecialisme',
    alias: 'postOnderhoudsspecialisme',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateOnderhoudsspecialismeCommand,
      },
    ],
    response: OnderhoudsspecialismeDto,
  },
  {
    method: 'delete',
    path: '/Onderhoudsspecialisme',
    alias: 'deleteOnderhoudsspecialisme',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Onderhoudsspecialisme/-opties',
    alias: 'getOnderhoudsspecialismeOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'put',
    path: '/Onderhoudsspecialisme/:id',
    alias: 'putOnderhoudsspecialismeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateOnderhoudsspecialismeCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OnderhoudsspecialismeDto,
  },
  {
    method: 'get',
    path: '/OnderhoudsTaakErp',
    alias: 'getOnderhoudsTaakErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: OnderhoudsTaakErpDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/OnderhoudsTaakErp',
    alias: 'postOnderhoudsTaakErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateOnderhoudsTaakErpCommand,
      },
    ],
    response: OnderhoudsTaakErpDto,
  },
  {
    method: 'delete',
    path: '/OnderhoudsTaakErp',
    alias: 'deleteOnderhoudsTaakErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/OnderhoudsTaakErp/:id',
    alias: 'putOnderhoudsTaakErpId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateOnderhoudsTaakErpCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OnderhoudsTaakErpDto,
  },
  {
    method: 'get',
    path: '/OnderhoudsTaakErp/opties',
    alias: 'getOnderhoudsTaakErpopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/PubSub/entities',
    alias: 'getPubSubentities',
    requestFormat: 'json',
    response: z.array(PubSubEntityDto),
  },
  {
    method: 'post',
    path: '/PubSub/initialload',
    alias: 'postPubSubinitialload',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: InitialLoadCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/PubSub/subscriptions',
    alias: 'getPubSubsubscriptions',
    requestFormat: 'json',
    response: z.array(SubscriptionDto),
  },
  {
    method: 'post',
    path: '/PubSub/subscriptions',
    alias: 'postPubSubsubscriptions',
    requestFormat: 'json',
    response: z.array(SubscriptionDto),
  },
  {
    method: 'delete',
    path: '/PubSub/subscriptions/:subscriptionId',
    alias: 'deletePubSubsubscriptionsSubscriptionId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionId',
        type: 'Path',
        schema: id,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Query',
    alias: 'getQuery',
    requestFormat: 'json',
    response: z.array(QueryInfo),
  },
  {
    method: 'get',
    path: '/Query/export/NogInTeDelen',
    alias: 'getQueryexportNogInTeDelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Query/export/ZonderKadastraleAanduiding',
    alias: 'getQueryexportZonderKadastraleAanduiding',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Query/export/ZonderVerhuureenheid',
    alias: 'getQueryexportZonderVerhuureenheid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Query/NogInTeDelen',
    alias: 'getQueryNogInTeDelen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Query/ZonderKadastraleAanduiding',
    alias: 'getQueryZonderKadastraleAanduiding',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Query/ZonderVerhuureenheid',
    alias: 'getQueryZonderVerhuureenheid',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Rechtspersonen',
    alias: 'getRechtspersonen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'RelatieRol',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: RechtspersoonDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Rechtspersonen',
    alias: 'postRechtspersonen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RechtspersoonAddOrUpdateDto,
      },
    ],
    response: RechtspersoonAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Rechtspersonen',
    alias: 'deleteRechtspersonen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteRechtspersoonCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Rechtspersonen/:id',
    alias: 'getRechtspersonenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: RechtspersoonAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Rechtspersonen/:id',
    alias: 'putRechtspersonenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RechtspersoonAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: RechtspersoonAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Rechtspersonen/dropdownoptions',
    alias: 'getRechtspersonendropdownoptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Rechtspersonen/list',
    alias: 'getRechtspersonenlist',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: RechtspersoonListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Rechtspersonen/relatierol',
    alias: 'getRechtspersonenrelatierol',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: RelatieRolDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Rechtspersonen/relatierol-opties',
    alias: 'getRechtspersonenrelatierolOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/RuimteSoort',
    alias: 'getRuimteSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: CoenCadRuimteSoortDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/RuimteSoort',
    alias: 'postRuimteSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateRuimteSoortCommand,
      },
    ],
    response: CoenCadRuimteSoortDto,
  },
  {
    method: 'delete',
    path: '/RuimteSoort',
    alias: 'deleteRuimteSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/RuimteSoort/:id',
    alias: 'putRuimteSoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateRuimteSoortCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: CoenCadRuimteSoortDto,
  },
  {
    method: 'get',
    path: '/RuimteSoort/listitems',
    alias: 'getRuimteSoortlistitems',
    requestFormat: 'json',
    response: z.array(RuimteSoortListItem),
  },
  {
    method: 'get',
    path: '/RuimteSoort/opties',
    alias: 'getRuimteSoortopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/RuimtesoortErp',
    alias: 'getRuimtesoortErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: RuimtesoortErpDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/RuimtesoortErp',
    alias: 'postRuimtesoortErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateRuimtesoortErpCommand,
      },
    ],
    response: RuimtesoortErpDto,
  },
  {
    method: 'delete',
    path: '/RuimtesoortErp',
    alias: 'deleteRuimtesoortErp',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/RuimtesoortErp/:id',
    alias: 'putRuimtesoortErpId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateRuimtesoortErpCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: RuimtesoortErpDto,
  },
  {
    method: 'get',
    path: '/RuimtesoortErp/opties',
    alias: 'getRuimtesoortErpopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Sleuteltype',
    alias: 'getSleuteltype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: SleuteltypeDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Sleuteltype',
    alias: 'postSleuteltype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SleuteltypeAddOrUpdateDto,
      },
    ],
    response: SleuteltypeAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Sleuteltype',
    alias: 'deleteSleuteltype',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SleuteltypeDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Sleuteltype/:id',
    alias: 'getSleuteltypeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: SleuteltypeAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Sleuteltype/:id',
    alias: 'putSleuteltypeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SleuteltypeAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: SleuteltypeAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Statistics',
    alias: 'getStatistics',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: StatistiekDto,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen',
    alias: 'getSysteemInstellingen',
    requestFormat: 'json',
    response: SysteeminstellingDto,
  },
  {
    method: 'delete',
    path: '/SysteemInstellingen/BusinessCentral',
    alias: 'deleteSysteemInstellingenBusinessCentral',
    requestFormat: 'json',
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/BusinessCentral',
    alias: 'putSysteemInstellingenBusinessCentral',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BusinessCentralConfigurationUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/BusinessCentral',
    alias: 'getSysteemInstellingenBusinessCentral',
    requestFormat: 'json',
    response: BusinessCentralConfigurationUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/BusinessCentralSecret',
    alias: 'putSysteemInstellingenBusinessCentralSecret',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BusinessCentralConfigurationSecretUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/SysteemInstellingen/Crm',
    alias: 'deleteSysteemInstellingenCrm',
    requestFormat: 'json',
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/Crm',
    alias: 'putSysteemInstellingenCrm',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CrmConfigurationUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/Crm',
    alias: 'getSysteemInstellingenCrm',
    requestFormat: 'json',
    response: CrmConfigurationUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/CrmSecret',
    alias: 'putSysteemInstellingenCrmSecret',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CrmConfigurationSecretUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/SysteemInstellingen/DocumentConfiguration',
    alias: 'deleteSysteemInstellingenDocumentConfiguration',
    requestFormat: 'json',
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/DocumentConfiguration',
    alias: 'putSysteemInstellingenDocumentConfiguration',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentConfigurationUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/DocumentConfiguration',
    alias: 'getSysteemInstellingenDocumentConfiguration',
    requestFormat: 'json',
    response: DocumentConfigurationUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/DocumentConfigurationSecret',
    alias: 'putSysteemInstellingenDocumentConfigurationSecret',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DocumentConfigurationSecretUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/SysteemInstellingen/FieldService',
    alias: 'deleteSysteemInstellingenFieldService',
    requestFormat: 'json',
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/FieldService',
    alias: 'putSysteemInstellingenFieldService',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: FieldServiceConfigurationUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/FieldService',
    alias: 'getSysteemInstellingenFieldService',
    requestFormat: 'json',
    response: FieldServiceConfigurationUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/FieldServiceSecret',
    alias: 'putSysteemInstellingenFieldServiceSecret',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: FieldServiceConfigurationSecretUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/SysteemInstellingen/IrisFieldService',
    alias: 'deleteSysteemInstellingenIrisFieldService',
    requestFormat: 'json',
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/IrisFieldService',
    alias: 'putSysteemInstellingenIrisFieldService',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: IrisFieldServiceConfigurationUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/IrisFieldService',
    alias: 'getSysteemInstellingenIrisFieldService',
    requestFormat: 'json',
    response: IrisFieldServiceConfigurationUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/IrisFieldServiceSecret',
    alias: 'putSysteemInstellingenIrisFieldServiceSecret',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: IrisFieldServiceConfigurationSecretUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/Tenant',
    alias: 'putSysteemInstellingenTenant',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/Tenant',
    alias: 'getSysteemInstellingenTenant',
    requestFormat: 'json',
    response: TenantUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/Verhuur',
    alias: 'putSysteemInstellingenVerhuur',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerhuurUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/Verhuur',
    alias: 'getSysteemInstellingenVerhuur',
    requestFormat: 'json',
    response: VerhuurUpdateDto,
  },
  {
    method: 'put',
    path: '/SysteemInstellingen/VheNummering',
    alias: 'putSysteemInstellingenVheNummering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VheNummeringUpdateDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/SysteemInstellingen/VheNummering',
    alias: 'getSysteemInstellingenVheNummering',
    requestFormat: 'json',
    response: VheNummeringUpdateDto,
  },
  {
    method: 'get',
    path: '/TenantKostensoortBetaler',
    alias: 'getTenantKostensoortBetaler',
    requestFormat: 'json',
    parameters: [
      {
        name: 'query',
        type: 'Query',
        schema: query,
      },
    ],
    response: z.array(TenantKostensoortBetalerDto),
  },
  {
    method: 'post',
    path: '/TenantKostensoortBetaler',
    alias: 'postTenantKostensoortBetaler',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTenantKostensoortBetalerCommand,
      },
    ],
    response: TenantKostensoortBetalerDto,
  },
  {
    method: 'delete',
    path: '/TenantKostensoortBetaler',
    alias: 'deleteTenantKostensoortBetaler',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/TenantKostensoortBetaler/:id',
    alias: 'putTenantKostensoortBetalerId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTenantKostensoortBetalerCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantKostensoortBetalerDto,
  },
  {
    method: 'get',
    path: '/TenantKostensoortBetaler/opties',
    alias: 'getTenantKostensoortBetaleropties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/TenantRuimteSoort',
    alias: 'getTenantRuimteSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TenantRuimteSoortListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/TenantRuimteSoort',
    alias: 'postTenantRuimteSoort',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantRuimteSoortAddOrUpdateDto,
      },
    ],
    response: TenantRuimteSoortAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/TenantRuimteSoort/:id',
    alias: 'putTenantRuimteSoortId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TenantRuimteSoortAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantRuimteSoortAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/TenantRuimteSoort/ruimteSoortOptions',
    alias: 'getTenantRuimteSoortruimteSoortOptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'includeEmpty',
        type: 'Query',
        schema: BinnenDeWoning,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/TenantTaak',
    alias: 'getTenantTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TenantTaakListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/TenantTaak',
    alias: 'postTenantTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTenantTaakCommand,
      },
    ],
    response: TenantTaakAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/TenantTaak',
    alias: 'deleteTenantTaak',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/TenantTaak/:id',
    alias: 'getTenantTaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantTaakAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/TenantTaak/:id',
    alias: 'putTenantTaakId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTenantTaakCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantTaakAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/TenantTaak/:tenantTaakId/onderhoudscontracten',
    alias: 'getTenantTaakTenantTaakIdonderhoudscontracten',
    requestFormat: 'json',
    parameters: [
      {
        name: 'tenantTaakId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(TenantTaakOnderhoudscontractenListDto),
  },
  {
    method: 'get',
    path: '/TenantWoningverbetering',
    alias: 'getTenantWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TenantWoningverbeteringDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/TenantWoningverbetering',
    alias: 'postTenantWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTenantWoningverbeteringCommand,
      },
    ],
    response: TenantWoningverbeteringDto,
  },
  {
    method: 'delete',
    path: '/TenantWoningverbetering',
    alias: 'deleteTenantWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/TenantWoningverbetering/:id',
    alias: 'putTenantWoningverbeteringId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTenantWoningverbeteringCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TenantWoningverbeteringDto,
  },
  {
    method: 'get',
    path: '/TerreinClassificatie',
    alias: 'getTerreinClassificatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: TerreinClassificatieDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/TerreinClassificatie',
    alias: 'postTerreinClassificatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateTerreinClassificatieCommand,
      },
    ],
    response: TerreinClassificatieDto,
  },
  {
    method: 'delete',
    path: '/TerreinClassificatie',
    alias: 'deleteTerreinClassificatie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/TerreinClassificatie/:id',
    alias: 'putTerreinClassificatieId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateTerreinClassificatieCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: TerreinClassificatieDto,
  },
  {
    method: 'get',
    path: '/User/roles',
    alias: 'getUserroles',
    requestFormat: 'json',
    response: z.array(z.string()),
  },
  {
    method: 'get',
    path: '/Vastgoeddeelportefeuille',
    alias: 'getVastgoeddeelportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VastgoeddeelportefeuilleListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Vastgoeddeelportefeuille',
    alias: 'postVastgoeddeelportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoeddeelportefeuilleAddOrUpdateDto,
      },
    ],
    response: VastgoeddeelportefeuilleAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Vastgoeddeelportefeuille',
    alias: 'deleteVastgoeddeelportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoeddeelportefeuilleDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Vastgoeddeelportefeuille/:id',
    alias: 'getVastgoeddeelportefeuilleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VastgoeddeelportefeuilleAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Vastgoeddeelportefeuille/:id',
    alias: 'putVastgoeddeelportefeuilleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoeddeelportefeuilleAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VastgoeddeelportefeuilleAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Vastgoedportefeuille',
    alias: 'getVastgoedportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VastgoedportefeuilleListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Vastgoedportefeuille',
    alias: 'postVastgoedportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoedportefeuilleAddOrUpdateDto,
      },
    ],
    response: VastgoedportefeuilleAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Vastgoedportefeuille',
    alias: 'deleteVastgoedportefeuille',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoedportefeuilleDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Vastgoedportefeuille/:id',
    alias: 'getVastgoedportefeuilleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VastgoedportefeuilleAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Vastgoedportefeuille/:id',
    alias: 'putVastgoedportefeuilleId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VastgoedportefeuilleAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VastgoedportefeuilleAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/verkoop-aan-opties',
    alias: 'getVerkoopAanOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Verwarmingen',
    alias: 'getVerwarmingen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VerwarmingListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Verwarmingen',
    alias: 'postVerwarmingen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerwarmingAddOrUpdateDto,
      },
    ],
    response: VerwarmingAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Verwarmingen',
    alias: 'deleteVerwarmingen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerwarmingDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Verwarmingen/:id',
    alias: 'getVerwarmingenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VerwarmingAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Verwarmingen/:id',
    alias: 'putVerwarmingenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerwarmingAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VerwarmingAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Verwarmingen/levering-opties',
    alias: 'getVerwarmingenleveringOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Verwarmingen/opties',
    alias: 'getVerwarmingenopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Verwarmingen/wijze-opties',
    alias: 'getVerwarmingenwijzeOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Verwerking',
    alias: 'getVerwerking',
    requestFormat: 'json',
    parameters: [
      {
        name: 'TypeId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VerwerkingDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Verwerking',
    alias: 'postVerwerking',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VerwerkingPostCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Verwerking/Regels',
    alias: 'getVerwerkingRegels',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Verwerkingen',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'LogTypes',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ActieIds',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'ObjectType',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'VastgoedeenheidId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'VerblijfsobjectId',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'KadastraalObjectId',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'DetailsFilter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VerwerkingRegelDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Verwerking/verwerkingtypes',
    alias: 'getVerwerkingverwerkingtypes',
    requestFormat: 'json',
    response: z.array(VerwerkingstypeDto),
  },
  {
    method: 'get',
    path: '/Vge',
    alias: 'getVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeListDtoPaginatedList,
  },
  {
    method: 'delete',
    path: '/Vge',
    alias: 'deleteVge',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Vge/:id',
    alias: 'getVgeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeDto,
  },
  {
    method: 'put',
    path: '/Vge/:id',
    alias: 'putVgeId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateVgeCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeDto,
  },
  {
    method: 'get',
    path: '/Vge/:id/cartotheek/categorieen',
    alias: 'getVgeIdcartotheekcategorieen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Vge/:id/OppervlaktesNEN2580',
    alias: 'getVgeIdOppervlaktesNEN2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: OppervlakteNen2580HeaderDto,
  },
  {
    method: 'post',
    path: '/Vge/:id/UpdateLatLongFromBagId',
    alias: 'postVgeIdUpdateLatLongFromBagId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeDto,
  },
  {
    method: 'get',
    path: '/Vge/:id/vhe-opties',
    alias: 'getVgeIdvheOpties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/:id/vhes',
    alias: 'getVgeIdvhes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(VheListDto),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/cartotheek/categorie/:categorieId/elementen',
    alias: 'getVgeVgeIdcartotheekcategorieCategorieIdelementen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'categorieId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/cartotheek/element/:elementId/details',
    alias: 'getVgeVgeIdcartotheekelementElementIddetails',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/cartotheek/keuken-voorzieningen',
    alias: 'getVgeVgeIdcartotheekkeukenVoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/cartotheek/sanitair-voorzieningen',
    alias: 'getVgeVgeIdcartotheeksanitairVoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(CartotheekIcon),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/installaties',
    alias: 'getVgeVgeIdinstallaties',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'elementdetailId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(InstallatieDto),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/ruimtes',
    alias: 'getVgeVgeIdruimtes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(VgeRuimteListDto),
  },
  {
    method: 'post',
    path: '/Vge/:vgeId/ruimtes',
    alias: 'postVgeVgeIdruimtes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeRuimteAddOrUpdateDto,
      },
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeRuimteAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/ruimtes-as-options',
    alias: 'getVgeVgeIdruimtesAsOptions',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/voorzieningen',
    alias: 'getVgeVgeIdvoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: z.array(VgeVoorzieningenListDto),
  },
  {
    method: 'get',
    path: '/Vge/:vgeId/woningwaardering',
    alias: 'getVgeVgeIdwoningwaardering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WoningwaarderingDto,
  },
  {
    method: 'put',
    path: '/Vge/:vgeId/woningwaardering',
    alias: 'putVgeVgeIdwoningwaardering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeWoningwaarderingUpdateDto,
      },
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Vge/forVheSelection',
    alias: 'getVgeforVheSelection',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeType',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: z.array(VgeSelectionListDto),
  },
  {
    method: 'get',
    path: '/Vge/fromvhe/:id',
    alias: 'getVgefromvheId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id,
      },
    ],
    response: z.number().int(),
  },
  {
    method: 'get',
    path: '/Vge/HistorieRedenen',
    alias: 'getVgeHistorieRedenen',
    requestFormat: 'json',
    response: z.array(HistorischReden),
  },
  {
    method: 'get',
    path: '/Vge/niet-verhuurd-opties',
    alias: 'getVgenietVerhuurdOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/searchVgesForLinkage',
    alias: 'getVgesearchVgesForLinkage',
    requestFormat: 'json',
    parameters: [
      {
        name: 'AdresStatus',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: SearchVgesForLinkageListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Vge/suggestAdres',
    alias: 'getVgesuggestAdres',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Vges',
        type: 'Query',
        schema: Vges,
      },
    ],
    response: SuggestedAddressDto,
  },
  {
    method: 'get',
    path: '/Vge/taak-type-opties',
    alias: 'getVgetaakTypeOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/toegankelijkheid-opgang-opties',
    alias: 'getVgetoegankelijkheidOpgangOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/verhuurtype-opties',
    alias: 'getVgeverhuurtypeOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Vge/zolder-zonder-vaste-trap-opties',
    alias: 'getVgezolderZonderVasteTrapOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/VgeMeterstand',
    alias: 'getVgeMeterstand',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeMeterstandDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/VgeMeterstand',
    alias: 'postVgeMeterstand',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeMeterstandAddOrUpdateDto,
      },
    ],
    response: VgeMeterstandDto,
  },
  {
    method: 'put',
    path: '/VgeMeterstand/:id',
    alias: 'putVgeMeterstandId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeMeterstandAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeMeterstandDto,
  },
  {
    method: 'get',
    path: '/VgeMeterstand/historie',
    alias: 'getVgeMeterstandhistorie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeMeterstandId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(VgeMeterstandHistorieDto),
  },
  {
    method: 'get',
    path: '/VgeOppervlakteNen2580',
    alias: 'getVgeOppervlakteNen2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeOppervlakteNen2580ListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/VgeOppervlakteNen2580',
    alias: 'postVgeOppervlakteNen2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeOppervlakteNen2580AddOrUpdateDto,
      },
    ],
    response: VgeOppervlakteNen2580AddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/VgeOppervlakteNen2580',
    alias: 'deleteVgeOppervlakteNen2580',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeOppervlakteNen2580DeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/VgeOppervlakteNen2580/kompasrichting-opties',
    alias: 'getVgeOppervlakteNen2580kompasrichtingOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/VgeOppervlakteNen2580/vges/:vgeId/bouwlagen/:bouwlaagId',
    alias: 'getVgeOppervlakteNen2580vgesVgeIdbouwlagenBouwlaagId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'bouwlaagId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeOppervlakteNen2580AddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/VgeOppervlakteNen2580/vges/:vgeId/bouwlagen/:bouwlaagId',
    alias: 'putVgeOppervlakteNen2580vgesVgeIdbouwlagenBouwlaagId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeOppervlakteNen2580AddOrUpdateDto,
      },
      {
        name: 'vgeId',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'bouwlaagId',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeOppervlakteNen2580AddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/VgeRuimte',
    alias: 'deleteVgeRuimte',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeRuimteDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/VgeRuimte/:id',
    alias: 'getVgeRuimteId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeRuimteAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/VgeRuimte/:id',
    alias: 'putVgeRuimteId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeRuimteAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VgeRuimteAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/VgeRuimte/koeling-opties',
    alias: 'getVgeRuimtekoelingOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/VgeSleutel',
    alias: 'getVgeSleutel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'VgeId',
        type: 'Query',
        schema: id__3,
      },
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VgeSleutelDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/VgeSleutel',
    alias: 'postVgeSleutel',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VgeSleutelAddOrUpdateDto,
      },
    ],
    response: VgeSleutelDto,
  },
  {
    method: 'get',
    path: '/Vhes',
    alias: 'getVhes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VheListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Vhes',
    alias: 'postVhes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VheAddDto,
      },
    ],
    response: Unit,
  },
  {
    method: 'delete',
    path: '/Vhes',
    alias: 'deleteVhes',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VheDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Vhes/:id',
    alias: 'getVhesId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VheUpdateDto,
  },
  {
    method: 'put',
    path: '/Vhes/:id',
    alias: 'putVhesId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VheUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VheUpdateDto,
  },
  {
    method: 'get',
    path: '/Vhes/:id/vges',
    alias: 'getVhesIdvges',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VheVgeListDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Vhes/waardestelsel-opties',
    alias: 'getVheswaardestelselOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Voorzieningen',
    alias: 'getVoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VoorzieningenListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Voorzieningen',
    alias: 'postVoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VoorzieningenAddOrUpdateDto,
      },
    ],
    response: VoorzieningenAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Voorzieningen',
    alias: 'deleteVoorzieningen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VoorzieningenDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Voorzieningen/:id',
    alias: 'getVoorzieningenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VoorzieningenAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Voorzieningen/:id',
    alias: 'putVoorzieningenId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VoorzieningenAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VoorzieningenAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Voorzieningen/:id/coencadcoderingen',
    alias: 'getVoorzieningenIdcoencadcoderingen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
      {
        name: 'VoorzieningId',
        type: 'Query',
        schema: id__3,
      },
    ],
    response: z.array(CoenCadcoderingListDto),
  },
  {
    method: 'get',
    path: '/Voorzieningen/type-opties',
    alias: 'getVoorzieningentypeOpties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'post',
    path: '/Vve',
    alias: 'postVve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AddVveCommand,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve',
    alias: 'deleteVve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeleteVvesCommand,
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/Vve',
    alias: 'getVve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: VveDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Vve/:id',
    alias: 'getVveId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'put',
    path: '/Vve/:id',
    alias: 'putVveId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: VveDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveDto,
  },
  {
    method: 'post',
    path: '/Vve/:id/appartementsindexen',
    alias: 'postVveIdappartementsindexen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdappartementsindexen_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve/:id/appartementsindexen',
    alias: 'deleteVveIdappartementsindexen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdappartementsindexen_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve/:id/bovenliggendeVve',
    alias: 'deleteVveIdbovenliggendeVve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'post',
    path: '/Vve/:id/bovenliggendeVve',
    alias: 'postVveIdbovenliggendeVve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdkadastraalsubject_Body.int(),
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve/:id/bronnen',
    alias: 'deleteVveIdbronnen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdappartementsindexen_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'post',
    path: '/Vve/:id/bronnen',
    alias: 'postVveIdbronnen',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdappartementsindexen_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve/:id/kadastraalsubject',
    alias: 'deleteVveIdkadastraalsubject',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'post',
    path: '/Vve/:id/kadastraalsubject',
    alias: 'postVveIdkadastraalsubject',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: postVveIdkadastraalsubject_Body.int(),
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'delete',
    path: '/Vve/:id/vves',
    alias: 'deleteVveIdvves',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'post',
    path: '/Vve/:id/vves',
    alias: 'postVveIdvves',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveExpandedDto,
  },
  {
    method: 'put',
    path: '/Vve/fromkvk/:id',
    alias: 'putVvefromkvkId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: KvkDetailsDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: VveDto,
  },
  {
    method: 'get',
    path: '/Werkgebied',
    alias: 'getWerkgebied',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: WerkgebiedListDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Werkgebied',
    alias: 'postWerkgebied',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkgebiedAddOrUpdateDto,
      },
    ],
    response: WerkgebiedAddOrUpdateDto,
  },
  {
    method: 'delete',
    path: '/Werkgebied',
    alias: 'deleteWerkgebied',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkgebiedDeleteCommand,
      },
    ],
    response: Unit,
  },
  {
    method: 'get',
    path: '/Werkgebied/:id',
    alias: 'getWerkgebiedId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WerkgebiedAddOrUpdateDto,
  },
  {
    method: 'put',
    path: '/Werkgebied/:id',
    alias: 'putWerkgebiedId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WerkgebiedAddOrUpdateDto,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WerkgebiedAddOrUpdateDto,
  },
  {
    method: 'get',
    path: '/Werkgebied/opties',
    alias: 'getWerkgebiedopties',
    requestFormat: 'json',
    response: z.array(DropdownOption),
  },
  {
    method: 'get',
    path: '/Woningverbetering',
    alias: 'getWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: WoningverbeteringDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/Woningverbetering',
    alias: 'postWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateWoningverbeteringCommand,
      },
    ],
    response: WoningverbeteringDto,
  },
  {
    method: 'delete',
    path: '/Woningverbetering',
    alias: 'deleteWoningverbetering',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/Woningverbetering/:id',
    alias: 'putWoningverbeteringId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateWoningverbeteringCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WoningverbeteringDto,
  },
  {
    method: 'get',
    path: '/Woningverbetering/listItems',
    alias: 'getWoningverbeteringlistItems',
    requestFormat: 'json',
    response: z.array(WoningverbeteringListItemDto),
  },
  {
    method: 'get',
    path: '/Woningverbetering/woningverbeteringTypes',
    alias: 'getWoningverbeteringwoningverbeteringTypes',
    requestFormat: 'json',
    response: z.array(WoningVerbeteringType),
  },
  {
    method: 'get',
    path: '/WoningverbeteringToestemming',
    alias: 'getWoningverbeteringToestemming',
    requestFormat: 'json',
    parameters: [
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: WoningverbeteringToestemmingDtoPaginatedList,
  },
  {
    method: 'post',
    path: '/WoningverbeteringToestemming',
    alias: 'postWoningverbeteringToestemming',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateWoningverbeteringToestemmingCommand,
      },
    ],
    response: WoningverbeteringToestemmingDto,
  },
  {
    method: 'delete',
    path: '/WoningverbeteringToestemming',
    alias: 'deleteWoningverbeteringToestemming',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: deleteBegrippenLijst_Body,
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/WoningverbeteringToestemming/:id',
    alias: 'putWoningverbeteringToestemmingId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UpdateWoningverbeteringToestemmingCommand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WoningverbeteringToestemmingDto,
  },
  {
    method: 'get',
    path: '/Woz/historie',
    alias: 'getWozhistorie',
    requestFormat: 'json',
    parameters: [
      {
        name: 'Filter',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'PageIndex',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'PageSize',
        type: 'Query',
        schema: PageIndex,
      },
      {
        name: 'SortDirection',
        type: 'Query',
        schema: Filter,
      },
      {
        name: 'SortKey',
        type: 'Query',
        schema: Filter,
      },
    ],
    response: WozObjectDtoPaginatedList,
  },
  {
    method: 'get',
    path: '/Woz/woz-objects/:id',
    alias: 'getWozwozObjectsId',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: id__2,
      },
    ],
    response: WozObjectDto,
  },
])

export {
  Postcode,
  AdresListDto,
  id,
  AdresDetailsDto,
  Filter,
  PageIndex,
  BegrippenLijstDto,
  BegrippenLijstDtoPaginatedList,
  CreateBegrippenLijstCommand,
  deleteBegrippenLijst_Body,
  UpdateBegrippenLijstCommand,
  id__2,
  BouwlaagDto,
  BouwlaagDtoPaginatedList,
  ChangeBouwlaagOrderCommand,
  DropdownOption,
  CoenCadCodeDto,
  CoenCadCodeDtoPaginatedList,
  CoencadMeetinstructieListDto,
  CoencadZorggeschiktheidListDto,
  ContactCategorieListDto,
  ContactCategorieListDtoPaginatedList,
  ContactCategorieAddOrUpdateDto,
  ContactCategorieDeleteCommand,
  Unit,
  ContactgegevenListDto,
  ContactgegevenListDtoPaginatedList,
  ContactgegevenAddOrUpdateDto,
  ContactgegevenDeleteCommand,
  DefectLocatieDto,
  DefectLocatieDtoPaginatedList,
  CreateDefectLocatieCommand,
  UpdateDefectLocatieCommand,
  id__3,
  Headers,
  Result,
  query,
  DefectlocatieElementDto,
  DefectlocatieElementDtoPaginatedList,
  DefectLocationWoningverbeteringDto,
  DefectLocationListItemDto,
  DefectoorzaakDto,
  DefectoorzaakDtoPaginatedList,
  CreateDefectoorzaakCommand,
  UpdateDefectoorzaakCommand,
  DefectoorzaakKostensoortListDto,
  DefectoorzaakKostensoortListDtoPaginatedList,
  DefectoorzaakKostensoortAddOrUpdateDto,
  DefectSoortListDto,
  DefectSoortListDtoPaginatedList,
  DefectSoortAddOrUpdateDto,
  DefectSoortDeleteCommand,
  DefectSoortDto,
  DienstenComponentDto,
  DienstenComponentDtoPaginatedList,
  CreateDienstenComponentCommand,
  UpdateDienstenComponentCommand,
  DimensieListDto,
  DimensieListDtoPaginatedList,
  DimensieAddCommand,
  DimensieDeleteCommand,
  DimensieCreateOrUpdateDto,
  DimensieUpdateCommand,
  DimensiewaardeListDto,
  DimensiewaardeListDtoPaginatedList,
  DimensiewaardeAddCommand,
  DimensiewaardeDeleteCommand,
  DimensiewaardeUpdateOrAddDto,
  DimensiewaardeUpdateCommand,
  DocumentClassificationListDto,
  DocumentClassificationListDtoPaginatedList,
  DocumentClassificationAddOrUpdateDto,
  DocumentClassificationDeleteCommand,
  DocumentListDto,
  DocumentListDtoPaginatedList,
  postApiDocuments_Body,
  DocumentAddOrUpdateDto,
  DocumentDeleteRequestDto,
  HoofdDocumentDto,
  UpdateHoofdDocumentCommand,
  DocumentSoortListDto,
  DocumentSoortListDtoPaginatedList,
  DocumentKenmerkListDto,
  DocumentKenmerkListDtoPaginatedList,
  DocumentSoortKenmerkDoelListDto,
  DocumentSoortKenmerkDoelListDtoPaginatedList,
  EenheidDetailSoortDto,
  EenheidDetailSoortDtoPaginatedList,
  CreateEenheidDetailSoortCommand,
  UpdateEenheidDetailSoortCommand,
  EenheidDetailSoortRuimteSoortMatrixDto,
  EenheidDetailSoortListItemDto,
  BinnenDeWoning,
  EenheiddetailsoortDefectlocatieDto,
  EenheiddetailsoortDefectlocatieDtoPaginatedList,
  EenheidDetailSoortCollectiefObjectTypeDto,
  EenheidDetailSoortCollectiefObjectTypeDtoPaginatedList,
  EenheidSoortDto,
  EenheidSoortDtoPaginatedList,
  ElementDto,
  ElementDtoPaginatedList,
  CreateElementCommand,
  UpdateElementCommand,
  ElementElementDetailDto,
  ElementElementDetailDtoPaginatedList,
  ElementDefectSoortDto,
  ElementDefectSoortDtoPaginatedList,
  ElementDefectSoortTakenRow,
  ElementDefectSoortTakenRowPaginatedList,
  ElementCategorieDto,
  ElementCategorieDtoPaginatedList,
  ElementCategorieElementDto,
  ElementCategorieElementDtoPaginatedList,
  CreateElementCategorieCommand,
  UpdateElementCategorieCommand,
  ElementdetailsListDto,
  ElementdetailsListDtoPaginatedList,
  CreateElementdetailsCommand,
  ElementdetailsDto,
  GerelateerdInspectietakenListDto,
  GerelateerdReparatieboomTaken,
  ElementElementDetailListDto,
  ElementElementDetailListDtoPaginatedList,
  ElementElementDetailAddOrUpdateDto,
  ElementElementDetailListItemDto,
  ExportInfo,
  FonticoonListDto,
  FonticoonListDtoPaginatedList,
  FonticoonAddOrUpdateDto,
  FonticoonDeleteCommand,
  VgeListDto,
  VgeListDtoPaginatedList,
  GerelateerdHuurcontractbepaling,
  VgeCoordinateDto,
  GebouwenClusterDto,
  GebouwenClusterDtoPaginatedList,
  GebouwDto,
  GebouwDtoPaginatedList,
  VgeDto,
  VgeDtoPaginatedList,
  FysiekInfo,
  GarantieDto,
  GarantieDtoPaginatedList,
  CreateGarantieCommand,
  UpdateGarantieCommand,
  GebouwContactCategorieListDto,
  GebouwContactCategorieListDtoPaginatedList,
  GebouwContactCategorieGetOrModifyDto,
  GebouwContactCategorieMutateDto,
  GebouwDeelContactCategorieListDto,
  GebouwDeelContactCategorieListDtoPaginatedList,
  GebouwDeelContactCategorieGetOrModifyDto,
  GebouwDeelContactCategorieMutateDto,
  GebouwdeelAddOrUpdateDto,
  GebouwdeelDeleteCommand,
  GebouwdeelListDto,
  GebouwdeelListDtoPaginatedList,
  AddVgeToGebouwdeelCommand,
  RemoveVgeFromGebouwdeelCommand,
  OppervlakteNen2580Dto,
  OppervlakteNen2580HeaderDto,
  CartotheekIcon,
  InstallatieDto,
  GebouwDeelVgeListDto,
  GebouwAddOrUpdateDto,
  GebouwDeleteCommand,
  GerelateerdGebouwDeelListDto,
  GebouwenListDto,
  GebouwenListDtoPaginatedList,
  GebouwListDto,
  GebouwListDtoPaginatedList,
  GebouwDefectLocatieDto,
  GebouwDefectlocatieAddorUpdateDto,
  GebouwDefectlocatieDeleteCommand,
  AddVgeToGebouwCommand,
  RemoveVgeFromGebouwCommand,
  GebouwHoeveelheidDto,
  GebouwHoeveelheidDisplayDto,
  GebouwVgeListDto,
  GebouwHoeveelheidListDto,
  GebouwHoeveelheidListDtoPaginatedList,
  GebouwGebouwdelenListDto,
  GebouwGebouwdelenListDtoPaginatedList,
  CreateGebouwenClusterCommand,
  GerelateerdGebouwListDto,
  GebouwenClusterListDto,
  GebouwenClusterListDtoPaginatedList,
  UpdateGebouwenClusterCommand,
  GebouwOnderhoudsbedrijfDto,
  GebouwOnderhoudsbedrijfDtoPaginatedList,
  CreateGebouwOnderhoudsbedrijfCommand,
  UpdateGebouwOnderhoudsbedrijfCommand,
  HuurcontractbepalingListDto,
  HuurcontractbepalingListDtoPaginatedList,
  HuurcontractbepalingAddOrUpdateDto,
  HuurcontractbepalingDeleteCommand,
  HuurcontractbepalingGebouwclusterListDto,
  HuurcontractbepalingGebouwclusterListDtoPaginatedList,
  HuurcontractbepalingGebouwListDto,
  HuurcontractbepalingGebouwListDtoPaginatedList,
  HuurcontractbepalingGebouwdeelListDto,
  HuurcontractbepalingGebouwdeelListDtoPaginatedList,
  NietGekoppeldGebouwclusterListDto,
  NietGekoppeldGebouwclusterListDtoPaginatedList,
  NietGekoppeldGebouwListDto,
  NietGekoppeldGebouwListDtoPaginatedList,
  NietGekoppeldGebouwdeelListDto,
  NietGekoppeldGebouwdeelListDtoPaginatedList,
  KoppelingGebouwclusterCommand,
  KoppelingGebouwCommand,
  KoppelingGebouwdeelCommand,
  KoppelingVgeCommand,
  OntkoppelingGebouwclusterCommand,
  OntkoppelingGebouwCommand,
  OntkoppelingGebouwdeelCommand,
  OntkoppelingVgeCommand,
  ImportInfo,
  InspectiecategorieActiefDto,
  InspectiecategorieActiefDtoPaginatedList,
  InspectiecategorieActiefUpdateCommand,
  InspectieOpnamedetailCartotheekMatrixDto,
  ElementElementDetailMatrixLeftDto,
  InspectieSectieTypeMatrixTopDto,
  InspectieOpnameTypeDto,
  InspectieSjabloonListDto,
  InspectieSjabloonListDtoPaginatedList,
  InspectieSjabloonAddOrUpdateDto,
  InspectieSjabloonDeleteCommand,
  InspectieSjabloonTenantInspectietaakListDto,
  InspectieSjabloonTenantInspectietaakListDtoPaginatedList,
  ActiefInspectietaakListDto,
  ActiefInspectietaakListDtoPaginatedList,
  KoppelingInspectietaakCommand,
  OntkoppelingInspectietaakCommand,
  InspectietaakListDto,
  InspectietaakListDtoPaginatedList,
  InspectietaakAddOrUpdateDto,
  InspectietaakDeleteCommand,
  DeleteInstallatiesCommand,
  KadastraalObjectDto,
  KadastraalObjectDtoPaginatedList,
  KadastraalSubjectDto,
  KadastraalSubjectDtoPaginatedList,
  KostensoortDto,
  KostensoortDtoPaginatedList,
  CreateKostensoortCommand,
  UpdateKostensoortCommand,
  SbiActiviteit,
  Adres,
  BinnenlandsAdres,
  BuitenlandsAdres,
  KvkAdres,
  KvkDetailsDto,
  KvkDetailsDtoPaginatedList,
  MateriaalSoortDto,
  MateriaalSoortDtoPaginatedList,
  MeetkundigeEenheidDto,
  MeetkundigeEenheidDtoPaginatedList,
  MetertypeDto,
  MetertypeDtoPaginatedList,
  MetertypeAddOrUpdateDto,
  MetertypeDeleteCommand,
  ElementSuggestion,
  NlsfbBouwkundigElementDto,
  NlsfbBouwkundigElementDtoPaginatedList,
  CreateNlSfBElementenCommand,
  UpdateNlSfbElementenCommand,
  OnderhoudsbedrijfTyperingDto,
  OnderhoudsbedrijfTyperingDtoPaginatedList,
  CreateOnderhoudsbedrijfTyperingCommand,
  UpdateOnderhoudsbedrijfTyperingCommand,
  GetOnderhoudscontractVgesQuery,
  GebouwIds,
  ContractGebouwDto,
  ContractGebouwDeelDto,
  TenantTaakDto,
  OnderhoudscontractDto,
  OnderhoudscontractDtoPaginatedList,
  OnderhoudscontractTaakDto,
  OnderhoudsspecialismeDto,
  OnderhoudsspecialismeDtoPaginatedList,
  CreateOnderhoudsspecialismeCommand,
  UpdateOnderhoudsspecialismeCommand,
  OnderhoudsTaakErpDto,
  OnderhoudsTaakErpDtoPaginatedList,
  CreateOnderhoudsTaakErpCommand,
  UpdateOnderhoudsTaakErpCommand,
  GerechtigdeDto,
  PerceelDto,
  ProductFeature,
  Product,
  ProductPaginatedList,
  PubSubEntityDto,
  SubscriptionDto,
  InitialLoadCommand,
  QueryInfo,
  RechtspersoonListDto,
  RechtspersoonListDtoPaginatedList,
  RechtspersoonDto,
  RechtspersoonDtoPaginatedList,
  RechtspersoonAddOrUpdateDto,
  DeleteRechtspersoonCommand,
  RelatieRolDto,
  RelatieRolDtoPaginatedList,
  CoenCadRuimteSoortDto,
  CoenCadRuimteSoortDtoPaginatedList,
  CreateRuimteSoortCommand,
  UpdateRuimteSoortCommand,
  RuimteSoortListItem,
  RuimtesoortErpDto,
  RuimtesoortErpDtoPaginatedList,
  CreateRuimtesoortErpCommand,
  UpdateRuimtesoortErpCommand,
  SleuteltypeDto,
  SleuteltypeDtoPaginatedList,
  SleuteltypeAddOrUpdateDto,
  SleuteltypeDeleteCommand,
  Verwerking,
  StatistiekDto,
  SysteeminstellingDto,
  VheNummeringUpdateDto,
  VerhuurUpdateDto,
  CrmConfigurationUpdateDto,
  CrmConfigurationSecretUpdateDto,
  FieldServiceConfigurationUpdateDto,
  FieldServiceConfigurationSecretUpdateDto,
  IrisFieldServiceConfigurationUpdateDto,
  IrisFieldServiceConfigurationSecretUpdateDto,
  BusinessCentralConfigurationUpdateDto,
  BusinessCentralConfigurationSecretUpdateDto,
  DocumentConfigurationUpdateDto,
  DocumentConfigurationSecretUpdateDto,
  TenantUpdateDto,
  TaakDto,
  TaakDtoPaginatedList,
  TaakListDto,
  TaakListDtoPaginatedList,
  TaakAddOrUpdateDto,
  TaakDeleteCommand,
  TenantDto,
  TenantInspectietaakListDto,
  TenantInspectietaakListDtoPaginatedList,
  TenantInspectietaakAddOrUpdateDto,
  TenantInspectietaakDeleteCommand,
  TenantKostensoortBetalerDto,
  CreateTenantKostensoortBetalerCommand,
  UpdateTenantKostensoortBetalerCommand,
  TenantRuimteSoortListDto,
  TenantRuimteSoortListDtoPaginatedList,
  TenantRuimteSoortAddOrUpdateDto,
  TenantTaakListDto,
  TenantTaakListDtoPaginatedList,
  CreateTenantTaakCommand,
  TenantTaakAddOrUpdateDto,
  UpdateTenantTaakCommand,
  TenantTaakOnderhoudscontractenListDto,
  TenantWoningverbeteringDto,
  TenantWoningverbeteringDtoPaginatedList,
  CreateTenantWoningverbeteringCommand,
  UpdateTenantWoningverbeteringCommand,
  TerreinClassificatieDto,
  TerreinClassificatieDtoPaginatedList,
  CreateTerreinClassificatieCommand,
  UpdateTerreinClassificatieCommand,
  VastgoeddeelportefeuilleListDto,
  VastgoeddeelportefeuilleListDtoPaginatedList,
  VastgoeddeelportefeuilleAddOrUpdateDto,
  VastgoeddeelportefeuilleDeleteCommand,
  VastgoedportefeuilleListDto,
  VastgoedportefeuilleListDtoPaginatedList,
  VastgoedportefeuilleAddOrUpdateDto,
  VastgoedportefeuilleDeleteCommand,
  VerwarmingListDto,
  VerwarmingListDtoPaginatedList,
  VerwarmingAddOrUpdateDto,
  VerwarmingDeleteCommand,
  VerwerkingDto,
  VerwerkingDtoPaginatedList,
  VerwerkingPostCommand,
  VerwerkingstypeDto,
  VerwerkingRegelDto,
  VerwerkingRegelDtoPaginatedList,
  HistorischReden,
  SearchVgesForLinkageListDto,
  SearchVgesForLinkageListDtoPaginatedList,
  UpdateVgeCommand,
  VheListDto,
  VgeSelectionListDto,
  Vges,
  SuggestedAddressDto,
  VgeVoorzieningenListDto,
  VgeRuimteListDto,
  VgeRuimteAddOrUpdateDto,
  WoningwaarderingDto,
  VgeWoningwaarderingUpdateDto,
  PandCertificaatDto,
  HistorischInfoDto,
  VgeSetHistorischDto,
  VgeMeterstandDto,
  VgeMeterstandDtoPaginatedList,
  VgeMeterstandAddOrUpdateDto,
  VgeMeterstandHistorieDto,
  VgeOppervlakteNen2580ListDto,
  VgeOppervlakteNen2580ListDtoPaginatedList,
  VgeOppervlakteNen2580AddOrUpdateDto,
  VgeOppervlakteNen2580DeleteCommand,
  VgeRuimteDeleteCommand,
  VgeSleutelDto,
  VgeSleutelDtoPaginatedList,
  VgeSleutelAddOrUpdateDto,
  VheListDtoPaginatedList,
  VheAddDto,
  VheDeleteCommand,
  VheUpdateDto,
  VheVgeListDto,
  VheVgeListDtoPaginatedList,
  VoorzieningenListDto,
  VoorzieningenListDtoPaginatedList,
  VoorzieningenAddOrUpdateDto,
  VoorzieningenDeleteCommand,
  CoenCadcoderingListDto,
  AddVveCommand,
  VveDto,
  VveExpandedDto,
  DeleteVvesCommand,
  VveDtoPaginatedList,
  postVveIdappartementsindexen_Body,
  postVveIdkadastraalsubject_Body,
  WerkgebiedListDto,
  WerkgebiedListDtoPaginatedList,
  WerkgebiedAddOrUpdateDto,
  WerkgebiedDeleteCommand,
  WerkzaamhedenListDto,
  WerkzaamhedenListDtoPaginatedList,
  WerkzaamhedenAddOrUpdateDto,
  WerkzaamhedenDeleteCommand,
  WoningverbeteringDto,
  WoningverbeteringDtoPaginatedList,
  CreateWoningverbeteringCommand,
  UpdateWoningverbeteringCommand,
  WoningverbeteringListItemDto,
  WoningVerbeteringType,
  WoningverbeteringToestemmingDto,
  WoningverbeteringToestemmingDtoPaginatedList,
  CreateWoningverbeteringToestemmingCommand,
  UpdateWoningverbeteringToestemmingCommand,
  WozDeelobjectDto,
  WozObjectDto,
  WozObjectDtoPaginatedList,
  DefectLocatieElementCategorieExportQuery,
  DefectLocatieWoningverbeteringExportQuery,
  EenheidDetailSoortRuimteSoortExportQuery,
  ElementDefectSoortExportQuery,
  ElementDefectSoortTaakExportQuery,
  ElementExportQuery,
  GetExportQuery,
  InspectieOpnameTypeAllListQuery,
  InspectieOpnamedetailCartotheekExportQuery,
  StatistiekQuery,
  TenantKostensoortBetalerQuery,
}

const settings = JSON.parse(localStorage.getItem('settings') || '{ "apiEndpoint": "https://api.vastgoedtabel.nl"}') as IConfiguration
export const zodios = new Zodios(settings.apiEndpoint, endpoints)
zodios.use(
  pluginToken({
    getToken: async () => {
      const response = await MsalSingleton.getInstance().acquireTokenSilent()
      return response.accessToken
    },
  })
)
export const zodiosHooks = new ZodiosHooks('localApi', zodios)
