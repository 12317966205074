import { KvkDetailsDto, RechtspersoonAddOrUpdateDto, zodiosHooks } from 'api/ApiClient'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect } from 'react'
import VgtTextField from 'components/VgtTextField'
import VgtDropdown from 'components/FluentDropdown'
import { DefaultButton, Stack, StackItem } from '@fluentui/react'
import VgtCheckbox from '../../../../components/FluentCheckbox'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { landOptions } from './landOptions'
import SearchPanelKVK from './searchPanelKvK'
import { useBoolean } from '@fluentui/react-hooks'

type CreateOrUpdateType = z.infer<typeof RechtspersoonAddOrUpdateDto>;

export const RechtspersoonForm = ({ data, isLoading, onSubmit, onCancel }: {
  data?: CreateOrUpdateType,
  isLoading: boolean,
  onSubmit: (values: CreateOrUpdateType) => void,
  onCancel: () => void
}) => {
  const defaults = {
    id: 0,
    landCode: '6030',
    gln: '', rolId: 1, kvkNummer: '',
    vestigingsnummer: '', rsin: null, contactpersoon: '', straat: '',
    handelsnaam: '', postcode: '', woonplaats: '', telefoonnummer: '', emailadres: '', geblokkeerd: false,
    contactBinnenXDagen: null,
    nummeraanduidingId: '0', rolNaam: '', website: '', intakeDoorCorporatie: false, externeReferentie: null
  } as CreateOrUpdateType

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateOrUpdateType>({
    resolver: zodResolver(RechtspersoonAddOrUpdateDto), mode: 'all', defaultValues: defaults
  })

  const { data: relatieRolOpties } = zodiosHooks.useGetRechtspersonenrelatierolOpties({}, { enabled: true })
  const [isKVKPanelOpen, { setTrue: openKVKPanel, setFalse: dismissKVKPanel }] = useBoolean(false)

  useEffect(() => {
    if (data) {
      reset(data)
    } else {
      reset(defaults)
    }
  }, [data])

  const onBeforeSubmit = (values: CreateOrUpdateType) => {
    if(values.rsin === '')
    {
      values.rsin = null
    }    
    values.postcode = values.postcode?.toUpperCase()
    onSubmit(values)
  }

  type KvKType = z.infer<typeof KvkDetailsDto>;
  const onKvkSelected = (selectedKVKEntity: KvKType) => {
    setValue(getPropertyName<CreateOrUpdateType>('handelsnaam'), selectedKVKEntity.naam)
    setValue(getPropertyName<CreateOrUpdateType>('kvkNummer'), selectedKVKEntity.kvkNummer)
    let adres = selectedKVKEntity.straat
    if (selectedKVKEntity.huisnummer) adres += ` ${selectedKVKEntity.huisnummer}`
    setValue(getPropertyName<CreateOrUpdateType>('straat'), adres)
    setValue(getPropertyName<CreateOrUpdateType>('postcode'), selectedKVKEntity.postcode)
    setValue(getPropertyName<CreateOrUpdateType>('woonplaats'), selectedKVKEntity.woonplaats)
    setValue(getPropertyName<CreateOrUpdateType>('vestigingsnummer'), selectedKVKEntity.vestigingsNummer)
    setValue(getPropertyName<CreateOrUpdateType>('rsin'), selectedKVKEntity.rsin)
    if (!selectedKVKEntity.adressen) return

    let adressen = selectedKVKEntity.adressen.filter(x => x.type === 'bezoekadres')
    if (adressen.length === 0) return

    adres = adressen[0].straatnaam ?? ''
    if (adressen[0].huisnummer) adres += ` ${adressen[0].huisnummer}`
    if (adressen[0].huisnummerToevoeging) adres += ' ' + adressen[0].huisnummerToevoeging
    setValue(getPropertyName<CreateOrUpdateType>('postcode'), adressen[0].postcode)
    setValue(getPropertyName<CreateOrUpdateType>('straat'), adres)
    setValue(getPropertyName<CreateOrUpdateType>('woonplaats'), adressen[0].plaats)
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)}>
      <Stack horizontal tokens={{ childrenGap: 30 }}>
        <StackItem grow={1}>
          <VgtDropdown label='Rol' name={getPropertyName<CreateOrUpdateType>('rolId')} control={control} errors={errors} required options={relatieRolOpties} />
          <VgtTextField label='Handelsnaam' name={getPropertyName<CreateOrUpdateType>('handelsnaam')} control={control} errors={errors} />
          <VgtTextField label='GLN' name={getPropertyName<CreateOrUpdateType>('gln')} control={control} errors={errors} />
          <VgtTextField label='KVK nummer' name={getPropertyName<CreateOrUpdateType>('kvkNummer')} control={control} errors={errors} />
          <VgtTextField label='Vestigingsnummer' name={getPropertyName<CreateOrUpdateType>('vestigingsnummer')} control={control} errors={errors} />
          <VgtTextField label='RSIN' name={getPropertyName<CreateOrUpdateType>('rsin')} control={control} errors={errors} />
          <VgtTextField label='Externe referentie' name={getPropertyName<CreateOrUpdateType>('externeReferentie')} control={control} errors={errors} />
          <VgtTextField label='Adres' name={getPropertyName<CreateOrUpdateType>('straat')} control={control} errors={errors} />
          <VgtTextField label='Postcode' name={getPropertyName<CreateOrUpdateType>('postcode')} control={control} errors={errors} />
          <VgtTextField label='Woonplaats' name={getPropertyName<CreateOrUpdateType>('woonplaats')} control={control} errors={errors} />
          <VgtDropdown label='Land' name={getPropertyName<CreateOrUpdateType>('landCode')} control={control} errors={errors} options={landOptions} />
          <VgtTextField iconProps={{ iconName: 'Mail' }} label='E-mailadres (onderhoud) ' name={getPropertyName<CreateOrUpdateType>('emailadres')} control={control} errors={errors} />
          <VgtTextField iconProps={{ iconName: 'Phone' }} label='Telefoonnummer (onderhoud)' name={getPropertyName<CreateOrUpdateType>('telefoonnummer')} control={control} errors={errors} />
          <VgtTextField label='Contact binnen aantal dagen' name={getPropertyName<CreateOrUpdateType>('contactBinnenXDagen')} control={control} errors={errors} />
          <VgtTextField iconProps={{ iconName: 'Contact' }} label='Contactpersoon' name={getPropertyName<CreateOrUpdateType>('contactpersoon')} control={control} errors={errors} />
          <VgtCheckbox label='Geblokkeerd' name={getPropertyName<CreateOrUpdateType>('geblokkeerd')} control={control} errors={errors} required />
          <VgtCheckbox label="Intake door corporatie" name={getPropertyName<CreateOrUpdateType>('intakeDoorCorporatie')} control={control} errors={errors} />
        </StackItem>

        <StackItem grow={1}>
        </StackItem>
      </Stack>

      <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isLoading ?? false}
                           onOkClick={() => handleSubmit(onBeforeSubmit, (errors) => alert(errors))}
                           onCancelClick={() => onCancel()}>
        <DefaultButton text='Zoek KvK' onClick={() => openKVKPanel()} />
      </OkCancelButtonStack>

      <SearchPanelKVK
        handelsNaam={data?.handelsnaam ?? ''}
        onSelected={(item) => {onKvkSelected(item)}}
        isOpen={isKVKPanelOpen}
        dismissPanel={() => dismissKVKPanel()}
      />
    </form>
  )
}